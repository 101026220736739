import { Grid, Typography } from "@material-ui/core";
import React from "react";
import urlJoin from "url-join";
import AppContext from "../AppContext";
import useTranslate from "../lib/useTranslate";
import wrapper from "../utils/wrapper";
import BottomSelectDrawer from "./BottomSelectDrawer";


const styles = theme => ({
  container : {
    borderTop : `1px solid ${theme.palette.grey[50]}`,
    marginTop : theme.spacing(5),

    [theme.breakpoints.down("md")] : {
      marginTop : theme.spacing(3)
    }
  },

  item : {
    cursor : "pointer",
    padding : theme.spacing(4),
    width : "50%",
    "&:first-child" : {
      borderRight : `1px solid ${theme.palette.grey[50]}`,
      paddingRight : theme.spacing(17),

      "& $text" : {
        right : theme.spacing(3)
      }
    },
    "&:last-child" : {
      paddingLeft : theme.spacing(17)
    },
    [theme.breakpoints.down("md")] : {
      paddingTop : theme.spacing(3),
      paddingBottom : theme.spacing(3)
    }
  },

  text : {
    position : "relative",

    [theme.breakpoints.down("md")] : {
      fontSize : "1rem"
    },

    "&:before" : {
      content : '"‹"',
      position : "absolute",
      right : -24,
      top : 0,
      fontFamily : "DIN",
      color : theme.palette.grey[50],
      lineHeight : "19px",
      fontSize : 30,
      transform : "rotate(90deg)",
      transition : `transform 0.6s ${theme.transitions.easing.easeOut}`
    }
  }
});


export function getFooterItems(t, appConfig, customizer) {
  return {
    design : {
      title : t("ACTIONS.SELECT_ANOTHER_DESIGN"),
      items : appConfig.customizerPresets.designs.availableDesigns[customizer.productType],
      onClick : item => {
        customizer.addCustomizationToLocalStorage()
          .then(() => {
            window.location.href = window.location.origin + (process.env.NODE_ENV === "production"
              ? `${urlJoin("/",
              appConfig.api.pathPrefix,
              "/configurateur",
              customizer.productId) }?design=${item.code}`
              : `/${customizer.productId}?design=${item.code}`);
          }).catch(console.error);
      },
      getItemImage : ({ previewPath }) => urlJoin(appConfig.siteBaseURL, previewPath)

    },
    variant : {
      title : t("ACTIONS.SELECT_ANOTHER_VARIATION"),
      items : customizer?.productVariants,
      onClick :
        item => {
          customizer.addCustomizationToLocalStorage()
            .then(() => {

              window.location.href = window.location.origin + (process.env.NODE_ENV === "production"
                ? `${urlJoin("/",
                appConfig.api.pathPrefix,
                "/configurateur/",
                item.code) }?design=${customizer.design}`
                : `/${item.code}?design=${customizer.design}`);
            }).catch(console.error);
        },
      getItemImage : ({ code }) => customizer?.productVariants?.find(_variant => _variant.code === code)?.preview
    }
  };
}


function Footer({ classes }) {
  const [openedDrawer, setOpenedDrawer] = React.useState();
  const { customizer, appConfig } = React.useContext(AppContext);
  const { t } = useTranslate();

  return React.useMemo(() => {
    const footerItems = getFooterItems(t, appConfig, customizer);


    const onClose = () => setOpenedDrawer(false);

    return (
      <Grid container justifyContent="center" alignItems="center" className={classes.container} wrap="nowrap">
        <Grid
          item
          container
          alignItems="center"
          justifyContent={!footerItems.variant.items?.length ? "center" : "flex-end"}
          className={classes.item}
          onClick={() => setOpenedDrawer("design")}
          style={!footerItems.variant.items?.length ? { borderWidth: 0, whiteSpace: "nowrap" } : {}}
        >
          <Typography variant="h2" component="p" align="right" className={classes.text}>
            {footerItems.design.title}
          </Typography>
        </Grid>
        {!!footerItems.variant.items?.length && (
          <Grid item container alignItems="center" className={classes.item} onClick={() => setOpenedDrawer("variant")}>
            <Typography variant="h2" component="p" className={classes.text}>
              {footerItems.variant.title}
            </Typography>
          </Grid>
        )}
        <BottomSelectDrawer isOpen={!!openedDrawer} onClose={onClose} contentItem={footerItems[openedDrawer]} />
      </Grid>
    );

  }, [customizer, openedDrawer, setOpenedDrawer, classes]);
}


export default wrapper({ styles }, Footer);
