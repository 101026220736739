import React from "react";


export default props => (
  <svg width="25" height="25" viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g stroke="#181818" strokeWidth="1.452" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
      <path d="M23.624 12.496c0 6.146-4.982 11.128-11.128 11.128S1.368 18.642 1.368 12.496 6.35 1.368 12.496 1.368 23.624 6.35 23.624 12.496zM12.496 7.241v.814M12.496 11.604v6.718" />
    </g>
  </svg>
);
