import { Button, Grid } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import React from "react";
import AppContext from "../../../AppContext";
import useTranslate from "../../../lib/useTranslate";
import wrapper from "../../../utils/wrapper";
import ColorsPicker from "../../ColorsPicker";
import Slider from "../../Slider";


const styles = theme => ({
  container : {
    width : "100%",
    display : "flex",
    marginTop : theme.spacing(3)
  },

  deleteButton : {
    fontSize : "0.7rem",
    alignSelf : "flex-start",
    marginBottom : theme.spacing(),
    color : theme.palette.error.main
  }
});


function PatternsEditForm({ classes, pattern = {}, id, handleDelete }) {
  const [sliderValues, setSlidersValues] = React.useState(pattern || {});
  const { t } = useTranslate();
  const { customizer, appConfig } = React.useContext(AppContext);

  const handleSliderChange = (field, value) => {
    setSlidersValues(prevState => ({
      ...prevState,
      [field] : value
    }));
  };

  const handleChange = field => async e => {
    await customizer.setColorZonePattern(id, {
      ...pattern,
      [field] : e.target.value
    });
    handleSliderChange(field, e.target.value);
  };

  React.useEffect(() => {
    setSlidersValues(pattern);
  }, [pattern]);

  if (!pattern) { return null; }

  return (
    <Grid container direction="column" className={classes.container} spacing={2}>
      <Grid item container wrap="nowrap" alignItems="center" justifyContent="space-between">
        <Grid item xs={2}>
          <Typography variant="body2">{t("WORDS.COLORS")}</Typography>
        </Grid>
        <Grid item xs={8} container alignItems="center" justifyContent="center">
          <ColorsPicker
            colors={appConfig.customizerPresets.patterns.availableColors}
            onChange={handleChange("fillColor")}
          />
        </Grid>
      </Grid>
      <Grid item container wrap="nowrap" alignItems="center" justifyContent="space-between">
        <Grid item xs={2}>
          <Typography variant="body2">{t("WORDS.SIZE")}</Typography>
        </Grid>
        <Grid item xs={8} container alignItems="center" justifyContent="center">
          <Slider
            value={sliderValues.scale || 1}
            className={classes.slider}
            defaultValue={1}
            min={0.5}
            max={2}
            step={0.1}
            onChange={(evt, value) => handleSliderChange("scale", value)}
            onChangeCommitted={(evt, value) => handleChange("scale")({ target: { value } })}
          />
        </Grid>
      </Grid>
      <Grid item container wrap="nowrap" alignItems="center" justifyContent="space-between">
        <Grid item xs={2}>
          <Typography variant="body2">{t("WORDS.ROTATION")}</Typography>
        </Grid>
        <Grid item xs={8} container alignItems="center" justifyContent="center">
          <Slider
            value={sliderValues.rotation || 0}
            className={classes.slider}
            defaultValue={0}
            min={-180}
            max={180}
            step={1}
            onChange={(evt, value) => handleSliderChange("rotation", value)}
            onChangeCommitted={(evt, value) => handleChange("rotation")({ target: { value } })}
          />
        </Grid>
      </Grid>
      <Grid item container wrap="nowrap" alignItems="center" justifyContent="space-between">
        <Grid item xs={2}>
          <Typography variant="body2">{t("WORDS.OPACITY")}</Typography>
        </Grid>
        <Grid item xs={8} container alignItems="center" justifyContent="center">
          <Slider
            value={(sliderValues.opacity || 1) * 100}
            className={classes.slider}
            defaultValue={100}
            min={10}
            max={100}
            step={1}
            onChange={(evt, value) => handleSliderChange("opacity", value / 100)}
            onChangeCommitted={(evt, value) => handleChange("opacity")({ target: { value: value / 100 } })}
          />
        </Grid>
      </Grid>
      <Grid item>
        <Divider />
      </Grid>
      <Button size="small" className={classes.deleteButton} onClick={() => handleDelete(id)}>
        {t("ACTIONS.REMOVE")}
      </Button>
      <Divider />
    </Grid>
  );
}


export default wrapper({ styles }, PatternsEditForm);
