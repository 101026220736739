import { Grid, IconButton } from "@material-ui/core";
import { Fullscreen, FullscreenExit, SwapHoriz } from "@material-ui/icons";
import FavoriteIcon from "@material-ui/icons/FavoriteBorder";
import React from "react";
import AppContext from "../AppContext";
import wrapper from "../utils/wrapper";
import MobileDesignSelectorDialog from "./MobileDesignSelectorDialog";
import SavingConfirmModal from "./SavingConfirmModal";
import ShareButton from "./ShareButton";


const styles = () => ({
  container : {
    position : "absolute",
    bottom : 0,
    left : 0,
    width : "100%"
  },

  designButtonContainer : {
    position : "fixed",
    top : 0,
    left : 0
  },

  buttonsContainer : {
    pointerEvents : "none",
    width : "auto"
  },

  button : {
    pointerEvents : "all"
  },

  icon : {
    color : "#000"
  }
});


function ViewerMobileButtons({ classes }) {
  const { customizer } = React.useContext(AppContext);
  const [isFullScreen, setIsFullScreen] = React.useState(false);
  const [isDesignSelectorOpen, setIsDesignSelectorOpen] = React.useState(false);
  const [isSavingConfirmModalOpen, setIsSavingConfirmModalOpen] = React.useState(false);

  React.useEffect(() => {
    if (!customizer?.viewer) { return; }

    if (isFullScreen) {
      customizer.viewer.toggleFullscreen();
    } else if (customizer.viewer.isFullscreen) {
      customizer.viewer.exitFullscreen();
    }

  }, [isFullScreen]);

  const handleSave = () => {
    customizer.saveCustomization()
      .then(res => !!res && setIsSavingConfirmModalOpen(true));
  };

  const toggleFullscreen = () => {
    setIsFullScreen(!isFullScreen);
  };

  const toggleDesignSelector = () => {
    setIsDesignSelectorOpen(!isDesignSelectorOpen);
  };

  return (
    <Grid className={classes.container} container alignItems="flex-end" justifyContent="space-between" direction="row" wrap="nowrap">
      <Grid className={classes.designButtonContainer}>
        {!isFullScreen
        && (
        <IconButton className={classes.button} onClick={toggleDesignSelector}>
          <SwapHoriz className={classes.icon} />
        </IconButton>
)}
      </Grid>
      <Grid item container direction="column" alignItems="center" justifyContent="flex-end" className={classes.buttonsContainer}>
        <IconButton className={classes.button} onClick={toggleFullscreen}>
          {
            isFullScreen
              ? <FullscreenExit className={classes.icon} />
              : <Fullscreen className={classes.icon} />
          }
        </IconButton>
      </Grid>
      <Grid item container direction="column" alignItems="center" justifyContent="flex-end" className={classes.buttonsContainer}>
        {
          !isFullScreen && !!customizer?.userIsLogged
          && (
          <IconButton className={classes.button} onClick={handleSave}>
            <FavoriteIcon className={classes.icon} />
          </IconButton>
)
        }
        {
          !isFullScreen && !!customizer?.userIsLogged
          && <ShareButton className={classes.button} />
        }
      </Grid>
      <SavingConfirmModal isOpen={isSavingConfirmModalOpen} onClose={() => setIsSavingConfirmModalOpen(false)} />
      <MobileDesignSelectorDialog isOpen={isDesignSelectorOpen} onClose={toggleDesignSelector} />
    </Grid>
  );
}


export default wrapper({ styles }, ViewerMobileButtons);
