import { config, translations } from "../presets/presetsTarget";


const tMock = (string, opts) => {
  let searchString = string;
  // Replace custom variables
  if (typeof opts === "object") {
    Object.entries(opts)
      .forEach(([_key, _value]) => {
        if ((typeof _value === "string" || typeof _value === "number") && searchString.includes(`@${_key}`)) {
          searchString = searchString.replace(`@${_key}`, _value);
        }
      });
  }

  return searchString;
};

const usedKeys = [];

window.__getTranslationKeys = () => usedKeys;


/**
 * This hook is used to translate the app text content
 * It is inspired from i18next and can be used this way:
 * const { t } = useTranslate();
 * t('SOME.STRING.TO.TRANSLATE')
 *
 * @returns {{t: (function(...[*]): *)}}
 */
function useTranslate() {
  return {
    t : (...params) => {
      const handler = config.enableDrupalI18n && !!window.Drupal?.t
        ? window.Drupal?.t
        : tMock;

      // Store the key
      if (typeof params[0] === "string" && !usedKeys[params[0]]) {
        usedKeys.push(params[0]);
      }

      let string = params[0] || "";
      const parameters = params[1] || {};
      const opts = {
        ...(params[2] || {}),
        context : "Customizer"
      };

      // If the string matches a dot notation, get its related translation
      if (string.match(/\.[^.]/) && !string.match(/\s/)) {
        const dotNotationString = string;

        // Convert dot notation into the corresponding string
        string = string.split(".").reduce((o, i) => o?.[i], translations);

        if (!string && process.env.NODE_ENV === "development") {
          console.warn(`The "${dotNotationString}" dit not match any translation string`);
        }
      }

      // Prevent drupal errors
      if (!string) {
        string = "";
      }

      return handler(string, parameters, opts);
    }
  };
}


export default useTranslate;
