import { CircularProgress, Grid, Modal, Paper, Typography } from "@material-ui/core";
import React from "react";
import useTranslate from "../lib/useTranslate";
import wrapper from "../utils/wrapper";


const styles = theme => ({
  loadingModal : {
    width : "100%",
    height : "100%",
    display : "flex",
    alignItems : "center",
    justifyContent : "center"
  },

  loadingPaper : {
    padding : theme.spacing(5)
  }
});


function LoadingModal({ classes, message }) {
  const { t } = useTranslate();

  return (
    <Modal
      open={!!message}
      className={classes.loadingModal}
    >
      <Paper className={classes.loadingPaper}>
        <Grid container alignItems="center" justifyContent="center" direction="column">
          <Typography variant="body2" paragraph>
            {t(message)}
          </Typography>
          <CircularProgress />
        </Grid>
      </Paper>
    </Modal>
  );
}


export default wrapper({ styles }, LoadingModal);
