import config from "./config";
import presets from "./customizer-presets.json";
import theme from "./theme";
import "./styles/fonts.css";

export default {
  config,
  presets,
  theme
};
