import { createTheme } from "@material-ui/core/styles";


const theme = createTheme({

  /** *********************************************
   * PALETTE
   ********************************************** */

  palette : {
    primary : {
      main : "#f00"
    },
    secondary : {
      main : "#181818"
    },
    error : {
      main : "#ff1700"
    },
    grey : {
      40 : "#E8E8E8",
      50 : "#D8D8D8"
    },
    text : {
      primary : "#181818",
      secondary : "#818181",
      grey : "#B6B6B6"
    }
  },

  /** *********************************************
   * TYPOGRAPHY
   ********************************************** */

  typography : {
    fontSize : 16,
    htmlFontSize : 16,
    fontFamily : "DIN, Arial, sans-serif",
    fontWeightBold : 700,
    h1 : {
      fontSize : "1.125rem",
      color : "#181818",
      fontWeight : 700,
      textTransform : "uppercase"
    },
    h2 : {
      fontSize : "1.125rem",
      color : "#181818",
      fontWeight : 700,
      textTransform : "uppercase"
    },
    h3 : {
      fontSize : "1.0625rem",
      color : "#181818",
      fontWeight : 400
    },
    h4 : {
      fontSize : "0.8125rem",
      color : "#818181",
      fontWeight : 400
    },
    subtitle1 : {
      fontSize : "0.75",
      color : "#818181",
      fontWeight : 400
    },
    body2 : {
      fontSize : "1.0625rem",
      color : "#181818",
      fontWeight : 400
    },
    body1 : {
      fontSize : "0.9375rem",
      color : "#818181",
      fontWeight : 400
    },
    caption : {
      fontSize : "0.75rem",
      color : "#818181",
      fontWeight : 400
    },
    button : {
      fontSize : "0.9375rem",
      color : "#818181",
      fontWeight : 400,
      textTransform : "uppercase",
      letterSpacing : 1.88
    }
  },

  /** *********************************************
   * PROPS OVERRIDES
   ********************************************** */

  props : {
    MuiInput : {
      disableUnderline : true
    },
    Hidden : {
      implementation : "css"
    }
  },

  /** *********************************************
   * STYLES OVERRIDES
   ********************************************** */

  overrides : {},

  /** *********************************************
   * GRADIENTS
   ********************************************** */

  gradients : {
    default : "linear-gradient(to bottom, rgba(0,0,0,0.65) 0%,rgba(0,0,0,0) 100%)"
  },

  /** *********************************************
   * Transitions
   ********************************************** */

  transitions : {
    easing : {
      easeOut : "cubic-bezier(0.190, 1.000, 0.220, 1.000)"
    }
  }
});


/** *********************************************
 * Styles
 *
 * This adds some pre-built styles objects that
 * can be easily used through the app
 ********************************************** */


theme.styles = {

  hover : {
    offLinkUnderline : {
      position : "relative",
      "&::after" : {
        content : '""',
        position : "absolute",
        bottom : 0,
        left : 0,
        width : "100%",
        height : 1,
        backgroundColor : theme.palette.text.grey,
        transform : "scaleX(0)",
        transition : `transform 0.4s ${theme.transitions.easing.easeOut}`,
        transformOrigin : "100% 100%"
      },
      "&:hover::after" : {
        transform : "scaleX(1)",
        transformOrigin : "0 0"
      }
    },
    offLinkUnderlineWhite : {
      position : "relative",
      "&::after" : {
        content : '""',
        position : "absolute",
        bottom : 0,
        left : 0,
        width : "100%",
        height : 1,
        backgroundColor : theme.palette.text.white,
        transform : "scaleX(0)",
        transition : `transform 0.4s ${theme.transitions.easing.easeOut}`,
        transformOrigin : "100% 100%"
      },
      "&:hover::after" : {
        transform : "scaleX(1)",
        transformOrigin : "0 0"
      }
    },
    onLinkUnderline : {
      position : "relative",
      "&::after" : {
        content : '""',
        position : "absolute",
        bottom : 0,
        left : 0,
        width : "100%",
        height : 1,
        backgroundColor : theme.palette.text.grey,
        transform : "scaleX(1)",
        transition : `transform 0.4s ${theme.transitions.easing.easeOut}`,
        transformOrigin : "0 0"
      },
      "&:hover::after" : {
        transform : "scaleX(0)",
        transformOrigin : "100% 100%"
      }
    }
  }
};

theme.shadows[1] = "0 5px 10px 0 rgba(0,0,0,0.05)";

export default theme;
