import { Backdrop, CircularProgress, Grid, Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import React from "react";
import useTranslate from "../lib/useTranslate";
import wrapper from "../utils/wrapper";


const styles = theme => ({
  loadingBackDrop : {
    zIndex : 2000,
    backgroundColor : "#fff"
  },

  loadingText : {
    marginTop : theme.spacing(4)
  }
});


function CircularProgressWithLabel(props) {
  const { value } = props;

  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress variant="static" {...props} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="caption" component="div" color="textSecondary">
          {`${Math.round(value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}


function CustomizerLoadingBackdrop({
 classes,
 isCustomizerReady,
 loadingProgressValue,
 loadingProgressMessage,
 isStaging
}) {
  const { t } = useTranslate();

  return (
    <Backdrop open={!isCustomizerReady} className={classes.loadingBackDrop}>
      <Grid container direction="column" alignItems="center" justifyContent="center">
        <CircularProgressWithLabel size={40} variant="determinate" value={loadingProgressValue} message={loadingProgressMessage} />
        <Typography className={classes.loadingText} paragraph={isStaging && !!loadingProgressMessage}>
          {t("SENTENCES.CUSTOMIZER_LOADING_MESSAGE")}
        </Typography>
        {
          isStaging && !!loadingProgressMessage
          && (
          <Typography variant="caption">
            DEBUG: {loadingProgressMessage}
          </Typography>
)
        }
      </Grid>
    </Backdrop>
  );
}


export default wrapper({ styles }, CustomizerLoadingBackdrop);
