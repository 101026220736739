import { Drawer, Grid, IconButton, Typography } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import CloseIcon from "@material-ui/icons/Close";
import React from "react";
import Slider from "react-slick";
import AppContext from "../AppContext";
import useTranslate from "../lib/useTranslate";
import wrapper from "../utils/wrapper";
import Button from "./Button";
import { getFooterItems } from "./Footer";


const Transition = React.forwardRef((props, ref) => <Slide direction="down" ref={ref} {...props} />);


const styles = theme => ({
  dialog : {
    maxHeight : 160
  },

  buttonsContainer : {
    height : "100%",
    padding : theme.spacing(1)
  },

  button : {
    margin : theme.spacing(0.5, 0),
    padding : theme.spacing(),
    height : 50,
    maxHeight : 50,
    width : "100%"
  },

  drawerContainer : {
    height : "100vh",
    overflow : "hidden"
  },


  sliderContainer : {
    width : "100%",
    marginTop : theme.spacing(5),
    paddingLeft : theme.spacing(3),
    paddingRight : theme.spacing(3),

    "&.slick-slider" : {
      height : "100%",
      width : "100%"
    },

    "& .slick-slide" : {
      display : "flex",
      justifyContent : "center"
    },

    "& .slick-list" : {
      overflow : "visible"
    }
  },

  itemImage : {
    width : "60vw",
    cursor : "pointer",
    transition : `transform 1.3s ${theme.transitions.easing.easeOut}`
  },

  itemLabel : {
    marginTop : theme.spacing(2)
  },

  drawerCloseButton : {
    position : "absolute",
    top : 0,
    left : 0
  }
});

const sliderSettings = {
  // accessibility: false,
  draggable : true,
  infinite : true,
  slidesToShow : 1,
  slidesToScroll : 1,
  dots : true,
  arrows : false
};


function MobileDesignSelectorDialog({ classes, isOpen, onClose }) {
  const { customizer, appConfig } = React.useContext(AppContext);
  const { t } = useTranslate();
  const [openedDrawer, setOpenedDrawer] = React.useState(undefined);

  const handleClose = () => {
    setOpenedDrawer(undefined);
    onClose();
  };

  if (!customizer) { return null; }

  const dialogItems = getFooterItems(t, appConfig, customizer);
  const handleButtonClick = type => () => setOpenedDrawer(type);
  const drawerItem = dialogItems[openedDrawer];

  const onDesignClick = item => {
    drawerItem.onClick(item);
  };

  return (
    <>
      {/** DIALOG TO CHOSE BETWEEN DESIGNS AND VARIANTS * */}

      <Dialog fullScreen open={isOpen && !openedDrawer} onClose={handleClose} TransitionComponent={Transition} className={classes.dialog}>
        <Grid container direction="column" className={classes.buttonsContainer} justifyContent="center" alignItems="center">
          <Button className={classes.button} onClick={handleButtonClick("design")}>
            {t("ACTIONS.SELECT_ANOTHER_DESIGN")}
          </Button>
          {
            !!dialogItems.variant.items?.length
            && (
            <Button className={classes.button} onClick={handleButtonClick("variant")}>
              {t("ACTIONS.SELECT_ANOTHER_VARIATION")}
            </Button>
)
          }
        </Grid>
      </Dialog>

      {/** DRAWER WITH THE SLIDER CONTAINING THE OTHER DESIGNS * */}

      {
        !!drawerItem
        && (
        <Drawer variant="temporary" anchor="bottom" open onClose={onClose} transitionDuration={600}>
          <Grid container direction="column" alignItems="center" justifyContent="center" className={classes.drawerContainer} wrap="nowrap">
            <IconButton color="inherit" onClick={handleClose} aria-label="close" className={classes.drawerCloseButton}>
              <CloseIcon />
            </IconButton>
            <Typography variant="h2" className={classes.drawerTitle} onClick={onClose}>
              {t(drawerItem.title)}
            </Typography>
            <Grid container className={classes.sliderContainer}>
              <Grid item md={12} xs={12}>
                <Slider {...sliderSettings} infinite={drawerItem.items.length > sliderSettings.slidesToShow}>
                  {
                    drawerItem.items.map(_item => (
                      <button
                        type="button"
                        key={_item.code}
                        onClick={() => onDesignClick(_item)}
                      >
                        <Grid container className={classes.itemContainer} direction="column" alignItems="center" justifyContent="space-between">
                          <img src={drawerItem.getItemImage(_item)} className={classes.itemImage} alt={t(_item.label || _item.name || _item.title)} />
                          {
                            !!_item.label
                            && (
                            <Typography align="center" className={classes.itemLabel}>
                              {_item.label}
                            </Typography>
)
                          }
                        </Grid>
                      </button>
                    ))
                  }
                </Slider>
              </Grid>
            </Grid>
          </Grid>
        </Drawer>
)
      }
    </>
  );
}


export default wrapper({ styles }, MobileDesignSelectorDialog);
