import { Button, Grid } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import Grow from "@material-ui/core/Grow";
import Switch from "@material-ui/core/Switch";
import Typography from "@material-ui/core/Typography";
import React from "react";
import AppContext from "../../../AppContext";
import FormContext from "../../../FormContext";
import useTranslate from "../../../lib/useTranslate";
import wrapper from "../../../utils/wrapper";
import ColorsPicker from "../../ColorsPicker";
import Select from "../../Select";
import Slider from "../../Slider";


const OUTLINES_DEFAULT_STROKE_WIDTH = 5;

const styles = theme => ({
  container : {
    width : "100%",
    display : "flex",
    marginTop : theme.spacing(3)
  },

  deleteButton : {
    fontSize : "0.7rem",
    alignSelf : "flex-start",
    marginBottom : theme.spacing(),
    color : theme.palette.error.main
  }
});


function TextsEditForm({ classes, text = {}, id, availableLocations }) {
  const [sliderValues, setSlidersValues] = React.useState(text || {});
  const { customizer, appConfig } = React.useContext(AppContext);
  const [showOutlines, setShowOutlines] = React.useState(!!text.strokeWidth);
  const { formValues, setFormValues } = React.useContext(FormContext);
  const { t } = useTranslate();

  const usagesOptions = (appConfig.formVariants.texts.settings.getUsageOptions(customizer.productType) || [])
    .filter(_option => !appConfig.formVariants.texts.settings.onePerUsage
      || (!Object.values(formValues.custom.texts || {}).some(_text => _text.usage === _option.value))
      || text?.usage === _option.value);

  const handleSliderChange = (field, value) => {
    setSlidersValues(prevState => ({
      ...prevState,
      [field] : value
    }));
  };

  const handleChange = (field, cb) => async e => {
    const nextConfig = { ...text, [field]: e.target.value };
    const fillColorName = (appConfig.customizerPresets.texts.availableColors.find(_color => _color.code === nextConfig.fillColor))?.label;
    const strokeColorName = (appConfig.customizerPresets.texts.availableColors.find(_color => _color.code === nextConfig.strokeColor))?.label;
    const { pngImage, svgString } = await customizer.getBase64FromText(text.text, nextConfig);
    let location = field === "location" ? e.target.value : text?.location;

    // Change the location of the text depending on the given usage, if the feature is enabled
    if (field === "usage") {
      const usageConfig = appConfig.formVariants.texts.settings.getUsageOptions(customizer.productType)
        .find(_usage => _usage.value === e.target.value);

      if (usageConfig.targetPosition) {
        location = usageConfig.targetPosition;
      }
    }

    // Check if images overlaid is allowed
    if (field === "location") {
      const imageCanBeOverlaid = await customizer.imageCanBeOverlaid(location);

      if (!imageCanBeOverlaid) { return; }
    }

    setFormValues({
      custom : {
        texts : {
          [id] : {
            [field] : e.target.value,
            image : pngImage,
            // It is mandatory to clear `imageUrl` so the new text image gets uploaded to server
            imageUrl : null,
            svgString,
            fillColorName,
            strokeColorName,
            location
          }
        }
      }
    }, res => {
      // In case of a location update, we must also change the current viewer viewpoint
      if (!!res && field === "location") {
        const newLocation = availableLocations.find(_location => _location.value === e.target.value);

        if (newLocation && newLocation.viewpoint) {
          customizer.changeViewpoint(newLocation.viewpoint);
        }
      }

      if (typeof cb === "function") {
        cb(res);
      }
    });
    handleSliderChange(field, e.target.value);
  };

  const handleOutlinesToggle = async () => {

    const fields = {
      strokeWidth : showOutlines ? 0 : OUTLINES_DEFAULT_STROKE_WIDTH,
      strokeColor : text.strokeColor || appConfig.customizerPresets.texts.availableColors[1].code
    };
    const strokeColorName = (appConfig.customizerPresets.texts.availableColors.find(_color => _color.code === fields.strokeColor))?.label;
    const { pngImage, svgString } = await customizer.getBase64FromText(text.text, { ...text, ...fields });

    setFormValues({
      custom : {
        texts : {
          [id] : {
            ...fields,
            image : pngImage,
            // It is mandatory to clear `imageUrl` so the new text image gets uploaded to server
            imageUrl : null,
            svgString,
            strokeColorName
          }
        }
      }
    });
    setShowOutlines(!showOutlines);
  };

  React.useEffect(() => {
    setSlidersValues(text);
    setShowOutlines(!!text.strokeWidth);
  }, [text, setSlidersValues]);

  const handleDelete = () => {
    setFormValues({
      custom : {
        texts : {
          [id] : null
        }
      }
    });
  };

  if (!text || !text.text) { return null; }

  return (
    <Grid container direction="column" className={classes.container} spacing={2}>
      {
        !appConfig.formVariants.texts.settings.hiddenInputs?.includes("position")
        && (
        <Grid item container wrap="nowrap" alignItems="center" justifyContent="space-between">
          <Grid item xs={2}>
            <Typography variant="body2">{t("WORDS.POSITION")}</Typography>
          </Grid>
          <Grid item xs={8}>
            <Select
              value={text.location}
              onChange={handleChange("location")}
              options={availableLocations}
            />
          </Grid>
        </Grid>
)
      }
      {
        !appConfig.formVariants.texts.settings.hiddenInputs?.includes("usage")
        && (
        <Grid item container wrap="nowrap" alignItems="center" justifyContent="space-between">
          <Grid item xs={2}>
            <Typography variant="body2">{t("WORDS.USAGE")}</Typography>
          </Grid>
          <Grid item xs={8}>
            <Select
              noEmptyItem
              value={text.usage}
              onChange={handleChange("usage")}
              options={usagesOptions}
            />
          </Grid>
        </Grid>
)
      }
      {
        !appConfig.formVariants.texts.settings.hiddenInputs?.includes("font")
        && (
        <Grid item container wrap="nowrap" alignItems="center" justifyContent="space-between">
          <Grid item xs={2}>
            <Typography variant="body2">{t("WORDS.FONT")}</Typography>
          </Grid>
          <Grid item xs={8}>
            <Select
              value={text.fontFamily}
              onChange={handleChange("fontFamily")}
              options={appConfig.customizerPresets.texts.availableFonts}
            />
          </Grid>
        </Grid>
)
      }
      {
        !appConfig.formVariants.texts.settings.hiddenInputs?.includes("color")
        && (
        <Grid item container wrap="nowrap" alignItems="center" justifyContent="space-between">
          <Grid item xs={2}>
            <Typography variant="body2">{t("WORDS.COLOR")}</Typography>
          </Grid>
          <Grid item xs={8} container alignItems="center" justifyContent="center">
            <ColorsPicker
              colors={appConfig.customizerPresets.texts.availableColors}
              onChange={handleChange("fillColor")}
            />
          </Grid>
        </Grid>
)
      }
      {
        !appConfig.formVariants.texts.settings.hiddenInputs?.includes("scale")
        && (
        <Grid item container wrap="nowrap" alignItems="center" justifyContent="space-between">
          <Grid item xs={2}>
            <Typography variant="body2">{t("WORDS.SIZE")}</Typography>
          </Grid>
          <Grid item xs={8} container alignItems="center" justifyContent="center">
            <Slider
              value={sliderValues.sizeInCm || { width: 10 }}
              className={classes.slider}
              defaultValue={OUTLINES_DEFAULT_STROKE_WIDTH}
              min={0.5}
              max={40}
              step={1}
              onChange={(evt, value) => handleSliderChange("sizeInCm", value)}
              onChangeCommitted={(evt, value) => handleChange("sizeInCm")({ target: { value } })}
            />
          </Grid>
        </Grid>
)
      }
      {
        !appConfig.formVariants.texts.settings.hiddenInputs?.includes("rotation")
        && (
        <Grid item container wrap="nowrap" alignItems="center" justifyContent="space-between">
          <Grid item xs={2}>
            <Typography variant="body2">{t("WORDS.ROTATION")}</Typography>
          </Grid>
          <Grid item xs={8} container alignItems="center" justifyContent="center">
            <Slider
              value={sliderValues.userRotation || 1}
              className={classes.slider}
              defaultValue={1}
              min={-180}
              max={180}
              step={10}
              onChange={(evt, value) => handleSliderChange("userRotation", value)}
              onChangeCommitted={(evt, value) => handleChange("userRotation")({ target: { value } })}
            />
          </Grid>
        </Grid>
)
      }
      {
        !appConfig.formVariants.texts.settings.hiddenInputs?.includes("opacity")
        && (
        <Grid item container wrap="nowrap" alignItems="center" justifyContent="space-between">
          <Grid item xs={2}>
            <Typography variant="body2">{t("WORDS.OPACITY")}</Typography>
          </Grid>
          <Grid item xs={8} container alignItems="center" justifyContent="center">
            <Slider
              value={(sliderValues.opacity || 1) * 100}
              className={classes.slider}
              defaultValue={100}
              min={10}
              max={100}
              step={1}
              onChange={(evt, value) => handleSliderChange("opacity", value / 100)}
              onChangeCommitted={(evt, value) => handleChange("opacity")({ target: { value: value / 100 } })}
            />
          </Grid>
        </Grid>
)
      }
      {
        !appConfig.formVariants.texts.settings.hiddenInputs?.includes("stroke")
        && (
        <>
          <Grid item>
            <Divider />
          </Grid>
          <Grid item container wrap="nowrap" alignItems="center" justifyContent="space-between">
            <Grid item xs={2}>
              <Typography variant="body2">{t("WORDS.OUTLINES")}</Typography>
            </Grid>
            <Grid item xs={8} container alignItems="center" justifyContent="flex-end">
              <Switch
                size="small"
                checked={showOutlines}
                color="primary"
                onChange={handleOutlinesToggle}
                inputProps={{ style: { pointerEvents: "all" } }}
              />
            </Grid>
          </Grid>
          <Grow in={showOutlines} unmountOnExit>
            <Grid item container wrap="nowrap" alignItems="center" justifyContent="space-between">
              <Grid item xs={2}>
                <Typography variant="body2">{t("WORDS.COLOR")}</Typography>
              </Grid>
              <Grid item xs={8} container alignItems="center" justifyContent="center">
                <ColorsPicker
                  colors={appConfig.customizerPresets.texts.availableColors}
                  onChange={handleChange("strokeColor")}
                />
              </Grid>
            </Grid>
          </Grow>
          <Grow in={showOutlines} unmountOnExit>
            <Grid item container wrap="nowrap" alignItems="center" justifyContent="space-between">
              <Grid item xs={2}>
                <Typography variant="body2">{t("WORDS.SIZE")}</Typography>
              </Grid>
              <Grid item xs={8} container alignItems="center" justifyContent="center">
                <Slider
                  value={sliderValues.strokeWidth || OUTLINES_DEFAULT_STROKE_WIDTH}
                  className={classes.slider}
                  defaultValue={OUTLINES_DEFAULT_STROKE_WIDTH}
                  min={1}
                  max={20}
                  step={2}
                  onChange={(evt, value) => handleSliderChange("strokeWidth", value)}
                  onChangeCommitted={(evt, value) => handleChange("strokeWidth")({ target: { value } })}
                />
              </Grid>
            </Grid>
          </Grow>
        </>
)
      }
      <Button size="small" className={classes.deleteButton} onClick={handleDelete}>
        {t("ACTIONS.REMOVE")}
      </Button>
      <Divider />
    </Grid>
  );
}


export default wrapper({ styles }, TextsEditForm);
