import { Grid, Hidden, IconButton, Typography } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/InfoOutlined";
import numeral from "numeral";
import React from "react";
import AppContext from "../AppContext";
import useTranslate from "../lib/useTranslate";
import wrapper from "../utils/wrapper";


const styles = theme => ({
  container : {
    height : "100%",

    [theme.breakpoints.down("sm")] : {
      alignItems : "center",
      margin : theme.spacing(2, 0),
      height : "auto",
      flexWrap : "nowrap"
    }
  },

  priceContainer : {
    [theme.breakpoints.down("sm")] : {
      alignItems : "center",
      marginTop : theme.spacing(),
      marginBottom : theme.spacing()
    }
  },

  button : {
    position : "relative",
    border : 0,
    cursor : "pointer",
    padding : 0,
    background : "transparent",
    paddingLeft : theme.spacing(2),
    marginTop : theme.spacing(3),
    marginBottom : theme.spacing(7),
    [theme.breakpoints.down("md")] : {
      marginTop : theme.spacing(2),
      marginBottom : theme.spacing(3)
    },
    ...theme.styles.hover.onLinkUnderline,

    "&:before" : {
      content : '"‹"',
      position : "absolute",
      left : 0,
      top : 0,
      fontFamily : "DIN",
      color : "#818181",
      lineHeight : "18px",
      fontSize : 22,
      transition : `transform 0.6s ${theme.transitions.easing.easeOut}`
    },

    "&:hover" : {
      "&::after" : theme.styles.hover.onLinkUnderline["&:hover::after"],
      "&::before" : {
        transform : "translateX(-5px)"
      }
    }
  },

  price : {
    position : "relative",
    fontSize : "2.1875rem",
    color : theme.palette.text.primary,
    fontWeight : 700,

    "&:before" : {
      content : "attr(data-each-translation)",
      position : "absolute",
      left : theme.spacing(),
      top : 7,
      paddingLeft : "100%",
      color : theme.palette.text.grey,
      fontFamily : "DIN",
      fontSize : "0.75rem",
      fontWeight : 400
    },

    [theme.breakpoints.down("sm")] : {
      fontSize : "1.125rem",

      "&:before" : {
        display : "none"
      }
    }
  },

  smTitleCaption : {
    marginLeft : theme.spacing(),
    fontSize : "1.125rem"
  },

  smInfoButton : {
    position : "absolute",
    right : -30,
    top : 4,
    padding : 0,
    width : 10,
    height : 10
  }
});


function numPrice(price = "") {
  return numeral(String(price)).format("00.00");
}


// Since the back-end developer does not seem to be able to provide a price in the same format,
// we are forced to do a lot of unnecessary manipulation to ensure some consistency in the price display
function getPriceFromProductInfo(isVariablePrice, t, productInfos) {
  if (productInfos.formattedPrice) { return productInfos.formattedPrice; }

  let price = isVariablePrice
    ? `<small>${t("WORDS.FROM")}</small> ${numPrice(productInfos.minPrice)} <small>${t("WORDS.TO")}</small> ${numPrice(
      productInfos.maxPrice
)} €`
    : productInfos.price || productInfos.minPrice || "";

  if (typeof price === "string" && price.includes("€")) {
    price = price.replace("€", "");
  }

  if (typeof price === "number") {
    price = numPrice(price);
  }

  return price.replace(/\./gm, ",");
}


function ProductInfo({ classes, productInfos = {}, openDrawer }) {
  const { t } = useTranslate();
  const { appConfig } = React.useContext(AppContext);

  const isVariablePrice = !!productInfos.minPrice && !!productInfos.maxPrice && productInfos.minPrice !== productInfos.maxPrice;
  const price = getPriceFromProductInfo(isVariablePrice, t, productInfos);

  const hasEmbellishment = isVariablePrice
    ? productInfos.defaultMinPrice !== productInfos.minPrice || productInfos.defaultMaxPrice !== productInfos.maxPrice
    : productInfos.price !== productInfos.defaultPrice;

  return (
    <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start" className={classes.container}>
      <div style={{ position: "relative" }}>
        <Typography
          variant="h2"
          style={{
          letterSpacing : "3px",
          textTransform : "initial"
        }}
        >{productInfos.title}
        </Typography>
        <Hidden mdUp>
          <IconButton className={classes.smInfoButton} onClick={openDrawer} size="small">
            <InfoIcon fontSize="small" />
          </IconButton>
        </Hidden>
      </div>

      <Hidden smDown>
        <button type="button" className={classes.button} onClick={openDrawer}>
          <Typography variant="body1">
            {t("ACTIONS.SEE_PRODUCT_DESCRIPTION")}
          </Typography>
        </button>
      </Hidden>

      <Grid container direction="column" alignItems="flex-start" className={classes.priceContainer} wrap="nowrap">
        {
          isVariablePrice && appConfig.dynamicPrice !== true
          && (
          <Typography variant="caption" style={{ whiteSpace: "nowrap" }}>
            {t("COMPONENTS.PRODUCT_INFO.RECOMMENDED_RETAIL_PRICE")}
          </Typography>
)
        }
        <Typography className={classes.price} data-each-translation={appConfig.dynamicPrice !== true ? t("WORDS.EACH") : ""}>
          <span dangerouslySetInnerHTML={{ __html: price }} />
          {typeof price !== "string" && "€"}
        </Typography>
        <Hidden smDown>
          {
            appConfig.dynamicPrice === true
            && (
            <Typography variant="caption" paragraph>
              {
                t(hasEmbellishment
                  ? "COMPONENTS.PRODUCT_INFO.DYNAMIC_PRICE_HELP_TEXT.WITH_EMBELLISHMENT"
                  : "COMPONENTS.PRODUCT_INFO.DYNAMIC_PRICE_HELP_TEXT.WITHOUT_EMBELLISHMENT")
              }
            </Typography>
)
          }
        </Hidden>
      </Grid>
    </Grid>
  );
}


export default wrapper({ styles }, ProductInfo);
