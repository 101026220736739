import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { presetsTarget } from "./presets/presetsTarget";
import "./assets/slick.css";
import "./assets/main-fonts.css";

console.log(`ⓘ Loading the "${presetsTarget}" target config`);

ReactDOM.render(
  <App />,
  document.getElementById("defil-customizer-webapp")
);
