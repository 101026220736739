import FormControl from "@material-ui/core/FormControl";
import InputBase from "@material-ui/core/InputBase";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import MUISelect from "@material-ui/core/Select";
import { withStyles } from "@material-ui/core/styles";
import React from "react";
import useTranslate from "../lib/useTranslate";


const BootstrapInput = withStyles(theme => ({
  root : {
    width : "100%",
    "label + &" : {
      marginTop : theme.spacing(3)
    }
  },
  input : {
    width : "100%",
    borderRadius : 10,
    position : "relative",
    backgroundColor : theme.palette.grey[40],
    fontSize : 16,
    padding : "10px 26px 10px 12px",
    transition : theme.transitions.create(["border-color", "box-shadow"]),

    "&:focus" : {
      borderRadius : 10,
      borderColor : "transparent",
      boxShadow : "none"
    }
  }
}))(InputBase);


function Select({ className, value, options = [], onChange, noEmptyItem, label, ...rest }) {
  const { t } = useTranslate();

  return (
    <FormControl style={{ width: "100%" }}>
      {
        !!label
        && (
        <InputLabel shrink id="demo-simple-select-placeholder-label-label">
          {label}
        </InputLabel>
)
      }
      <MUISelect
        value={value || ""}
        onChange={onChange}
        input={<BootstrapInput />}
        className={className}
        {...rest}
      >
        {
          !noEmptyItem
          && <MenuItem value="" />
        }
        {
          options.map(_option => (
            <MenuItem
              value={typeof _option === "string" ? _option : _option.value}
              key={_option.label || _option.value || _option}
            >
              <span style={{ fontFamily: _option.value }}>
                {t(typeof _option === "string" ? _option : _option.label)}
              </span>
            </MenuItem>
          ))
        }
      </MUISelect>
    </FormControl>
  );
}

export default Select;
