import MUISnackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import clx from "classnames";
import React from "react";
import AppContext from "../AppContext";
import useTranslate from "../lib/useTranslate";
import wrapper from "../utils/wrapper";


const styles = () => ({});


function Snackbar({ classes, children, severity = "warning", className, messageKey }) {
  const [value, setValue] = React.useState("");
  const { appConfig } = React.useContext(AppContext);
  const { t } = useTranslate();

  React.useEffect(() => {
    const nextValue = appConfig.viewerErrorsMap[children] || children;

    if (nextValue && nextValue !== value) {
      setValue(nextValue);
    }

  }, [messageKey]);

  const handleClose = (evt, reason) => {
    if (reason !== "clickaway") {
      setValue(false);

    }
  };

  if (!value || !value.length) { return null; }

  return (
    <MUISnackbar
      key={messageKey}
      open={!!value}
      autoHideDuration={6000}
      onClose={handleClose}
      className={clx(classes.container, className)}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
    >
      <Alert elevation={6} variant="filled" severity={severity}>
        {t(value)}
      </Alert>
    </MUISnackbar>
  );
}


export default wrapper({ styles }, Snackbar);
