import queryString from "query-string";


/**
 * Some parts of the presets' config may need to be updated depending
 * on the backend settings. For example, it should be possible to provide customer colors dynamically
 * from a backend. The goal of this (a bit ugly) function is to mutate the customizer presets using parameters
 * that have been provided through the DOM.
 *
 * The backend actually used for this project is Drupal and these parameters are provided through the
 * window.drupalSettings.defil_core object.
 *
 * Note that the data provided by the backend MUST follow the same architecture of the current presets' config. There is
 * no parsing nor validation processed here, and it is up to the backend team to follow the same structure and to name the
 * variables correctly.
 *
 * @param {object} presets
 * @returns {*}
 */
function mutatePresetsFromDOM(presets) {
  if (!window.drupalSettings?.defil_core || typeof window.drupalSettings.defil_core !== "object") { return presets; }

  // Handle custom colors defined in Drupal
  if (window.drupalSettings.defil_core.colors) {
    console.log("ⓘ Using custom colors from Drupal");
    presets.colors.availableZones = window.drupalSettings.defil_core.colors;

    // Use the color-zone0 colors for the brand logo
    if (window.drupalSettings.defil_core.colors[0]?.availableColors) {
      presets.brandLogos.availableLogos = presets.brandLogos.availableLogos.map(_logo => ({ ..._logo, availableColors: window.drupalSettings.defil_core.colors[0]?.availableColors }));
    }
  }

  // Handle custom font colors defined in Drupal
  if (window.drupalSettings.defil_core.colors_font) {
    console.log("ⓘ Using custom font colors from Drupal");
    presets.texts.availableColors = window.drupalSettings.defil_core.colors_font;
  }

  // Handle custom brand logo
  if (window.drupalSettings.defil_core.custom_logo) {
    console.log("ⓘ Using custom brand logo from Drupal");
    if (!window.drupalSettings.defil_core.custom_logo.availablePositions) {
      window.drupalSettings.defil_core.custom_logo.availablePositions = presets.brandLogos.availableLogos?.[0]?.availablePositions;
    }

    presets.brandLogos.availableLogos = [window.drupalSettings.defil_core.custom_logo];
  }

  // Handle custom patterns defined in Drupal
  if (window.drupalSettings.defil_core.patterns?.groups?.length) {
    console.log("ⓘ Using custom patterns from Drupal");
    presets.patterns = presets.patterns || {};
    presets.patterns.groups = window.drupalSettings.defil_core.patterns.groups;
  }
  if (window.drupalSettings.defil_core.patterns?.availablePatterns?.length) {
    presets.patterns = presets.patterns || {};
    presets.patterns.availablePatterns = window.drupalSettings.defil_core.patterns.availablePatterns;
  }

  // Handle custom designs defined in Drupal
  if (window.drupalSettings.defil_core.designs.availableDesigns) {
    console.log("ⓘ Using custom designs from Drupal");
    presets.designs.availableDesigns.default = window.drupalSettings.defil_core.designs?.availableDesigns;
    presets.designs.availableDesigns.short = window.drupalSettings.defil_core.designs?.availableDesigns;
  }

  return presets;
}


/**
 * Get the name of the current preset target
 * Currently it should be one of 'defil' 'puma' or 'puma-global'
 *
 * @returns {string[]|string|*}
 */
function getPresetsTarget() {
  const parsedQuery = queryString.parse(window.location.search);

  let presetsTarget = parsedQuery.customizerPresetsTarget || window.customizerPresetsTarget || window.drupalSettings?.defil_core?.site_name;

  if (!presetsTarget || presetsTarget === "fallback") {
    presetsTarget = "defil";
  }

  return presetsTarget;
}


/**
 * Get the config, presets, translations and theme for a given presets target id
 *
 * No validation is done their, an incorrect preset target will throw an error and the app will crash
 * This is because this feature has been added for development purpose and won't be directly used by a user
 *
 * @param {string} presetsTarget
 * @returns {{presets: *, theme: *, config: *}}
 */
function getPresetsConfig(presetsTarget) {
  const { config, presets, theme } = require(`./${presetsTarget}`).default;
  const translations = require(`./${presetsTarget}/translations`);

  // Store the presets directly on the app config, this is where it will generally be consumed
  config.customizerPresets = presets;

  return {
    config,
    presets : mutatePresetsFromDOM(presets),
    theme,
    translations
  };
}


const presetsTarget = getPresetsTarget();
const { config, presets, theme, translations } = getPresetsConfig(presetsTarget);

export {
  presetsTarget,
  config,
  presets,
  theme,
  translations
};
