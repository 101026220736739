import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Modal from "@material-ui/core/Modal";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import ArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import React from "react";
import useTranslate from "../lib/useTranslate";
import wrapper from "../utils/wrapper";
import Button from "./Button";


const styles = theme => ({
  modal : {
    width : "100%",
    height : "100%",
    display : "flex",
    alignItems : "center",
    justifyContent : "center"
  },

  title : {
    [theme.breakpoints.down("sm")] : {
      fontSize : "1.4rem"
    }
  },

  paper : {
    padding : theme.spacing(5),
    overflow : "hidden"
  },

  textField : {
    width : "100%",
    marginBottom : theme.spacing(2),

    "& input" : {
      minHeight : 18,
      borderRadius : 0,
      padding : "18.5px 14px"
    }
  },

  orContainer : {
    padding : theme.spacing(3, 0)
  },

  projectThumb : {
    width : 70,
    marginRight : theme.spacing(3)
  },

  projectsMenu : {
    maxHeight : 380
  },

  projectsMenuItem : {
    minWidth : 300
  },

  selectButton : {
    position : "relative",
    padding : theme.spacing(2),
    border : `1px solid ${theme.palette.grey[200]}`,
    marginBottom : theme.spacing(2),
    cursor : "pointer",
    height : 74,

    "& > img" : {
      maxHeight : 60
    }
  },

  arrowDownIcon : {
    position : "absolute",
    right : theme.spacing(2),
    top : 0,
    bottom : 0,
    margin : "auto"
  }
});


function ProjectTitlePromptModal({ classes, defaultValue = "", currentProjectId, onSubmit, customizer }) {
  const [value, setValue] = React.useState(defaultValue);
  const [isOpen, setIsOpen] = React.useState(true);
  const [userProjects, setUserProjects] = React.useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedProject, setSelectedProject] = React.useState(undefined);
  const { t } = useTranslate();

  React.useEffect(() => {
    customizer.getUserProjectsList()
      .then(setUserProjects);

  }, []);

  React.useEffect(() => {
    if (Array.isArray(userProjects)) {
      setSelectedProject(userProjects.find(_project => _project.id === currentProjectId));
    }
  }, [currentProjectId, userProjects]);

  // Submit and override an existing project
  const handleSubmitOverride = e => {
    e.preventDefault();
    e.stopPropagation();

    setIsOpen(false);
    onSubmit(selectedProject);
  };

  // Submit and create a new project
  const handleSubmitNew = e => {
    e.preventDefault();
    e.stopPropagation();

    setIsOpen(false);
    onSubmit({
      title : value
    });
  };

  const handleClose = () => {
    setIsOpen(false);
    onSubmit(undefined);
  };

  return (
    <Modal
      onClose={handleClose}
      open={isOpen}
      className={classes.modal}
    >
      <Paper className={classes.paper}>
        {
          !!userProjects && userProjects.length > 0
          && (
          <form onSubmit={handleSubmitOverride}>
            <Grid container alignItems="center" justifyContent="center" direction="column">
              <Typography variant="h6" paragraph className={classes.title}>
                {t("COMPONENTS.PROJECT_TITLE_PROMPT_MODAL.SAVE_TO_EXISTING_PROJECT")}
              </Typography>
              <Grid container wrap="nowrap" alignItems="center" onClick={e => setAnchorEl(e.currentTarget)} className={classes.selectButton}>
                {
                  !!selectedProject
                  && (
                  <>
                    <img src={selectedProject.thumb} alt={selectedProject.title} className={classes.projectThumb} />
                    <Typography>
                      {selectedProject.title}
                    </Typography>
                  </>
)
                }
                {
                  !selectedProject
                  && (
                  <Typography>
                    {t("COMPONENTS.PROJECT_TITLE_PROMPT_MODAL.SELECT_PROJECT_IN_LIST")}
                    <ArrowDownIcon className={classes.arrowDownIcon} />
                  </Typography>
)
                }
              </Grid>
              <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
                className={classes.projectsMenu}
              >
                {
                  userProjects
                    // Filter potential duplication
                    .filter((_project, index) => userProjects.findIndex(_p => _p.id === _project.id) === index)
                    .map(_project => (
                      <MenuItem
                        className={classes.projectsMenuItem}
                        key={_project.id}
                        onClick={() => setSelectedProject(_project) || setAnchorEl(null)}
                      >
                        <img src={_project.thumb} alt={_project.title} className={classes.projectThumb} />
                        <Typography>
                          {_project.title}
                        </Typography>
                      </MenuItem>
                    ))
                }
              </Menu>
              <Button variant="contained" type="submit" disabled={!selectedProject}>
                {t("ACTIONS.SAVE")}
              </Button>
            </Grid>
            <Grid container alignItems="center" justifyContent="center" wrap="nowrap" className={classes.orContainer}>
              <Divider style={{ flexGrow: 1 }} />
              <Typography variant="h2" style={{ padding: 8 }}>
                {t("COMPONENTS.PROJECT_TITLE_PROMPT_MODAL.OR")}
              </Typography>
              <Divider style={{ flexGrow: 1 }} />
            </Grid>
          </form>
)
        }
        <form onSubmit={handleSubmitNew}>
          <Grid container alignItems="center" justifyContent="center" direction="column">
            <Typography variant="h6" paragraph className={classes.title}>
              {t("COMPONENTS.PROJECT_TITLE_PROMPT_MODAL.CREATE_NEW_PROJECT")}
            </Typography>
            <TextField
              value={value || ""}
              onChange={e => setValue(e.target.value)}
              className={classes.textField}
              variant="outlined"
              autoFocus={!userProjects}
            />
            <Button variant="contained" type="submit" disabled={!value || value.length === 0}>
              {t("ACTIONS.CREATE")}
            </Button>
          </Grid>
        </form>
      </Paper>
    </Modal>
  );
}


export default wrapper({ styles }, ProjectTitlePromptModal);
