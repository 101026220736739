import { Typography } from "@material-ui/core";
import React from "react";
import SlickSlider from "react-slick";
import AppContext from "../../../AppContext";
import FormContext from "../../../FormContext";
import useTranslate from "../../../lib/useTranslate";
import createObjectId from "../../../utils/createObjectId";
import wrapper from "../../../utils/wrapper";
import TextFormEditInput from "./TextFormEditInput";
import TextsEditForm from "./TextsEditForm";
import TextsInputForm from "./TextsInputForm";


const DEFAULT_COLOR_ZONE_ID = "color-zone0";

const styles = theme => ({
  container : {
    "& .slick-slider" : {
      display : "flex",
      alignItems : "center",
      justifyContent : "center"
    },
    "& .slick-arrow" : {
      display : "flex",
      alignItems : "center",
      justifyContent : "center",
      transform : "scale(2)",
      position : "relative",
      top : 0,

      "&.slick-prev" : {
        left : -10
      },

      "&.slick-next" : {
        right : -10
      },

      "&:before" : {
        color : theme.palette.text.grey,
        content : '"‹"'
      },

      "&.slick-next:before" : {
        content : '"›"'
      }
    }
  },

  panelTitleContainer : {
    display : "flex",
    flexDirection : "row",
    flexWrap : "nowrap",
    alignItems : "center",
    justifyContent : "space-between",
    zIndex : 1,
    width : "100%"
  },

  label__inactive : {
    color : theme.palette.text.grey
  },

  topDivider : {
    marginBottom : theme.spacing()
  },

  deleteButton : {
    width : 16
  }
});

const sliderSettings = {
  // accessibility: false,
  draggable : false,
  infinite : false,
  slidesToShow : 1,
  dots : true
};


function TextsForm({ classes, selectedViewerItemId }) {
  const [selectedTextIndex, setSelectedTextIndex] = React.useState(0);
  const { formValues, setFormValues } = React.useContext(FormContext);
  const { customizer, appConfig } = React.useContext(AppContext);
  const [texts, setTexts] = React.useState([]);
  const sliderRef = React.useRef();
  const { t } = useTranslate();

  const maxTexts = typeof appConfig.formVariants.texts.settings.maxTexts === "object"
    ? appConfig.formVariants.texts.settings.maxTexts[customizer.productType]
    : appConfig.formVariants.texts.settings.maxTexts;
  const availableLocations = appConfig.customizerPresets.texts.availableLocations?.[customizer.productType]
    ?.map(_locationId => ({
      value : _locationId,
      label : appConfig.viewer.predefinedPositions[_locationId]?.label,
      viewpoint : appConfig.viewer.predefinedPositions[_locationId]?.viewpoint
    }));

  // Update the selected text index when selectedViewerItemId changes
  React.useEffect(() => {
    const textIndex = Object.keys(formValues.custom?.texts || {})
      .findIndex(_textId => _textId === selectedViewerItemId);

    if (textIndex >= 0) {
      setSelectedTextIndex(textIndex);
      if (typeof sliderRef?.current?.slickGoTo === "function") {
        sliderRef.current.slickGoTo(textIndex);
      }
    }

  }, [selectedViewerItemId, formValues, sliderRef]);


  // Update the selected text id when the number of texts changes
  React.useEffect(() => {
    const nextTexts = Object.entries(formValues.custom?.texts || {})
      .filter(([, _text]) => !!_text);

    if (nextTexts.length !== texts.length) {
      setSelectedTextIndex(nextTexts.length - 1);
      if (typeof sliderRef?.current?.slickGoTo === "function") {
        sliderRef.current.slickGoTo(nextTexts.length - 1);
      }
    }
    setTexts(nextTexts);

  }, [formValues, texts.length]);

  /**
   * Set the current text using its index
   *
   * @param {number} textIndex
   * @returns {(function(): void)|*}
   */
  const handleSlideChange = textIndex => () => {
    const relatedText = texts[textIndex];

    if (textIndex !== selectedTextIndex) {
      customizer.selectViewerItem(relatedText[0]);
      setSelectedTextIndex(textIndex);
    }
  };


  /**
   * This function can be used to get the default text color depending on Drupal settings
   * For this function to work, drupalSettings.defil_core.colors_font should be defined and containing
   * an array with a bright and dark color, and in customizerPresets.colors.availableZones[DEFAULT_COLOR_ZONE_ID].availableColors[COLOR_ID]
   * the property "bright_default" should be defined. If the bright value is "0", the dark text color will be used and if the value is "1",
   * it is the light color that will be used
   *
   * This function returns null if no matching color has been found
   *
   * @returns {?string}
   */
  const getDefaultTextColorFromDrupalSettings = () => {
    const drupalDynamicFontColorsSettings = window.drupalSettings?.defil_core?.colors_font;

    if (!Array.isArray(drupalDynamicFontColorsSettings)) { return null; }

    let lightColor = (drupalDynamicFontColorsSettings.find(_color => _color.bright_default === "0"))?.code;
    let darkColor = (drupalDynamicFontColorsSettings.find(_color => _color.bright_default === "1"))?.code;

    if (!lightColor || !darkColor) { return null; }

    if (!appConfig.customizerPresets.texts.availableColors.find(_text => _text.code === darkColor)) {
      darkColor = (appConfig.customizerPresets.texts.availableColors.find(_text => _text.id === "Blanc"))?.code;
    }
    if (!appConfig.customizerPresets.texts.availableColors.find(_text => _text.code === lightColor)) {
      lightColor = (appConfig.customizerPresets.texts.availableColors.find(_text => _text.id === "Noir"))?.code;
    }

    const primaryColorId = customizer.getCustomizationColors()[DEFAULT_COLOR_ZONE_ID]?.colorId;
    const primaryColorZoneConfig = appConfig.customizerPresets.colors.availableZones
      .find(_zone => _zone.id === DEFAULT_COLOR_ZONE_ID);

    if (!primaryColorZoneConfig) { return null; }

    const primaryColorConfig = primaryColorZoneConfig.availableColors
      .find(_color => _color.id === primaryColorId);

    if (primaryColorConfig?.bright === "1") { return darkColor; }
    if (primaryColorConfig?.bright === "0") { return lightColor; }

    return null;
  };

  /**
   * Get the default text color for new texts
   *
   * @returns {string}
   */
  const getDefaultTextColor = () => {

    // Try to get the default text color from drupal settings
    let color = getDefaultTextColorFromDrupalSettings();

    // When the colorZoneNameToBeUsedAsTextColor feature is enabled, the text color
    // should be set depending on the defined color zone name
    if (appConfig.formVariants.texts.settings.colorZoneNameToBeUsedAsTextColor) {
      color = customizer.getColorCodeFromZoneName(appConfig.formVariants.texts.settings.colorZoneNameToBeUsedAsTextColor);
    }


    return color || appConfig.customizerPresets.texts.defaultTextConfig.fillColor;
  };

  /**
   * Add a new text
   *
   * @param {string} text
   * @param {string} locationId
   * @param {string} usage
   * @returns {Promise<void>}
   */
  const handleTextAdd = async (text, locationId, usage) => {
    if (!text || !text.length) { return; }

    let newLocationId = locationId;

    // location may depend on the defined usage
    const usageConfig = appConfig.formVariants.texts.settings.getUsageOptions(customizer.productType)
      .find(_usage => _usage.value === usage);

    if (usageConfig.targetPosition) {
      newLocationId = usageConfig.targetPosition;
    }

    // Check if images overlaid is allowed
    const imageCanBeOverlaid = await customizer.imageCanBeOverlaid(newLocationId);

    if (!imageCanBeOverlaid) { return; }

    try {
      const fillColor = getDefaultTextColor();
      const id = `text-${createObjectId()}`;
      const location = appConfig.viewer.predefinedPositions[newLocationId];
      const nextViewpoint = location?.viewpoint || appConfig.viewer.viewpoints.View_Back.id;
      const fillColorName = (appConfig.customizerPresets.texts.availableColors.find(_color => _color.code === fillColor))?.label;
      const strokeColorName = (appConfig.customizerPresets.texts.availableColors.find(_color => _color.code === appConfig.customizerPresets.texts.defaultTextConfig.strokeColor))?.label;
      const { pngImage, svgString } = await customizer.getBase64FromText(text, { ...appConfig.customizerPresets.texts.defaultTextConfig, fillColor });

      setFormValues({
        custom : {
          texts : {
            [id] : {
              image : pngImage,
              // It is mandatory to clear `imageUrl` so the new text image gets uploaded to server
              imageUrl : null,
              svgString,
              location : newLocationId,
              ...(appConfig.customizerPresets.texts.defaultTextConfig || {}),
              fillColor,
              text,
              usage,
              fillColorName,
              strokeColorName,
              pinned : !!appConfig.formVariants.logos.settings.viewerPinned,
              menuDisabled : !!appConfig.formVariants.logos.settings.viewerMenuDisabled
            }
          }
        }
      }, success => {
        if (success) {

          // Change the viewpoint
          setTimeout(() => {
            customizer.selectViewerItem(id);
            customizer.changeViewpoint(nextViewpoint);
          }, 100);
        }
      });
    } catch (err) {
      console.error(err);
    }
  };

  /**
   * Update the content of a text
   *
   * @param {string} textId
   * @returns {(function(*=): Promise<void>)|*}
   */
  const handleInputTextUpdate = textId => async inputText => {
    const textConfig = (texts.find(_text => _text?.[0] === textId))?.[1];

    if (!textConfig) {
      return;
    }

    try {
      const { pngImage, svgString } = await customizer.getBase64FromText(inputText, textConfig);

      setFormValues({
        custom : {
          texts : {
            [textId] : {
              image : pngImage,
              // It is mandatory to clear `imageUrl` so the new text image gets uploaded to server
              imageUrl : null,
              svgString,
              text : inputText,
              dpi : undefined,
              height : undefined,
              sizeInCm : undefined,
              width : undefined
            }
          }
        }
      });
    } catch (err) {
      console.error(err);
    }
  };

  /**
   *
   * @param {string} textId
   * @returns {(function(): void)|*}
   */
  const handleInputTextBeingEdited = textId => () => {
    const textConfig = (texts.find(_text => _text?.[0] === textId))?.[1];

    if (!textConfig) {
      return;
    }

    const location = appConfig.viewer.predefinedPositions[textConfig.location];
    const nextViewpoint = location?.viewpoint;

    // Change the viewpoint
    customizer.selectViewerItem(textId);
    if (nextViewpoint) {
      customizer.changeViewpoint(nextViewpoint);
    }
  };

  return (
    <div style={{ zIndex: 1 }} className={classes.container}>
      <Typography paragraph>
        {t("COMPONENTS.TEXTS_FORM.DESCRIPTION")}
      </Typography>
      {!!texts && texts.length > 0 && (
        <SlickSlider
          {...sliderSettings}
          ref={sliderRef}
          initialSlide={selectedTextIndex}
          afterChange={index => handleSlideChange(index)()}
        >
          {
            texts.map(([_textId, _text]) => (
              <div key={_textId}>
                <TextFormEditInput
                  value={_text.text}
                  fontFamily={_text.fontFamily}
                  text={_text}
                  onTextUpdate={handleInputTextUpdate(_textId)}
                  onTextBeingEdited={handleInputTextBeingEdited(_textId)}
                />
              </div>
            ))
          }
        </SlickSlider>
      )}
      {
        !Number.isNaN(Number(selectedTextIndex)) && !!texts[selectedTextIndex]
        && <TextsEditForm text={texts[selectedTextIndex][1]} id={texts[selectedTextIndex][0]} availableLocations={availableLocations} />
      }
      {
        texts.length < maxTexts
        && <TextsInputForm onTextAdd={handleTextAdd} small={!!texts.length} availableLocations={availableLocations} />
      }
    </div>
  );
}


export default wrapper({ styles }, TextsForm);
