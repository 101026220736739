import { Grid, Typography } from "@material-ui/core";
import React from "react";
import SwipeableBottomSheet from "react-swipeable-bottom-sheet";
import AppContext from "../../AppContext";
import useTranslate from "../../lib/useTranslate";
import wrapper from "../../utils/wrapper";
import CustomTextCallout from "../CustomTextCallout";
import ColorsForm from "./ColorsForm";
import LogosForm from "./LogosForm";
import PatternsForm from "./PatternsForm";
import TextsForm from "./TextsForm";


const formComponents = {
  colors : ColorsForm,
  texts : TextsForm,
  logos : LogosForm,
  patterns : PatternsForm
};

const styles = theme => ({
    container : {
      height : "100%"
    },

    title : {
      paddingBottom : theme.spacing(3),

      [theme.breakpoints.down("md")] : {
        paddingBottom : theme.spacing()
      }
    },

    mobilePaper : {
      position : "relative",
      padding : theme.spacing(1, 3, 3, 3),
      width : "100%",
      overflow : "auto",
      top : 36,
      flexWrap : "nowrap",
      height : "calc(50vh - 36px)",
      minHeight : "calc(400px - 36px)",

      "&:focus" : {
        outline : "none"
      },

      "&::-webkit-scrollbar" : {
        display : "none"
      },
      "-ms-overflow-style" : "none",
      "scrollbar-width" : "none"
    },

    bottomButtonContainer : {
      borderTop : `1px solid ${theme.palette.grey[50]}`,
      paddingTop : theme.spacing(5),

      [theme.breakpoints.up("md")] : {
        "@media (max-height:800px)" : {
          paddingTop : theme.spacing(2)
        }
      }
    },

    mobileSwipeableHook : {
      position : "absolute",
      top : 0,
      left : 0,
      right : 0,
      height : 36,
      width : "100%",

      "&::after" : {
        content : '""',
        position : "absolute",
        top : 0,
        left : 0,
        right : 0,
        bottom : 0,
        margin : "auto",
        width : 80,
        height : 4,
        backgroundColor : theme.palette.grey[50],
        borderRadius : 10
      }
    },

    button : {
      position : "relative",
      border : 0,
      cursor : "pointer",
      padding : 0,
      background : "transparent",
      paddingRight : theme.spacing(2),
      color : "#818181",
      fontSize : 13,
      ...theme.styles.hover.offLinkUnderline,

      "&:before" : {
        content : '"›"',
        position : "absolute",
        right : 0,
        top : 0,
        fontFamily : "DIN",
        color : "#818181",
        lineHeight : "10px",
        fontSize : 26,
        transition : `transform 0.6s ${theme.transitions.easing.easeOut}`
      },

      "&:hover" : {
        "&::after" : theme.styles.hover.offLinkUnderline["&:hover::after"],
        "&::before" : {
          transform : "translateX(5px)"
        }
      }
    },

    modalSubmitButton : {
      alignSelf : "center",
      marginTop : theme.spacing(2)
    },

    componentContainer : {
      width : "100%"
    },

    customTextCallout : {
      marginTop : theme.spacing(2)
    }
  });


function Forms({
 classes,
 variant,
 setFormVariant,
 isMobileFormOpen,
 isLoading,
 onMobileModalClose,
 selectedViewerItemId
}) {
  const { appConfig } = React.useContext(AppContext);
  const [isMdSize, setIsMdSize] = React.useState(window.innerWidth < 960);
  const { t } = useTranslate();

  const componentVariants = appConfig.formVariants;
  const { title } = componentVariants[variant];
  const Component = formComponents[variant];
  const variantsNames = Object.keys(componentVariants);
  const nextVariantName = variantsNames
    .find((_variant, index) => variantsNames[index - 1] === variant);
  const nextVariant = componentVariants[nextVariantName];

  React.useEffect(() => {
  }, [isMobileFormOpen]);

  React.useEffect(() => {
    function onResize() {
      if (window.innerWidth < 960 && !isMdSize) {
        setIsMdSize(true);
      } else if (window.innerWidth >= 960 && isMdSize) {
        setIsMdSize(false);
      }
    }


    window.addEventListener("resize", onResize);

    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, [isMdSize]);

  if (isLoading) { return null; }

  if (isMdSize) {
    return (
      <SwipeableBottomSheet
        open={isMobileFormOpen}
        onChange={onMobileModalClose}
        swipeableViewsProps={{
          disabled : false
        }}
        marginTop={100}
        style={{ zIndex: 1000 }}
        overlay={false}
        topShadow={false}
        shadowTip={false}
        bodyStyle={{
          overflow : "hidden",
          borderTopLeftRadius : 20,
          borderTopRightRadius : 20,
          display : "flex",
          height : "50vh",
          minHeight : 400,
          flexDirection : "column",
          boxShadow : "rgba(0, 0, 0, 0.127) 0px -6px 5px"
        }}
      >
        <button
          type="button"
          aria-label="Close modal"
          className={classes.mobileSwipeableHook}
          onClick={onMobileModalClose}
        />
        <Grid container direction="column" alignItems="flex-start" className={classes.mobilePaper}>
          <Typography variant="h2" className={classes.title}>
            {t(title)}
          </Typography>
          <div className={classes.componentContainer}>
            <Component selectedViewerItemId={selectedViewerItemId} />
          </div>
        </Grid>
      </SwipeableBottomSheet>
    );
  }

  return (
    <Grid container direction="column" justifyContent="flex-start" className={classes.container}>
      <Grid item style={{ width: "100%" }}>
        <Typography variant="h2" className={classes.title}>
          {t(title)}
        </Typography>
        <Component selectedViewerItemId={selectedViewerItemId} />
      </Grid>
      {
        !!nextVariant
        && (
        <Grid item className={classes.bottomButtonContainer}>
          <button
            type="button"
            className={classes.button}
            onClick={() => setFormVariant(nextVariantName)}
          >
            <Typography variant="h4" style={{ textTransform: "uppercase", letterSpacing: "1.3px" }}>
              {t(nextVariant.label)}
            </Typography>
          </button>
        </Grid>
)
      }
      {
        !!appConfig.customCallouts?.[variant]
        && <CustomTextCallout {...appConfig.customCallouts?.[variant]} className={classes.customTextCallout} />
      }
    </Grid>
  );
}


export default wrapper({ styles }, Forms);
