import { Grid, Tooltip, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import clx from "classnames";
import React from "react";
import AppContext from "../../../AppContext";
import useTranslate from "../../../lib/useTranslate";
import wrapper from "../../../utils/wrapper";
import ExpansionPanel from "../../ExpansionPanel";
import BrandLogoColorsForm from "./BrandLogoColorsForm";


const styles = theme => ({
  panelTitleContainer : {
    display : "flex",
    flexDirection : "row",
    flexWrap : "nowrap",
    alignItems : "center",
    justifyContent : "flex-start",
    zIndex : 1
  },

  panelTitleIcon : {
    position : "relative",
    width : theme.spacing(2),
    height : theme.spacing(2),
    display : " flex",
    flexShrink : 0,
    alignItems : "center",
    justifyContent : "center",
    borderRadius : 100,
    marginRight : theme.spacing(2),
    marginLeft : 3,

    "&::before" : {
      zIndex : -1,
      content : '"?"',
      alignItems : "center",
      justifyContent : "center",
      display : "flex",
      fontFamily : "DIN",
      position : "absolute",
      margin : "auto",
      top : -3,
      left : -3,
      width : 20,
      height : 20,
      borderRadius : 100,
      border : `1px solid ${theme.palette.text.grey}`,
      color : theme.palette.text.grey,
      boxSizing : "initial"
    }
  },

  panelTitleIcon__active : {
    "&::before" : {
      border : `1px solid ${theme.palette.text.primary}`,
      color : theme.palette.text.primary
    }
  },

  label__inactive : {
    color : theme.palette.text.grey
  },

  colorsContainer : {
    display : "flex",
    flexWrap : "wrap"
  },

  colorThumb : {
    position : "relative",
    height : 20,
    width : 20,
    borderRadius : 100,
    margin : theme.spacing(1.5, 3, 1.5, 0),
    cursor : "pointer",
    border : "1px solid transparent",

    "&:hover::before" : {
      opacity : 1,
      transition : `opacity .3s ${theme.transitions.easing.easeOut}`
    },

    "&::before" : {
      content : '""',
      position : "absolute",
      top : "-10%",
      left : "-10%",
      width : "120%",
      height : "120%",
      // backgroundColor: '#fff',
      zIndex : -1,
      borderRadius : 100,
      boxShadow : "0px 0px 4px 0px rgba(0,0,0,0.5)",
      opacity : 0,
      transition : `opacity .8s ${theme.transitions.easing.easeOut}`
    },

    [theme.breakpoints.down("md")] : {
      margin : theme.spacing(1, 2, 1, 0)
    },

    [theme.breakpoints.down("sm")] : {
      margin : theme.spacing(1, 2, 1, 0),
      width : theme.spacing(2),
      height : theme.spacing(2)
    }
  },

  colorThumb__selected : {
    "&::before" : {
      zIndex : 1,
      opacity : 1
    }
  },

  /** MOBILE * */

  mobileColorsTitlesContainer : {
    overflowY : "scroll",
    minHeight : 80,

    "&::-webkit-scrollbar" : {
      display : "none"
    },
    "-ms-overflow-style" : "none",
    "scrollbar-width" : "none"
  },

  mobileColorTitleContainer : {
    minWidth : 70,
    cursor : "pointer"
  },

  mobileColorTitleContainer__active : {
    position : "relative",

    "&::after" : {
      content : '""',
      position : "absolute",
      bottom : 0,
      left : 0,
      width : "100%",
      height : 1,
      backgroundColor : "#000"
    }
  },

  mobilePanelTitleIcon : {
    margin : theme.spacing(0, 0, 0.5, 0),

    "&::before" : {
      zIndex : 1,
      content : '""'
    }
  },

  mobileColorsContainer : {
    marginTop : theme.spacing(2)
  }
});


function ColorsForm({ classes }) {
  const { customizer, appConfig } = React.useContext(AppContext);
  const [expanded, setExpanded] = React.useState(0);
  const [availableZones, setAvailableZones] = React.useState([]);
  const { t } = useTranslate();
  const theme = useTheme();
  const isSmScreen = useMediaQuery(theme.breakpoints.down("sm"));

  // Update the available colors zones
  const updateZones = () => {
    if (customizer) {
      const availableColors = customizer.getAvailableColorZones();
      setAvailableZones(appConfig.formVariants.colors.settings.colorZones
        .map(_group => {
          const color = availableColors.find(_color => _color.name.split("_")[0] === _group.id);

          return color
            ? { ..._group, ...color }
            : null;
        })
        .filter(_group => !!_group));
    }
  };

  React.useEffect(updateZones, [appConfig, customizer]);

  const handleExpansion = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleSelect = (id, value) => {
    customizer.updateColorZone(id, value);
    updateZones();
  };

  if (isSmScreen) {
    const selectedGroup = availableZones[expanded];
    const selectedPresetZone = selectedGroup
      ? appConfig.customizerPresets.colors.availableZones
        .find(_zone => _zone.id === selectedGroup.id)
      : null;
    const zoneColors = selectedPresetZone?.availableColors;


    return (
      <div style={{ zIndex: 1 }}>
        <Grid container className={classes.mobileColorsTitlesContainer} wrap="nowrap">
          {
            availableZones.map((_group, index) => {
              const presetZone = appConfig.customizerPresets.colors.availableZones
                .find(_zone => _zone.id === _group.id);

              if (!presetZone || !presetZone.availableColors) { return null; }

              // Not optimal but this allows us to gain some precious space
              const title = t(_group.title)
                .toLowerCase()
                .replace(/color/, "")
                .replace(/colour/, "")
                .replace(/couleur de/, "")
                .replace(/couleur du/, "")
                .replace(/couleur/, "");

              return (
                <Grid
                  key={_group.title}
                  container
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                  className={clx(
                    classes.mobileColorTitleContainer,
                    { [classes.mobileColorTitleContainer__active]: index === expanded }
                  )}
                  onClick={() => handleExpansion(index)(null, true)}
                >
                  <div
                    className={clx(classes.panelTitleIcon, classes.mobilePanelTitleIcon,
                      (index === expanded || !!_group.value) && classes.panelTitleIcon__active)}
                    style={{ backgroundColor: _group.value }}
                  />
                  <Typography
                    variant="caption"
                    className={index !== expanded ? classes.label__inactive : undefined}
                    align="center"
                    style={{ height: 40 }}
                  >
                    {title}
                  </Typography>
                </Grid>
              );
            })
          }
        </Grid>
        <Grid container className={classes.mobileColorsContainer}>
          {
            !!selectedGroup && !!zoneColors && zoneColors.map(_color => (
              <Tooltip key={_color.code} title={_color.label}>
                <button
                  type="button"
                  aria-label="Select"
                  style={{
                      backgroundColor : _color.code,
                      ...(_color.code.toLowerCase() === "#ffffff" ? {
                        border : "1px solid #818181"
                      } : {})
                    }}
                  className={clx(classes.colorThumb,
                      (selectedGroup.value || "").toLowerCase() === _color.code.toLowerCase() && classes.colorThumb__selected)}
                  onClick={() => handleSelect(selectedGroup.id, _color.code)}
                />
              </Tooltip>
              ))
          }
        </Grid>
      </div>
    );
  }

  return (
    <div style={{ zIndex: 1 }}>
      {
        availableZones.map((_group, index) => {
          const presetZone = appConfig.customizerPresets.colors.availableZones
            .find(_zone => _zone.id === _group.id);

          if (!presetZone || !presetZone.availableColors) { return null; }

          const zoneColors = presetZone.availableColors;

          return (
            <ExpansionPanel
              key={_group.title}
              onChange={handleExpansion(index)}
              expanded={index === expanded}
              title={(
                <div className={classes.panelTitleContainer}>
                  <div
                    className={clx(classes.panelTitleIcon,
                      (index === expanded || !!_group.value) && classes.panelTitleIcon__active)}
                    style={{ backgroundColor: _group.value }}
                  />
                  <Typography variant="body2" className={index !== expanded ? classes.label__inactive : undefined}>
                    {t(_group.title)}
                  </Typography>
                </div>
              )}
            >
              <div className={classes.colorsContainer}>
                {
                  zoneColors.map(_color => (
                    <Tooltip key={_color.code} title={_color.label}>
                      <button
                        type="button"
                        aria-label="Select"
                        style={{
                            backgroundColor : _color.code,
                            ...(_color.code.toLowerCase() === "#ffffff" ? {
                              border : "1px solid #818181"
                            } : {})
                          }}
                        className={clx(classes.colorThumb,
                            (_group.value || "").toLowerCase() === _color.code.toLowerCase() && classes.colorThumb__selected)}
                        onClick={() => handleSelect(_group.id, _color.code)}
                      />
                    </Tooltip>
                    ))
                }
              </div>
            </ExpansionPanel>
          );
        })
      }
      { // The brand logos are handle like "colors" while it is only possible to change their colors and some predefined positions
        appConfig.customizerPresets.brandLogos.availableLogos
          .filter(_logo => !!_logo?.availableColors && (!_logo.productTypes || _logo.productTypes.includes(customizer.productType)))
          .map((_logo, i) => (
            <BrandLogoColorsForm
              key={_logo.id}
              expanded={expanded === availableZones.length + i}
              onExpansion={handleExpansion(availableZones.length)}
              formLabel={appConfig.formVariants.colors.settings.brandLogoColorZoneLabel}
              {..._logo}
            />
          ))
      }
    </div>
  );
}


export default wrapper({ styles }, ColorsForm);
