import { Typography } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import React from "react";
import AppContext from "../../../AppContext";
import FormContext from "../../../FormContext";
import useTranslate from "../../../lib/useTranslate";
import createObjectId from "../../../utils/createObjectId";
import wrapper from "../../../utils/wrapper";
import LogosResizeForm from "./LogosResizeForm";
import LogosUploadForm from "./LogosUploadForm";


const styles = theme => ({
  content : {
    marginTop : theme.spacing(2)
  }
});


function LogosForm({ classes, selectedViewerItemId }) {
  const { formValues, setFormValues } = React.useContext(FormContext);
  const { customizer, appConfig } = React.useContext(AppContext);
  const { t } = useTranslate();

  const maxLogos = typeof appConfig.formVariants.logos.settings.maxLogos === "object"
    ? appConfig.formVariants.logos.settings.maxLogos[customizer.productType]
    : appConfig.formVariants.logos.settings.maxLogos;
  const availableLocations = appConfig.customizerPresets.logos.availableLocations?.[customizer.productType]
    ?.map(_locationId => ({
      value : _locationId,
      label : appConfig.viewer.predefinedPositions[_locationId]?.label,
      viewpoint : appConfig.viewer.predefinedPositions[_locationId]?.viewpoint
    }));
  const brandLogosIds = React.useMemo(() => appConfig.customizerPresets.brandLogos.availableLogos.map(_logo => _logo.id), [appConfig]);


  /**
   * Get the base64 image and update the viewer
   *
   * @param {string} image
   * @param {string} locationId
   * @returns {Function}
   */
  const handleFileUpload = async (image, locationId = availableLocations[0]) => {
    const id = `logo-${createObjectId()}`;
    const location = appConfig.viewer.predefinedPositions[locationId];
    const nextViewpoint = location?.viewpoint || appConfig.viewer.viewpoints.View_Front.id;

    // Check if images overlaid is allowed
    const imageCanBeOverlaid = await customizer.imageCanBeOverlaid(locationId);

    if (!imageCanBeOverlaid) { return; }

    setFormValues({
      custom : {
        logos : {
          [id] : {
            image,
            location : locationId,
            pinned : !!appConfig.formVariants.logos.settings.viewerPinned,
            menuDisabled : !!appConfig.formVariants.logos.settings.viewerMenuDisabled
          }
        }
      }
    })
      // Revert changes if there was an error
      .then(res => {
        // Change the viewpoint
        customizer.changeViewpoint(nextViewpoint);
        customizer.selectViewerItem(id);

        // Revert if pose is not valid
        if (res && res.error && res.error === "POSE_NOT_VALID") {
          setFormValues({
            custom : {
              logos : {
                [id] : null
              }
            }
          });
        }
      });
  };

  /**
   * Delete a logo by ID
   *
   * @type {Function}
   */
  const handleLogoDelete = React.useCallback(imageId => {
    setFormValues({
      custom : {
        logos : {
          [imageId] : null
        }
      }
    });

  }, [formValues]);

  // Filter logos to exclude brand logos
  const logos = Object.keys(formValues.custom?.logos || {})
    .filter(key => !brandLogosIds.includes(key))
    .reduce((obj, key) => {
      obj[key] = formValues.custom.logos[key];
      return obj;
    }, {});
  const logosKeys = Object.keys(logos);
  const hasLogo = logosKeys.length > 0 && Object.values(logos).some(_image => !!_image);

  return (
    <div style={{ zIndex: 1 }}>
      <Typography paragraph>
        {t("COMPONENTS.LOGOS_FORM.DESCRIPTION")}
      </Typography>
      <Divider className={classes.dividerTop} />
      <div className={classes.content}>
        <Grid container direction="column">
          <LogosResizeForm
            logos={logos}
            onLogoDelete={handleLogoDelete}
            setFormValues={setFormValues}
            formValues={formValues}
            selectedViewerItemId={selectedViewerItemId}
            availableLocations={availableLocations}
          />
          {
            logosKeys.length < maxLogos
            && <LogosUploadForm onFileUpload={handleFileUpload} small={hasLogo} availableLocations={availableLocations} />
          }
        </Grid>
      </div>
    </div>
  );
}


export default wrapper({ styles }, LogosForm);
