import { Typography } from "@material-ui/core";
import React from "react";
import AppContext from "../AppContext";
import useTranslate from "../lib/useTranslate";


function CustomTextCallout({ message, restrictedCountries, ...rest }) {
  const { t } = useTranslate();
  const { appConfig } = React.useContext(AppContext);

  if (!message) { return null; }

  if (!!appConfig.api.pathPrefix && !restrictedCountries?.includes?.(appConfig.api.pathPrefix)) {
    return null;
  }

  return (
    <Typography {...rest}>
      {t(message)}
    </Typography>
  );
}


export default CustomTextCallout;
