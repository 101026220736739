import { Badge, Button, MenuItem } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Select from "@material-ui/core/Select";
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/Add";
import clx from "classnames";
import React from "react";
import AppContext from "../../../AppContext";
import useTranslate from "../../../lib/useTranslate";
import wrapper from "../../../utils/wrapper";
import ArrowRight from "../../svg/ArrowRight";


const styles = theme => ({
  root : {
    marginTop : theme.spacing(2)
  },

  container : {
    width : "100%",
    display : "flex",
    flexDirection : "column",
    marginTop : theme.spacing(2)
  },

  patternContainer : {
    width : "auto",
    marginRight : theme.spacing(2),
    marginBottom : theme.spacing()
  },

  previewSvg : {
    position : "relative",
    width : theme.spacing(5),
    height : theme.spacing(5),
    padding : theme.spacing(),
    border : "1px solid #000",
    borderRadius : "10px",
    cursor : "pointer",

    "& > svg" : {
      zIndex : 200,
      width : "100%",
      height : "100%"
    },

    "&::before" : {
      content : '""',
      position : "absolute",
      top : -2,
      left : -2,
      width : "100%",
      height : "100%",
      border : "2px solid #000",
      borderRadius : "10px",
      pointerEvents : "none",
      opacity : 0,
      boxSizing : "unset"
    },

    "&:hover" : {
      "&::before" : {
        opacity : 1
      }
    }
  },

  patternContainer__selected : {
    border : "2px solid #000"
  },

  groupsBackButton : {
    cursor : "pointer",
    fontWeight : 700,
    color : "#000",
    position : "relative",

    "&:before" : {
      content : '"‹"',
      position : "absolute",
      left : -12,
      top : 0,
      fontFamily : "DIN",
      color : "#000",
      lineHeight : "18px",
      fontSize : 22,
      transition : `transform 0.6s ${theme.transitions.easing.easeOut}`
    },

    "&:hover" : {
      "&::after" : theme.styles.hover.onLinkUnderline["&:hover::after"],
      "&::before" : {
        transform : "translateX(-2px)"
      }
    }
  },

  insertButton : {
    fontSize : "0.8rem",
    borderRadius : 10,
    marginLeft : theme.spacing(),
    height : 40
  },

  insertButton__small : {
    padding : theme.spacing(0, 4)
  },

  insertIcon : {
    width : 12,
    minWidth : 12
  },

  buttonLabel : {
    marginLeft : theme.spacing(),
    fontSize : "0.6rem"
  },

  locationSelectContainer : {
    marginTop : theme.spacing(2)
  },

  locationSelectRoot : {
    backgroundColor : "rgba(255, 0, 0, 0.25)",
    borderRadius : 6,
    padding : theme.spacing(),
    flewGrow : 1
  },

  locationSelect : {
    display : "flex",
    alignItems : "center",
    flexWrap : "nowrap"
  },

  locationSelectBase : {
    flexGrow : 1
  },

  locationColorPreview : {
    width : theme.spacing(2),
    height : theme.spacing(2),
    marginRight : theme.spacing(),
    border : "1px solid #000"
  },

  addMoreButton : {
    fontSize : "0.8rem",
    borderRadius : 10,
    marginTop : theme.spacing(2)
  }
});


function PatternsInputForm({ classes, onPatternAdd, small, availableLocations }) {
  const [location, setLocation] = React.useState(availableLocations?.[0]?.id);
  const [groupId, setGroupId] = React.useState(undefined);
  const [patternId, setPatternId] = React.useState(undefined);
  const { appConfig } = React.useContext(AppContext);
  const [showForm, setShowForm] = React.useState(!small);
  const { t } = useTranslate();

  const handlePatternAdd = () => {
    onPatternAdd(location, {
      id : patternId
    });
    setShowForm(false);
    setGroupId(undefined);
    setPatternId(undefined);
  };

  React.useEffect(() => {
    setShowForm(!small);
  }, [small]);

  // When the selected location is no more available, we should take care of updating the select field
  // with an existing value
  React.useEffect(() => {
    if (!availableLocations.some(_location => _location.id === location)) {
      setLocation(availableLocations[0]?.id);
    }

  }, [availableLocations]);

  // When there is no more available location the component shouldn't display anything
  if (!availableLocations || availableLocations.length === 0) {
    return null;
  }

  return (
    <div className={classes.root}>
      {
        !showForm
          ? (
            <Button variant="contained" color="primary" className={classes.addMoreButton} onClick={() => setShowForm(true)}>
              {t("COMPONENTS.PATTERNS_FORM.ADD_ANOTHER_PATTERN")}
            </Button>
          ) : (
            <div className={classes.container}>
              {
                !groupId
                && (
                <Grid container direction="column">
                  <Typography variant="caption" paragraph>
                    {t("COMPONENTS.PATTERNS_FORM.ADD_A_PATTERN")}
                  </Typography>
                  <Grid container direction="row">
                    {
                      appConfig.customizerPresets.patterns.groups.map(_group => {
                        const previewPatterns = appConfig.customizerPresets.patterns.availablePatterns
                          .filter(_pattern => _pattern.groupId === _group.id);

                        return (
                          <Grid
                            item
                            container
                            key={_group.id}
                            direction="column"
                            alignItems="center"
                            onClick={() => setGroupId(
                            _group.id
)}
                            className={classes.patternContainer}
                          >
                            <Badge badgeContent={previewPatterns.length} color="secondary">
                              <div dangerouslySetInnerHTML={{ __html: previewPatterns?.[0]?.svgStringFile }} className={classes.previewSvg} />
                            </Badge>
                            <Typography variant="caption">
                              {t(_group.label)}
                            </Typography>
                          </Grid>
                        );
                      })
                    }
                  </Grid>
                </Grid>
)
              }
              {
                !!groupId
                && (
                <Grid container direction="column">
                  <Typography paragraph onClick={() => setGroupId(undefined) || setPatternId(undefined)} className={classes.groupsBackButton}>
                    {t("COMPONENTS.PATTERNS_FORM.CATEGORIES")}
                  </Typography>
                  <Grid container direction="row">
                    {
                      appConfig.customizerPresets.patterns.availablePatterns
                        .filter(_pattern => _pattern.groupId === groupId)
                        .map(_pattern => (
                          <Grid
                            item
                            container
                            key={_pattern.id}
                            direction="column"
                            alignItems="center"
                            className={classes.patternContainer}
                            onClick={() => setPatternId(_pattern.id)}
                          >
                            <div
                              dangerouslySetInnerHTML={{ __html: _pattern.svgStringFile }}
                              className={clx(
                                classes.previewSvg,
                                { [classes.patternContainer__selected]: patternId === _pattern.id }
                              )}
                            />
                            <Typography variant="caption">
                              {t(_pattern.label)}
                            </Typography>
                          </Grid>
                        ))
                    }
                  </Grid>
                  {
                    !!patternId
                    && (
                    <Grid container direction="column" className={classes.locationSelectContainer}>
                      <Typography variant="h3" gutterBottom>
                        {t("COMPONENTS.PATTERNS_FORM.ZONE")}
                      </Typography>
                      <Grid container direction="row" wrap="nowrap" alignItems="center">
                        <Select
                          value={location}
                          onChange={e => setLocation(e.target.value)}
                          className={classes.locationSelectBase}
                          classes={{
                            select : classes.locationSelect,
                            root : classes.locationSelectRoot
                          }}
                        >
                          {
                            availableLocations.map(_location => (
                              <MenuItem key={_location.id} value={_location.id}>
                                <div className={classes.locationColorPreview} style={{ backgroundColor: _location.value }} />
                                <Typography>
                                  {t(_location.label)}
                                </Typography>
                              </MenuItem>
                            ))
                          }
                        </Select>
                        <Button
                          onClick={handlePatternAdd}
                          variant={small ? "text" : "contained"}
                          color="primary"
                          disableElevation
                          size="large"
                          className={clx(classes.insertButton, { [classes.insertButton__small]: small })}
                        >
                          {
                            small
                              ? <AddIcon className={classes.insertIcon} fontSize="large" />
                              : <ArrowRight className={classes.insertIcon} />
                          }
                          <span className={classes.buttonLabel}>{small ? t("ACTIONS.ADD") : t("ACTIONS.INSERT")}</span>
                        </Button>
                      </Grid>
                    </Grid>
)
                  }
                </Grid>
)
              }
            </div>
          )
      }
    </div>
  );
}


export default wrapper({ styles }, PatternsInputForm);
