import { Grid, IconButton } from "@material-ui/core";
import PlusIcon from "@material-ui/icons/Add";
import MinusIcon from "@material-ui/icons/Remove";
import React from "react";
import wrapper from "../utils/wrapper";


const LessMoreButton = React.memo(({ className, value, min, max, onChange, step }) => {
    const [canMore, setCanMore] = React.useState(true);
    const isObjectValue = typeof value === "object" && !!value.width;
    const realValue = isObjectValue ? value.width : value;

    React.useEffect(() => {
      setCanMore(true);
    }, [value]);

    const onPlusChange = () => {
      const nextValue = realValue + step > max ? max : realValue + step;

      onChange(isObjectValue ? { width: nextValue } : nextValue, success => {
        if (!success) {
          setCanMore(false);
        }
      });
    };

    const onMinusChange = () => {
      const nextValue = realValue - step < min ? min : realValue - step;

      onChange(isObjectValue ? { width: nextValue } : nextValue);
    };

    return (
      <Grid container spacing={2} className={className}>
        <Grid item>
          <IconButton
            aria-label="Moins"
            size="small"
            onClick={onMinusChange}
            disabled={realValue <= min || (isObjectValue && value.height <= min)}
          >
            <MinusIcon />
          </IconButton>
        </Grid>
        <Grid item>
          <IconButton
            aria-label="Plus"
            size="small"
            onClick={onPlusChange}
            disabled={realValue + step > max || !canMore}
          >
            <PlusIcon />
          </IconButton>
        </Grid>
      </Grid>
    );
  },
  (prevProps, nextProps) => prevProps.value === nextProps.value);

export default wrapper({}, LessMoreButton);
