import queryString from "query-string";
import urlJoin from "url-join";


const DEV_API_BASE_URL = "http://prod-pumateamwear-global.vuxe.fr";
const VIEWER_VERSION = "v2.0.3";

export default {
  siteBaseURL : process.env.NODE_ENV === "development" ? DEV_API_BASE_URL : window.location.origin, // The website base URL (mainly for assets)
  viewer : {
    apvizScriptLink : "https://static.apviz.io/loader/v1.0.0-beta.2/main.js?callback=initApviz",
    viewerVersion : VIEWER_VERSION,
    get integrationLink() {
      /*
       // Use the development branch of the viewer on a dev env
       if (process.env.NODE_ENV === 'development') {
       return ' https://3d.dev.apviz.io/defil/sportswear/develop/options.js';
       } */
      const parsedQuery = queryString.parse(window.location.search);

      return `https://3d.apviz.io/defil/sportswear/${parsedQuery.viewerVersion || this.viewerVersion}/options.js`;
    },
    captureSnapshotWidth : 800,
    predefinedPositions : {
      "arm-left" : { label: "PREDEFINED_POSITIONS.LEFT_SLEEVE", viewpoint: "View_Left" },
      "arm-right" : { label: "PREDEFINED_POSITIONS.RIGHT_SLEEVE", viewpoint: "View_Right" },
      "collar-back" : { label: "PREDEFINED_POSITIONS.COLLAR", viewpoint: "View_Back" },
      "back-bottom" : { label: "PREDEFINED_POSITIONS.LOWER_BACK", viewpoint: "View_Back" },
      "back-full" : { label: "PREDEFINED_POSITIONS.MID_BACK", viewpoint: "View_Back" },
      "back-top" : { label: "PREDEFINED_POSITIONS.TOP_OF_THE_BACK", viewpoint: "View_Back" },
      "back-neck" : { label: "PREDEFINED_POSITIONS.NECK", viewpoint: "View_Back" },
      "front-hip-right" : { label: "PREDEFINED_POSITIONS.RIGHT_HIP", viewpoint: "View_Right" },
      "front-full" : { label: "PREDEFINED_POSITIONS.CHEST", viewpoint: "View_Front" },
      "front-chest-right" : { label: "PREDEFINED_POSITIONS.OPPOSED_HEART", viewpoint: "View_Front" },
      "front-chest-left" : { label: "PREDEFINED_POSITIONS.HEART_SIDE", viewpoint: "View_Front" },
      "front-chest-center" : { label: "PREDEFINED_POSITIONS.HIGH_CENTER", viewpoint: "View_Front" },
      "front-clavicle-right" : { label: "PREDEFINED_POSITIONS.OPPOSED_HIGH_HEART", viewpoint: "View_Front" },
      "front-clavicle-left" : { label: "PREDEFINED_POSITIONS.HEART_HIGH", viewpoint: "View_Front" },
      "back-leg-left" : { label: "PREDEFINED_POSITIONS.REAR_LEFT_LEG", viewpoint: "View_Back" },
      "front-top-leg-left" : { label: "PREDEFINED_POSITIONS.TOP_LEFT_LEG", viewpoint: "View_Front" },
      "front-leg-left" : { label: "PREDEFINED_POSITIONS.LEFT_LEG", viewpoint: "View_Front" },
      "back-leg-right" : { label: "PREDEFINED_POSITIONS.REAR_RIGHT_LEG", viewpoint: "View_Back" },
      "front-top-leg-right" : { label: "PREDEFINED_POSITIONS.UPPER_RIGHT_LEG", viewpoint: "View_Front" },
      "front-leg-right" : { label: "PREDEFINED_POSITIONS.RIGHT_LEG", viewpoint: "View_Front" },
      "buttock-right" : { label: "PREDEFINED_POSITIONS.BUTTOCKS", viewpoint: "View_Back" },
      "buttock-left" : { label: "PREDEFINED_POSITIONS.BUTTOCKS", viewpoint: "View_Back" }
    },
    viewpoints : {
      View_Front : { id: "View_Front" },
      View_Back : { id: "View_Back" },
      View_Left : { id: "View_Left" },
      View_Right : { id: "View_Right" },
      View_CollarFront : { id: "View_CollarFront" },
      View_CollarBack : { id: "View_CollarBack" },
      View_SleeveLeft : { id: "View_SleeveLeft" },
      View_SleeveRight : { id: "View_SleeveRight" },
      View_Hips : { id: "View_Hips" }
    },
    cursorAreas : {
      "front-right-leg-cursor-area" : { viewpoint: "View_Front" },
      "right-leg-cursor-area" : { viewpoint: "View_Left" },
      "back-right-leg-cursor-area" : { viewpoint: "View_Back" },
      "front-left-leg-cursor-area" : { viewpoint: "View_Front" },
      "left-leg-cursor-area" : { viewpoint: "View_Right" },
      "back-left-leg-cursor-area" : { viewpoint: "View_Back" }
    },
    safeZones : {
      "front-safe-zone" : { viewpoint: "View_Front" },
      "back-safe-zone" : { viewpoint: "View_Back" },
      "right-sleeve-safe-zone" : { viewpoint: "View_Right" },
      "left-sleeve-safe-zone" : { viewpoint: "View_Left" }
    }
  },
  api : {
    pathPrefix : (window.drupalSettings?.path?.pathPrefix || "at-de").replace(/\//g, ""),
    get baseURL() {
      return process.env.NODE_ENV === "production"
        ? urlJoin("/", this.pathPrefix, "/api")
        : urlJoin(DEV_API_BASE_URL, this.pathPrefix, "/api");
    },
    svgBaseURL : process.env.NODE_ENV === "production" ? "/sites/default/files/configurateur" : `${DEV_API_BASE_URL}/sites/default/files/configurateur`,
    endpoints : {
      getProductInfos : "/configurator",
      getVariantInfos : "/product",
      saveProject : "/project/save",
      uploadFile : "/file",
      getUserProjects : "/my-projects",
      getCustomizationPrice : "/price"
    }
  },
  replaceOverlapping : true,
  dynamicPrice : true,
  enableDrupalI18n : true,
  defaultFormVariant : "colors",
  loaderCustomMessage : "SENTENCES.LOADER",
  customCallouts : {
    logos : {
      message : "Bitte beachte, dass die Veredelungsgrößen sich an den offiziellen Spielkleidungsrichtlinien der Verbände orientieren",
      restrictedCountries : ["at-de", "de"]
    },
    texts : {
      message : "Bitte beachte, dass die Veredelungsgrößen sich an den offiziellen Spielkleidungsrichtlinien der Verbände orientieren",
      restrictedCountries : ["at-de", "de"]
    }
  },
  formVariants : {
    colors : {
      title : "COMPONENTS.COLORS_FORM.TITLE",
      label : "COMPONENTS.COLORS_FORM.LABEL",
      settings : {
        colorZones : [
          {
            id : "color-zone0",
            title : "COLOR_ZONES.ZONE_0"
          },
          {
            id : "color-zone1",
            title : "COLOR_ZONES.ZONE_1"
          },
          {
            id : "color-zone2",
            title : "COLOR_ZONES.ZONE_2"
          },
          {
            id : "color-zone3",
            title : "COLOR_ZONES.ZONE_3"
          },
          {
            id : "color-zone4",
            title : "COLOR_ZONES.ZONE_4"
          },
          {
            id : "color-zone5",
            title : "COLOR_ZONES.ZONE_5"
          },
          {
            id : "color-zone6",
            title : "COLOR_ZONES.ZONE_6"
          },
          {
            id : "color-zone7",
            title : "COLOR_ZONES.ZONE_7"
          },
          {
            id : "color-zone8",
            title : "COLOR_ZONES.ZONE_8"
          },
          {
            id : "color-zone9",
            title : "COLOR_ZONES.ZONE_9"
          },
          {
            id : "color-zone20-collar",
            title : "COLOR_ZONES.ZONE_20_COLLAR"
          },
          {
            id : "color-zone30-sleeves",
            title : "COLOR_ZONES.ZONE_30_SLEEVES"
          },
          {
            id : "color-zone31-right-sleeve",
            title : "COLOR_ZONES.ZONE_31_RIGHT_SLEEVE"
          },
          {
            id : "color-zone32-left-sleeve",
            title : "COLOR_ZONES.ZONE_31_LEFT_SLEEVE"
          },
          {
            id : "color-zone40-logo",
            title : "COLOR_ZONES.ZONE_40_LOGO"
          },
          {
            id : "color-zone40-logo-cat-sleeves",
            title : "COLOR_ZONES.ZONE_40_LOGO_CAT_SLEEVES"
          },
          {
            id : "color-zone41-logo-cat-right-sleeve",
            title : "COLOR_ZONES.ZONE_41_LOGO_CAT_RIGHT_SLEEVE"
          },
          {
            id : "color-zone42-logo-cat-left-sleeve",
            title : "COLOR_ZONES.ZONE_42_LOGO_CAT_LEFT_SLEEVE"
          },
          {
            id : "color-zone43-logo-cat-leg",
            title : "COLOR_ZONES.ZONE_43_LOGO_CAT_LEG"
          },
          {
            id : "color-zone50-bias",
            title : "COLOR_ZONES.ZONE_50_BIAS"
          },
          {
            id : "color-zone60-zip",
            title : "COLOR_ZONES.ZONE_60_ZIP"
          }
        ]
      }
    },
    logos : {
      title : "COMPONENTS.LOGOS_FORM.TITLE",
      label : "COMPONENTS.LOGOS_FORM.LABEL",
      settings : {
        viewerPinned : true,
        viewerMenuDisabled : true,
        minSizeInCm : 2,
        maxLogos : {
          default : 3,
          short : 1
        },
        maxFileSize : 2000000,
        minDPI : 100,
        allowedFileTypes : [
          "image/jpeg",
          "image/png",
          "image/svg+xml"
        ],
        transformationInputs : [
          { id: "sizeInCm", title: "WORDS.SCALE", useButtons: false, defaultValue: 1, min: 2, max: 40, step: 2 }
        ]
      }
    },
    texts : {
      title : "COMPONENTS.TEXTS_FORM.TITLE",
      label : "COMPONENTS.TEXTS_FORM.LABEL",
      settings : {
        viewerPinned : true,
        viewerMenuDisabled : true,
        minSizeInCm : 0.5,
        maxTexts : {
          default : 3,
          short : 1
        },
        maxLength : 70,
        hiddenInputs : ["rotation", "opacity", "stroke", "position"],
        // colorZoneNameToBeUsedAsTextColor: 'color-zone1',
        onePerUsage : true,
        getUsageOptions : productType => (productType === "short"
            ? [
              {
                value : "number",
                label : "COMPONENTS.TEXTS_FORM.USAGES.NUMBER",
                targetPosition : "front-leg-right",
                maxLength : 2
              }
            ] : [
              {
                value : "number",
                label : "COMPONENTS.TEXTS_FORM.USAGES.NUMBER",
                targetPosition : "back-full",
                maxLength : 2
              },
              {
                value : "name",
                label : "COMPONENTS.TEXTS_FORM.USAGES.NAME",
                targetPosition : "back-bottom",
                maxLength : 25
              },
              {
                value : "club",
                label : "COMPONENTS.TEXTS_FORM.USAGES.CLUB",
                targetPosition : "back-top",
                maxLength : 25
              }
            ])
      }
    }
  },
  viewerErrorsMap : {
    POSE_NOT_VALID : "ERRORS_POSE_NOT_VALID"
  }
};
