import { Tooltip, Typography } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import clx from "classnames";
import React from "react";
import AppContext from "../../../AppContext";
import FormContext from "../../../FormContext";
import useTranslate from "../../../lib/useTranslate";
import wrapper from "../../../utils/wrapper";
import ExpansionPanel from "../../ExpansionPanel";


const styles = theme => ({
  panelTitleContainer : {
    display : "flex",
    flexDirection : "row",
    flexWrap : "nowrap",
    alignItems : "center",
    justifyContent : "flex-start",
    zIndex : 1
  },

  panelTitleIcon : {
    position : "relative",
    width : theme.spacing(2),
    height : theme.spacing(2),
    display : " flex",
    flexShrink : 0,
    alignItems : "center",
    justifyContent : "center",
    borderRadius : 100,
    marginRight : theme.spacing(2),
    marginLeft : 3,

    "&::before" : {
      zIndex : -1,
      content : '"?"',
      alignItems : "center",
      justifyContent : "center",
      display : "flex",
      fontFamily : "DIN",
      position : "absolute",
      margin : "auto",
      top : -3,
      left : -3,
      width : 20,
      height : 20,
      borderRadius : 100,
      border : `1px solid ${theme.palette.text.grey}`,
      color : theme.palette.text.grey,
      boxSizing : "initial"
    }
  },

  panelTitleIcon__active : {
    "&::before" : {
      border : `1px solid ${theme.palette.text.primary}`,
      color : theme.palette.text.primary
    }
  },

  label__inactive : {
    color : theme.palette.text.grey
  },

  colorsContainer : {
    display : "flex",
    flexWrap : "wrap",
    marginBottom : theme.spacing(2)
  },

  colorThumb : {
    position : "relative",
    height : 20,
    width : 20,
    borderRadius : 100,
    border : "none",
    margin : theme.spacing(1.5, 3, 1.5, 0),
    cursor : "pointer",

    "&:hover::before" : {
      opacity : 1,
      transition : `opacity .3s ${theme.transitions.easing.easeOut}`
    },

    "&::before" : {
      content : '""',
      position : "absolute",
      top : "-10%",
      left : "-10%",
      width : "120%",
      height : "120%",
      backgroundColor : "#fff",
      zIndex : -1,
      borderRadius : 100,
      boxShadow : "0px 0px 4px 0px rgba(0,0,0,0.5)",
      opacity : 0,
      transition : `opacity .8s ${theme.transitions.easing.easeOut}`
    },

    [theme.breakpoints.down("md")] : {
      margin : theme.spacing(1, 2, 1, 0)
    },

    [theme.breakpoints.down("sm")] : {
      margin : theme.spacing(1, 2, 1, 0),
      width : theme.spacing(2),
      height : theme.spacing(2)
    }
  },

  colorThumb__selected : {
    "&::before" : {
      opacity : 1
    }
  }
});


/**
 * This component handle the brand logos colors and positions
 *
 * @param {boolean} expanded
 * @param {Function} onExpansion
 * @param {Array} availableColors
 * @param {Array} availablePositions
 * @param {string} id
 * @param {string} formLabel
 * @param {object} classes
 * @param {boolean} classes.expanded
 * @param {Function} classes.onExpansion
 * @param {Array} classes.availableColors
 * @param {Array} classes.availablePositions
 * @param {string} classes.id
 * @param {string} classes.formLabel
 * @returns {null|*}
 * @function Object() { [native code] }
 */
function BrandLogoColorsForm({
 classes,
 expanded,
 onExpansion,
 availableColors = [],
 availablePositions = [],
 id,
 formLabel
}) {
  const { formValues } = React.useContext(FormContext);
  const { customizer, appConfig } = React.useContext(AppContext);
  const { t } = useTranslate();

  const color = formValues?.custom?.logos?.[id]?.color;
  const location = formValues?.custom?.logos?.[id]?.location;
  // eslint-disable-next-line no-nested-ternary
  const positions = Array.isArray(availablePositions)
    ? availablePositions
    : Array.isArray(availablePositions?.[customizer.productType])
      ? availablePositions[customizer.productType]
      : [];

  const handleColorChange = c => {
    customizer.updateBrandLogo(id, c);
  };

  const handlePositionChange = event => {
    customizer.updateBrandLogo(id, undefined, event.target.value);
  };

  if (!availableColors?.length) {
    return null;
  }

  return (
    <ExpansionPanel
      onChange={onExpansion}
      expanded={expanded}
      title={(
        <div className={classes.panelTitleContainer}>
          <div
            className={clx(classes.panelTitleIcon,
              (expanded || !!color) && classes.panelTitleIcon__active)}
            style={{ backgroundColor: color }}
          />
          <Typography variant="body2" className={!expanded ? classes.label__inactive : undefined}>
            {t(formLabel || "COMPONENTS.FORMS.BRAND_LOGO_COLOR")}
          </Typography>
        </div>
      )}
    >
      <div className={classes.content}>
        <div className={classes.colorsContainer}>
          {
            availableColors.map(_color => (
              <Tooltip key={_color.code} title={_color.label}>
                <button
                  type="button"
                  aria-label="Select"
                  style={{
                    backgroundColor : _color.code,
                    ...(_color.code.toLowerCase() === "#ffffff" ? {
                      border : "1px solid #818181",
                      width : 19,
                      height : 19
                    } : {})
                  }}
                  className={clx(classes.colorThumb,
                    (color || "").toLowerCase() === _color.code.toLowerCase() && classes.colorThumb__selected)}
                  onClick={() => handleColorChange(_color.code)}
                />
              </Tooltip>
            ))
          }
        </div>
        {
          !!positions && positions.length > 1
          && (
            <FormControl component="fieldset">
              <FormLabel component="legend">{t("WORDS.POSITION")}</FormLabel>
              <RadioGroup
                row
                aria-label={t("WORDS.POSITION")}
                name={t("WORDS.POSITION")}
                defaultValue={positions[0]?.id}
                value={location || positions[0]?.id}
                onChange={handlePositionChange}
              >
                {
                  positions.map(_position => (
                    <FormControlLabel
                      key={_position.id}
                      value={_position.id}
                      control={<Radio color="primary" size="small" />}
                      label={t(appConfig.viewer.predefinedPositions[_position.id]?.label)}
                      labelPlacement="start"
                    />
                  ))
                }
              </RadioGroup>
            </FormControl>
          )
        }
      </div>
    </ExpansionPanel>
  );
}


export default wrapper({ styles }, BrandLogoColorsForm);
