import { Grid } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import FavoriteIcon from "@material-ui/icons/FavoriteBorder";
import React from "react";
import AppContext from "../AppContext";
import FormContext from "../FormContext";
import useTranslate from "../lib/useTranslate";
import wrapper from "../utils/wrapper";
import Button from "./Button";
import ProductInfo from "./ProductInfo";
import SavingConfirmModal from "./SavingConfirmModal";
import Select from "./Select";
import ShareButton from "./ShareButton";
import Info from "./svg/Info";


const styles = theme => ({
  container : {
    height : "100%"
  },

  saveButton : {
    marginBottom : theme.spacing(1.5)
  },

  buttonsContainer : {
    marginTop : theme.spacing(6),

    [theme.breakpoints.down("md")] : {
      marginTop : theme.spacing(4)
    }
  }
});


const ControlPanel = React.memo(({ classes, productInfos = {}, setIsDrawerOpen }) => {
  const { customizer } = React.useContext(AppContext);
  const [isSavingConfirmModalOpen, setIsSavingConfirmModalOpen] = React.useState(false);
  const { t } = useTranslate();
  const { formValues, setFormValues } = React.useContext(FormContext);

  return React.useMemo(() => {
    const onSavingConfirmModalClose = () => setIsSavingConfirmModalOpen(false);

    // Save the project
    const handleSave = () => {
      customizer.saveCustomization()
        .then(res => !!res && setIsSavingConfirmModalOpen(true));
    };

    return (
      <Grid container direction="column" justifyContent="space-between" className={classes.container}>
        <Grid item container direction="column" wrap="nowrap">
          <ProductInfo openDrawer={() => setIsDrawerOpen(true)} productInfos={productInfos} />
          <Grid item container direction="column" wrap="nowrap" className={classes.buttonsContainer}>
            {!!customizer.userIsLogged && (
              <Button
                className={classes.saveButton}
                endIcon={<FavoriteIcon fontSize="small" />}
                onClick={handleSave}
              >
                {t("ACTIONS.SAVE")}
              </Button>
            )}
            <Button
              onClick={() => customizer.saveCustomization(true)}
              color="secondary"
            >
              {t("ACTIONS.CREATE_TEAM_LINEUP")}
            </Button>
          </Grid>
        </Grid>
        {
          !!customizer.availableFabrics && customizer.availableFabrics.length > 0
          && (
          <Grid item>
            <Select
              label={t("WORDS.FABRIC")}
              noEmptyItem
              options={customizer.availableFabrics}
              value={formValues.fabric1}
              onChange={e => setFormValues({ fabric1: e.target.value })}
            />
          </Grid>
)
        }
        <Grid item>
          {
            !!customizer.userIsLogged
            && <ShareButton />
          }
          <IconButton onClick={() => setIsDrawerOpen(true)}>
            <Info />
          </IconButton>
        </Grid>
        <SavingConfirmModal isOpen={isSavingConfirmModalOpen} onClose={onSavingConfirmModalClose} />
      </Grid>
    );

  }, [customizer, isSavingConfirmModalOpen, productInfos, setIsDrawerOpen, classes, formValues]);
}, ((prevProps, nextProps) => prevProps.productInfos === nextProps.productInfos));

export default wrapper({ styles }, ControlPanel);
