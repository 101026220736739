import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import React from "react";
import SlickSlider from "react-slick";
import AppContext from "../../../AppContext";
import FormContext from "../../../FormContext";
import useTranslate from "../../../lib/useTranslate";
import wrapper from "../../../utils/wrapper";
import PatternsEditForm from "./PatternsEditForm";
import PatternsInputForm from "./PatternsInputForm";


const styles = theme => ({
  container : {
    marginBottom : theme.spacing(2),

    "& .slick-slider" : {
      display : "flex",
      alignItems : "center",
      justifyContent : "center"
    },
    "& .slick-arrow" : {
      display : "flex",
      alignItems : "center",
      justifyContent : "center",
      transform : "scale(2)",
      position : "relative",
      top : 0,

      "&.slick-prev" : {
        left : -10
      },

      "&.slick-next" : {
        right : -10
      },

      "&:before" : {
        color : theme.palette.text.grey,
        content : '"‹"'
      },

      "&.slick-next:before" : {
        content : '"›"'
      }
    }
  },

  panelTitleContainer : {
    display : "flex",
    flexDirection : "row",
    flexWrap : "nowrap",
    alignItems : "center",
    justifyContent : "space-between",
    zIndex : 1,
    width : "100%"
  },

  label__inactive : {
    color : theme.palette.text.grey
  },

  topDivider : {
    marginBottom : theme.spacing()
  },

  deleteButton : {
    width : 16
  },

  patternContainer : {
    width : "100%",
    height : "auto"
  },

  previewSvg : {
    position : "relative",
    width : theme.spacing(5),
    height : theme.spacing(5),
    padding : theme.spacing(),
    border : "1px solid #000",
    borderRadius : "10px",

    "& > svg" : {
      zIndex : 200,
      width : "100%",
      height : "100%"
    }
  },

  patternPreviewLabel : {
    marginTop : theme.spacing(0.5),
    whiteSpace : "nowrap"

  }
});

const sliderSettings = {
  // accessibility: false,
  draggable : false,
  infinite : false,
  slidesToShow : 1,
  dots : true
};


function PatternsForm({ classes }) {
  const [selectedPatternIndex, setSelectedPatternIndex] = React.useState(0);
  const { customizer, appConfig } = React.useContext(AppContext);
  const [patterns, setPatterns] = React.useState([]);
  const sliderRef = React.useRef();
  const { formValues, setFormValues } = React.useContext(FormContext);
  const { t } = useTranslate();

  // Retrieve the available patterns locations from the colors zones
  const availableLocations = React.useMemo(() => {
    const availableColors = customizer.getAvailableColorZones(true);

    return (appConfig.formVariants.colors.settings.colorZones
        .map(_group => {
          const color = availableColors.find(_color => _color.name.split("_")[0] === _group.id);
          const parentColor = availableColors.find(_color => _color.name === _group.id);

          // Do not include items that use a mask
          if (parentColor?.item?.hasMask === true) {
            return null;
          }

          const label = !!color && (appConfig.formVariants.colors.settings.colorZones
            .find(_cZone => _cZone.id === color.name.split("_")[0]))
            ?.title;

          return color
            ? {
              ...color,
              id : color.name.split("_")[0],
              label
            }
            : null;
        })
        .filter(_group => !!_group)
        .filter(_group => !patterns.some(([_zoneId]) => _zoneId === _group.id))
    );

  }, [patterns]);

  // Update the patterns list when formValues changes
  React.useEffect(() => {
    setPatterns(Object.entries(formValues.custom?.patterns || {})
      .map(([_patternId, _pattern]) => ([_patternId, _pattern]))
      .filter(([, _pattern]) => !!_pattern));
  }, [formValues]);

  const handleSlideChange = patternIndex => () => {
    if (patternIndex !== selectedPatternIndex) {
      setSelectedPatternIndex(patternIndex);
    }
  };

  const handlePatternAdd = async (colorZone, pattern) => {
    await customizer.setColorZonePattern(colorZone, pattern);

    const nextSelectedIndex = patterns.length;

    setPatterns([
      ...patterns,
      [colorZone, pattern]
    ]);
    setSelectedPatternIndex(nextSelectedIndex);

    if (typeof sliderRef?.current?.slickGoTo === "function") {
      sliderRef.current.slickGoTo(nextSelectedIndex);
    }
  };

  const handleDelete = id => {
    const _formValues = { ...formValues };

    customizer.removeColorZonePattern(id);
    delete _formValues.custom.patterns[id];
    setFormValues(_formValues);
    setSelectedPatternIndex(0);

    if (typeof sliderRef?.current?.slickGoTo === "function") {
      sliderRef.current.slickGoTo(0);
    }
  };


  return (
    <div style={{ zIndex: 1 }} className={classes.container}>
      {
        !!patterns && patterns.length > 0
        && (
          <SlickSlider
            {...sliderSettings}
            ref={sliderRef}
            initialSlide={selectedPatternIndex}
            afterChange={index => handleSlideChange(index)()}
          >
            {
              patterns.map(([_zoneName, _pattern]) => {
                if (!_pattern) { return null; }

                const { svgStringFile } = appConfig.customizerPresets.patterns.availablePatterns
                  .find(_p => _p.id === _pattern.id);
                const colorZoneLabel = appConfig.formVariants.colors.settings.colorZones
                  .find(_z => _z.id === _zoneName)
                  .title;

                return (
                  <div key={_zoneName}>
                    <Grid
                      item
                      container
                      direction="column"
                      alignItems="center"
                      justifyContent="center"
                      className={classes.patternContainer}
                    >
                      <div
                        dangerouslySetInnerHTML={{ __html: svgStringFile }}
                        className={classes.previewSvg}
                        style={{ color: _pattern.fillColor }}
                      />
                      <Typography variant="caption" className={classes.patternPreviewLabel}>
                        {t(colorZoneLabel)}
                      </Typography>
                    </Grid>
                  </div>
                );
              })
            }
          </SlickSlider>
        )
      }
      {
        !Number.isNaN(Number(selectedPatternIndex)) && !!patterns[selectedPatternIndex]
        && <PatternsEditForm pattern={patterns[selectedPatternIndex][1]} id={patterns[selectedPatternIndex][0]} handleDelete={handleDelete} />
      }
      <PatternsInputForm onPatternAdd={handlePatternAdd} small={!!patterns.length} availableLocations={availableLocations} />
    </div>
  );
}


export default wrapper({ styles }, PatternsForm);
