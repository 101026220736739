import { Container, Drawer, Grid, IconButton, Typography } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import React from "react";
import useTranslate from "../lib/useTranslate";
import wrapper from "../utils/wrapper";


const styles = theme => ({
  container : {
    width : "50vw",
    paddingTop : theme.spacing(5),
    paddingBottom : theme.spacing(2),
    overflowY : "scroll",

    [theme.breakpoints.down("sm")] : {
      width : "90vw",
      paddingTop : theme.spacing(3)
    }
  },

  descriptionTitle : {
    color : theme.palette.text.primary,
    fontWeight : 700,
    fontSize : "1.6875rem",
    marginTop : theme.spacing(4),
    marginBottom : theme.spacing(3)
  },

  subTitle : {
    padding : theme.spacing(4, 0),
    fontWeight : 700
  },

  closeIcon : {
    position : "absolute",
    top : 0,
    right : 0,
    marginTop : theme.spacing(-1)
  }
});


function ProductDescriptionDrawer({ classes, productInfos = {}, isOpen, onClose }) {
  const { t } = useTranslate();

  return (
    <Drawer
      variant="temporary"
      anchor="right"
      open={isOpen}
      onClose={onClose}
      transitionDuration={600}
    >
      <div className={classes.container}>
        <Container maxWidth="sm" style={{ position: "relative" }}>
          <IconButton className={classes.closeIcon} onClick={onClose}>
            <CloseIcon />
          </IconButton>
          <Grid container direction="column" alignItems="flex-start" justifyContent="flex-start">
            <Typography variant="h2" style={{ letterSpacing: "3px" }}>{productInfos.title}</Typography>
            <Typography className={classes.descriptionTitle}>{t("COMPONENTS.PRODUCT_DESCRIPTION_DRAWER.TITLE")}</Typography>
            <Typography color="textPrimary" dangerouslySetInnerHTML={{ __html: productInfos.description }} />
          </Grid>
        </Container>
      </div>
    </Drawer>
  );
}


export default wrapper({ styles }, ProductDescriptionDrawer);
