import { Backdrop, CircularProgress, Grid, Hidden, Typography } from "@material-ui/core";
import Fade from "@material-ui/core/Fade";
import Paper from "@material-ui/core/Paper";
import clx from "classnames";
import React from "react";
import AppContext from "../AppContext";
import Customizer from "../lib/Customizer.class";
import useTranslate from "../lib/useTranslate";
import wrapper from "../utils/wrapper";
import ViewerMobileButtons from "./ViewerMobileButtons";


const styles = theme => ({
  container : {
    position : "relative",
    height : "100%",
    minHeight : 490,
    marginBottom : theme.spacing(5),
    backgroundColor : "#fff",

    [theme.breakpoints.up("md")] : {
      "@media (max-height:800px)" : {
        marginTop : theme.spacing(2),
        minHeight : 420
      }
    },

    [theme.breakpoints.down("sm")] : {
      margin : 0,
      minHeight : "50vh"
    }
  },

  container__mobileFormOpen : {
    [theme.breakpoints.down("sm")] : {
      position : "absolute",
      top : 0,
      left : 0,
      width : "100%",
      height : "50vh",
      maxHeight : "calc(100vh - 400px)",
      minHeight : 0
    }
  },

  paper : {
    minWidth : 130,
    width : 130,
    maxWidth : 130,
    padding : theme.spacing(2),
    position : "absolute",
    bottom : theme.spacing(),
    left : theme.spacing(),
    backgroundColor : "#cbde8e",
    color : "#000"
  },

  loadingBackDrop : {
    zIndex : 2000
  }
});


function Viewer({ classes, onClick, isLoading, isMobileFormOpen }) {
  const { customizer, appConfig } = React.useContext(AppContext);
  const [dimensions, setDimensions] = React.useState();
  const [currentDPI, setCurrentDPI] = React.useState(0);
  const { t } = useTranslate();
  const brandLogosIds = React.useMemo(() => appConfig.customizerPresets.brandLogos.availableLogos.map(_logo => _logo.id), [appConfig]);

  let timeout;

  /**
   * Display a snack bar with the edited image size
   *
   * @param {object} root0
   * @param {string} root0.id
   * @param {string} root0.sizeInCm
   * @param {string} root0.dpi
   */
  const onItemTransforming = ({ id, sizeInCm, dpi }) => {
    if (!sizeInCm || !sizeInCm.width || !sizeInCm.height) {
      return;
    }

    // Do nothing for the brand logos
    if (brandLogosIds.includes(id)) {
      return;
    }

    // Store the dimensions, clear the previous timeout and hide the snackBar after some time
    setDimensions([sizeInCm?.width?.toFixed(2), sizeInCm?.height?.toFixed(2)]);
    setCurrentDPI(id.indexOf("text") === 0 ? 0 : dpi);
    clearTimeout(timeout);
    timeout = setTimeout(setDimensions, 5000);
  };


  const onItemSelected = id => {
    const customization = customizer.getCustomization();
    const relatedItem = customization.custom?.logos?.[id] || customization.custom?.texts?.[id];

    if (relatedItem) {
      onItemTransforming({ id, ...relatedItem });
    }
  };


  React.useEffect(() => {
    if (customizer) {
      customizer.viewer.on("item-selected", onItemSelected);
      customizer.viewer.on("item-transforming", onItemTransforming);
    }

  }, [customizer]);


  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div
      id={Customizer.viewerID}
      className={clx(classes.container, { [classes.container__mobileFormOpen]: isMobileFormOpen })}
      onClick={onClick}
    >
      <Backdrop open={isLoading} className={classes.loadingBackDrop}>
        <Grid container direction="column" alignItems="center" justifyContent="center">
          <CircularProgress size={40} variant="indeterminate" />
        </Grid>
      </Backdrop>
      <Fade in={!!dimensions}>
        <div>
          {!!dimensions && dimensions[0] !== undefined && (
          <Paper
            className={classes.paper}
            style={{ backgroundColor: currentDPI > 0 && currentDPI < appConfig.formVariants.logos.settings.minDPI ? "#f60404" : "#cbde8e" }}
          >
            <Typography variant="caption" color="textPrimary" style={{ fontSize: 12 }}>
              {t("COMPONENTS.VIEWER.APPROXIMATE_SIZE")}
              <br />
              {dimensions?.[0]} x {dimensions?.[1]} cm
              {!!currentDPI && (
              <>
                <br />
                {currentDPI.toFixed(0)} dpi
              </>
                  )}
              {!!currentDPI && currentDPI < appConfig.formVariants.logos.settings.minDPI && (
              <>
                <br />
                <br />
                {t("COMPONENTS.VIEWER.LOGO_RESOLUTION_WARNING")}
              </>
                  )}
            </Typography>
          </Paper>
            )}
        </div>
      </Fade>
      {
        !isMobileFormOpen
        && (
          <Hidden mdUp>
            <ViewerMobileButtons />
          </Hidden>
        )
      }
    </div>
  );
}


export default wrapper({ styles }, Viewer);
