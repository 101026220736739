import MUIButton from "@material-ui/core/Button";
import clx from "classnames";
import React from "react";
import wrapper from "../utils/wrapper";


const styles = theme => ({
  button : {
    borderRadius : 0,
    border : `1px solid ${theme.palette.secondary.main}`,
    padding : theme.spacing(2, 6),

    [theme.breakpoints.down("sm")] : {
      padding : theme.spacing(1, 2),
      fontSize : "0.6rem"
    },

    "&:hover" : {
      backgroundColor : theme.palette.secondary.main,
      color : theme.palette.secondary.contrastText
    }
  }
});


function Button({ classes, children, className, ...rest }) {
  return (
    <MUIButton {...rest} variant="outlined" className={clx(classes.button, className)}>
      {children}
    </MUIButton>
  );
}


export default wrapper({ styles }, Button);
