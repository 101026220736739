import MUISlider from "@material-ui/core/Slider";
import Tooltip from "@material-ui/core/Tooltip";
import withStyles from "@material-ui/core/styles/withStyles";
import React from "react";
import wrapper from "../utils/wrapper";


function ValueLabelComponent(props) {
  const { children, open, value } = props;

  return (
    <Tooltip open={open} enterTouchDelay={0} placement="top" title={value}>
      {children}
    </Tooltip>
  );
}


const StyledSlider = withStyles({
  root : {
    color : "#181818",
    height : 2,
    padding : "15px 0"
  },
  thumb : {
    height : 16,
    width : 16,
    backgroundColor : "#fff",
    border : "2px solid #181818",
    marginTop : -8,
    marginLeft : -8,
    "&:focus, &:hover, &$active" : {
      boxShadow : "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)"
    }
  },
  active : {},
  valueLabel : {
    left : "calc(-50% + 12px)",
    top : -22,
    "& *" : {
      background : "transparent",
      color : "#000"
    }
  },
  track : {
    height : 2
  },
  mark : {
    backgroundColor : "#fff"
  },
  rail : {
    height : 2,
    backgroundColor : "#181818",
    opacity : 1
  }
})(MUISlider);


const Slider = React.memo(props => {
  // Ugly line of code to handle one special case that cannot be handled differently
  const isObjectValue = typeof props.value === "object" && !!props.value.width;
  const realValue = isObjectValue ? props.value.width : props.value;

  return (
    <StyledSlider
      {...props}
      valueLabelDisplay="auto"
      ValueLabelComponent={ValueLabelComponent}
      value={typeof realValue === "number" ? parseFloat(realValue.toFixed(2)) : realValue}
      onChange={(evt, value) => props.onChange(evt, isObjectValue ? { width: value } : value)}
      onChangeCommitted={(evt, value) => props.onChangeCommitted(evt, isObjectValue ? { width: value } : value)}
    />

  );
}, ((prevProps, nextProps) => prevProps.value === nextProps.value));

export default wrapper({}, Slider);
