import React from "react";


export default props => (
  <svg width="23" height="23" viewBox="0 0 23 23" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g stroke="#FFF" strokeWidth="1.452" fill="none" fillRule="evenodd" strokeLinecap="round">
      <path d="M15.37 7.984h6.212c.248 0 .449.2.449.449v13.006a.449.449 0 01-.45.448H1.45A.449.449 0 011 21.44V8.433c0-.248.201-.45.449-.45h6.236" strokeLinejoin="bevel" />
      <g strokeLinejoin="round">
        <path d="M7.377 5.255l3.967-3.956 3.976 3.956M11.349 16.787V1.825" />
      </g>
    </g>
  </svg>
);
