import MuiExpansionPanel from "@material-ui/core/Accordion";
import MuiExpansionPanelDetails from "@material-ui/core/AccordionDetails";
import MuiExpansionPanelSummary from "@material-ui/core/AccordionSummary";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React from "react";
import wrapper from "../utils/wrapper";


const styles = () => ({});

const Panel = withStyles(theme => ({
  root : {
    boxShadow : "none",
    backgroundColor : "transparent",
    "&:not(:last-child)" : {
      borderBottom : `1px solid ${theme.palette.grey[50]}`
    },
    "&:before" : {
      display : "none"
    },
    "&$expanded" : {
      margin : "auto"
    }
  },
  expanded : {}
}))(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles(() => ({
  root : {
    minHeight : 56,
    "&$expanded" : {
      minHeight : 56
    },
    paddingLeft : 0
  },
  content : {
    "&$expanded" : {
      margin : "12px 0"
    }
  },
  expanded : {}
}))(MuiExpansionPanelSummary);

const ExpansionPanelDetails = withStyles(theme => ({
  root : {
    padding : theme.spacing(0, 0.1, 2, 0)
  }
}))(MuiExpansionPanelDetails);


const ExpansionPanel = React.memo(({ expanded, onChange, title, children }) => (
  <Panel expanded={expanded} onChange={onChange}>
    <ExpansionPanelSummary
      aria-controls="panel1bh-content"
      id="panel1bh-header"
    >
      {!!title && title}
    </ExpansionPanelSummary>
    <ExpansionPanelDetails>
      {children}
    </ExpansionPanelDetails>
  </Panel>
  ), (prevProps, nextProps) => prevProps.expanded === nextProps.expanded && prevProps.title === nextProps.title);

ExpansionPanel.propTypes = {
  expanded : PropTypes.bool,
  onChange : PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  title : PropTypes.any.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  children : PropTypes.any.isRequired
};

export default wrapper({ styles }, ExpansionPanel);
