import { Container, Grid, Hidden, ThemeProvider, Typography } from "@material-ui/core";
import ScopedCssBaseline from "@material-ui/core/ScopedCssBaseline";
import React from "react";
import packageJson from "../package.json";
import AppContext from "./AppContext";
import FormContext from "./FormContext";
import Button from "./components/Button";
import ControlPanel from "./components/ControlPanel";
import CustomizerLoadingBackDrop from "./components/CustomizerLoadingBackDrop";
import Footer from "./components/Footer";
import FormVariantTabSelect from "./components/FormVariantTabSelect";
import Forms from "./components/Forms";
import LoadingModal from "./components/LoadingModal";
import ProductDescriptionDrawer from "./components/ProductDescriptionDrawer";
import ProductInfo from "./components/ProductInfo";
import Snackbar from "./components/Snackbar";
import Viewer from "./components/Viewer";
import Customizer from "./lib/Customizer.class";
import useTranslate from "./lib/useTranslate";
import { config, theme } from "./presets/presetsTarget";
import wrapper from "./utils/wrapper";
import "./assets/slick.css";
import "./assets/main-fonts.css";

const isStaging = [
  "defil.vuxe.fr",
  "demo-defil.vuxe.fr",
  "puma-preprod.vuxe.fr",
  "localhost:3000"
].includes(document.location.host);

const styles = themeConfig => ({
  fluidContainer : {
    [themeConfig.breakpoints.down("sm")] : {
      padding : 0
    }
  },

  container : {
    height : "100%",
    minHeight : "100vh",
    display : "flex",
    flexDirection : "column",
    justifyContent : "space-between",
    marginLeft : themeConfig.spacing(2),
    marginRight : themeConfig.spacing(2),

    [themeConfig.breakpoints.down("sm")] : {
      marginLeft : 0,
      marginRight : 0
    }
  },

  content : {
    height : "100%",
    marginTop : themeConfig.spacing(6),
    flexGrow : 1,

    [themeConfig.breakpoints.down("sm")] : {
      marginTop : 0,
      flexDirection : "column",
      justifyContent : "flex-end",
      height : "100vh"
    }
  },

  sideCol : {
    marginTop : themeConfig.spacing(6),

    [themeConfig.breakpoints.up("md")] : {
      "@media (max-height:800px)" : {
        marginTop : themeConfig.spacing(2)
      }
    },

    [themeConfig.breakpoints.down("sm")] : {
      margin : 0
    }
  },

  sizer : {
    position : "fixed",
    bottom : 0,
    right : 0,

    "&:before" : {
      content : '""',
      [themeConfig.breakpoints.up("xs")] : {
        content : '"xs"'
      },
      [themeConfig.breakpoints.up("sm")] : {
        content : '"sm"'
      },
      [themeConfig.breakpoints.up("md")] : {
        content : '"md"'
      },
      [themeConfig.breakpoints.up("lg")] : {
        content : '"lg"'
      }
    }
  },

  centerContainer : {
    height : "80vh",
    padding : themeConfig.spacing(0, 3),

    [themeConfig.breakpoints.down("sm")] : {
      flexDirection : "column-reverse",
      padding : 0,
      height : "100vh"
    }
  },

  smCreateTeamButton : {
    width : "100%",
    alignSelf : "center",
    fontSize : "0.7rem",
    height : 50,
    maxHeight : 50
  },

  canvas : {
    top : 0,
    left : 0,
    //  width: 2048,
    position : "fixed",
    pointerEvents : "none",
    opacity : 0,
    zIndex : -1
  }
});

function App({ classes }) {
  const [isMobileFormOpen, setIsMobileFormOpen] = React.useState(false);
  const [formValues, _setFormValues] = React.useState({});
  const [formVariant, setFormVariant] = React.useState(config.defaultFormVariant);
  const [customizer, setCustomizer] = React.useState();
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState();
  const [fatalErrorMessage, setFatalErrorMessage] = React.useState();
  const [loadingMessage, setLoadingMessage] = React.useState();
  const [selectedViewerItemId, setSelectedViewerItemId] = React.useState();
  const [isCustomizerReady, setIsCustomizerReady] = React.useState(false);
  const [isProcessing, setIsProcessing] = React.useState(false);
  const [loadingProgressValue, setLoadingProgressValue] = React.useState(0);
  const [productInfos, setProductInfos] = React.useState(customizer?.productInfos);
  const [loadingProgressMessage, setLoadingProgressMessage] = React.useState(undefined);
  const { t } = useTranslate();
  const brandLogosIds = React.useMemo(() => config.customizerPresets.brandLogos.availableLogos.map(_logo => _logo.id), [config]);

  // Triggered each time an item is selected on the 3D viewer
  function handleViewerItemSelected({ variant, id }) {
    if (brandLogosIds.includes(id)) { return; }

    if (!!variant && formVariant !== variant) {
      setFormVariant(variant);
    }

    if (!!id && selectedViewerItemId !== id) {
      setSelectedViewerItemId(id);
    }
  }

  // Init customizer
  React.useEffect(() => {
    console.log(`ⓘ Running ${packageJson.name} v${packageJson.version}`);

    const _customizer = new Customizer({
      appConfig : config,
      theme,
      onUpdate : _setFormValues,
      onError : error => error !== setErrorMessage({ error }),
      onFatalError : setFatalErrorMessage,
      onLoading : setLoadingMessage,
      onProcessing : setIsProcessing,
      onViewerItemSelected : handleViewerItemSelected,
      onProductInfoChange : setProductInfos,
      onLoadingProgress : (progressValue, progressMessage) => {
        setLoadingProgressValue(progressValue);
        setLoadingProgressMessage(progressMessage);
        if (progressValue >= 100) { setIsCustomizerReady(true); }
      }
    });
    _customizer.init()
      .then(() => {
        setCustomizer(_customizer);
        _setFormValues(_customizer.getCustomization());
      })
      .catch(err => console.error(err));

  }, []);

  // Store error message
  React.useEffect(() => {
    if (!!formValues.error && formValues.error !== errorMessage) {
      setErrorMessage({ error: formValues.error });
    }

  }, [formValues]);

  // Clean error message
  React.useEffect(() => {
    if (errorMessage) {
      //  setErrorMessage(undefined)
    }
  }, [errorMessage]);

  /**
   * Update the form values and the viewer
   *
   * @type {Function}
   * @private
   */const setFormValues = React.useCallback((_formValues = {}, cb) => !!customizer && customizer.update(_formValues, cb), [customizer]);
  /**
   * Refresh the customization
   * Used has a callback when the viewer is clicked in case the user
   * has changed an asset parameter
   *
   * @type {function(): (boolean|void)}
   */const refreshCustomizationOnViewerUpdate = () => !!customizer && _setFormValues(customizer.getCustomization());
  const onMobileModalClose = React.useCallback(() => {
    setIsMobileFormOpen(false);
  }, []);

  /**
   * Change the current form variant
   *
   * @type {Function}
   * @private
   */
  const _setFormVariant = _variant => {
    setFormVariant(_variant);
    setIsMobileFormOpen(true);
  };

  if (fatalErrorMessage) {
    return (
      <Grid container className={classes.content} alignItems="center" justifyContent="center">
        <Typography style={{ color: "red" }} align="center">
          {fatalErrorMessage}
        </Typography>
      </Grid>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="xl" className={classes.fluidContainer}>
        <FormContext.Provider value={{ formValues, setFormValues }}>
          <AppContext.Provider value={{ customizer, appConfig: config }}>

            {/** LOADING BACKDROP * */}
            <CustomizerLoadingBackDrop
              isCustomizerReady={isCustomizerReady}
              isStaging={isStaging}
              loadingProgressMessage={loadingProgressMessage}
              loadingProgressValue={loadingProgressValue}
            />

            {/** MAIN CONTAINER * */}
            <div className={classes.container}>
              <Grid container className={classes.content}>

                {/** DESKTOP FORMS * */}
                <Grid item md={3} xs={false} className={classes.sideCol}>
                  <Forms
                    isLoading={!customizer}
                    variant={formVariant}
                    setFormVariant={setFormVariant}
                    isMobileFormOpen={isMobileFormOpen}
                    onMobileModalClose={onMobileModalClose}
                    selectedViewerItemId={selectedViewerItemId}
                  />
                </Grid>

                {/** VIEWER * */}
                <Grid
                  item
                  container
                  md={6}
                  xs={12}
                  direction="column"
                  wrap="nowrap"
                  style={{ alignSelf: "flex-start" }}
                  className={classes.centerContainer}
                >
                  <Hidden mdUp>
                    <Button
                      onClick={() => customizer.saveCustomization(true)}
                      className={classes.smCreateTeamButton}
                      color="secondary"
                    >
                      {t("ACTIONS.CREATE_TEAM_LINEUP")}
                    </Button>
                  </Hidden>
                  {!!customizer && <FormVariantTabSelect value={formVariant} onChange={_setFormVariant} />}
                  <Hidden mdUp>
                    {!!customizer && <ProductInfo openDrawer={() => setIsDrawerOpen(true)} productInfos={productInfos} />}
                  </Hidden>
                  <Viewer
                    onClick={refreshCustomizationOnViewerUpdate}
                    isLoading={isProcessing}
                    isMobileFormOpen={isMobileFormOpen}
                  />
                </Grid>

                {/** DESKTOP CONTROL PANEL * */}
                <Hidden smDown>
                  <Grid item md={3} className={classes.sideCol}>
                    {!!customizer && (
                      <ControlPanel
                        setIsDrawerOpen={setIsDrawerOpen}
                        isDrawerOpen={isDrawerOpen}
                        productInfos={productInfos}
                      />
                    )}
                  </Grid>
                </Hidden>
              </Grid>

              {/** DESKTOP FOOTER * */}
              <Hidden smDown>
                {!!customizer && <Footer />}
              </Hidden>
            </div>

            {/** MISC * */}
            <Snackbar messageKey={errorMessage}>
              {errorMessage?.error}
            </Snackbar>

            {!!customizer && (
              <ProductDescriptionDrawer
                isOpen={isDrawerOpen}
                onClose={() => setIsDrawerOpen(false)}
                productInfos={productInfos}
              />
            )}

            <LoadingModal message={loadingMessage} />
          </AppContext.Provider>
        </FormContext.Provider>
        {Customizer.Canvases.map((Canvas, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Canvas className={classes.canvas} key={index} />
        ))}
        {process.env.NODE_ENV === "development" && <div className={classes.sizer} />}
        <ScopedCssBaseline />
      </Container>
    </ThemeProvider>
  );
}


export default wrapper({ styles }, App);
