import React from "react";


export default props => (
  <svg width="19" height="11" viewBox="0 0 19 11" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g stroke="#FFF" strokeWidth="1.452" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
      <path d="M13.234 9.534l3.956-3.967-3.956-3.976M1.702 5.562h14.962" />
    </g>
  </svg>
);
