import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import CreateIcon from "@material-ui/icons/Create";
import CheckIcon from "@material-ui/icons/Done";
import React from "react";
import AppContext from "../../../AppContext";
import wrapper from "../../../utils/wrapper";


const styles = theme => ({
  textField : {
    cursor : "pointer",
    padding : theme.spacing(1, 2),
    top : 2,
    minWidth : 200,
    width : "100%",

    "&:hover button" : {
      opacity : "1 !important"
    },

    "& > div" : {
      backgroundColor : "transparent !important",
      paddingRight : theme.spacing()
    }
  },

  input : {
    fontSize : "20px !important",
    color : "#000",
    lineHeight : "1rem !important",
    padding : `${theme.spacing()}px !important`,
    background : "transparent !important",
    fontWeight : "normal !important",

    "&:disabled" : {
      backgroundColor : "transparent !important"
    }
  },

  iconButton : {
    "&:disabled" : {
      opacity : 0
    }
  }
});


function TextFormEditInput({ classes, value, onTextUpdate, fontFamily, onTextBeingEdited, text }) {
  const [userEntry, setUserEntry] = React.useState(value);
  const [isEdited, setIsEdited] = React.useState(false);
  const inputRef = React.useRef();
  const { appConfig, customizer } = React.useContext(AppContext);
  const usageOptions = (appConfig.formVariants.texts.settings.getUsageOptions(customizer.productType) || [])
    .filter(() => !!appConfig.formVariants.texts.settings.onePerUsage)
    .find?.(_usage => !!text.usage && _usage.value === text.usage);


  React.useEffect(() => {
    setUserEntry(value);
  }, [value]);

  React.useEffect(() => {
    if (isEdited && inputRef?.current) {
      inputRef.current.select();
      inputRef.current.focus();
    }
  }, [isEdited]);


  const handleInputChange = e => {
    if (e.target.value.length <= appConfig.formVariants.texts.settings.maxLength) {
      setUserEntry(e.target.value);
    }
  };

  const handleInputClick = () => {
    if (!isEdited) {
      if (typeof onTextBeingEdited === "function") {
        onTextBeingEdited();
      }
      setIsEdited(true);
    }
  };

  const handleConfirmClick = () => {
    if (isEdited) {
      onTextUpdate(userEntry || value);
      setIsEdited(false);
    }
  };

  const handleInputKeyPress = e => {
    if (e.keyCode === 13 && isEdited) {
      handleConfirmClick();
    }
  };

  const handleClickAway = () => {
    if (isEdited === true) {
      setIsEdited(false);
      setUserEntry(value);
    }
  };

  return (
    <div className={classes.container}>
      <ClickAwayListener onClickAway={handleClickAway}>
        <TextField
          inputRef={inputRef}
          value={userEntry}
          onClick={handleInputClick}
          onChange={handleInputChange}
          className={classes.textField}
          onKeyDown={handleInputKeyPress}
          placeholder={value}
          variant="filled"
          InputProps={{
            endAdornment : (
              <InputAdornment position="end">
                <IconButton disabled={!isEdited} className={classes.iconButton} onClick={handleConfirmClick} disableRipple>
                  {
                    isEdited
                      ? <CheckIcon />
                      : <CreateIcon />
                  }
                </IconButton>
              </InputAdornment>
            ),
            inputProps : {
              style : {
                fontFamily,
                cursor : isEdited ? "text" : "pointer"
              },
              className : classes.input,
              maxLength : usageOptions?.maxLength
            }
          }}
          disabled={!isEdited}
        />
      </ClickAwayListener>
    </div>
  );
}


export default wrapper({ styles }, TextFormEditInput);
