import isEqual from "lodash/isEqual";
import isObject from "lodash/isObject";
import transform from "lodash/transform";

function changes(object, base) {
  return transform(object, (result, value, key) => {
    if (!isEqual(value, base[key])) {
      result[key] = (isObject(value) && isObject(base[key])) ? changes(value, base[key]) : value;
    }
  });
}

export default function deepDiff(object, base) {
  return changes(object, base);
}
