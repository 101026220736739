import { Tab, Tabs, withStyles } from "@material-ui/core";
import React from "react";
import AppContext from "../AppContext";
import useTranslate from "../lib/useTranslate";
import wrapper from "../utils/wrapper";


const styles = theme => ({
  container : {
    borderBottom : `1px solid ${theme.palette.grey[50]}`,
    alignSelf : "center",

    [theme.breakpoints.down("sm")] : {
      borderBottom : 0,
      borderTop : `1px solid ${theme.palette.grey[50]}`,
      alignSelf : "stretch",
      "&>div>div" : {
        justifyContent : "center"
      }
    }
  },

  indicator : {
    [theme.breakpoints.down("sm")] : {
      backgroundColor : "transparent"
    }
  },

  tab : {
    [theme.breakpoints.down("sm")] : {
      color : "#818181 !important",
      margin : 0,
      padding : 5,

      "& *" : {
        fontSize : "0.7rem"
      }
    }
  }
});


const AntTab = withStyles(theme => ({
  root : {
    minWidth : 72,
    marginRight : theme.spacing(2),
    fontSize : ".8125rem"
  },
  selected : {}
}))(props => <Tab disableRipple {...props} />);

const AntTabs = withStyles(theme => ({
  indicator : {
    [theme.breakpoints.down("sm")] : {
      top : -1,
      bottom : "inherit"
    }
  }
}))(props => <Tabs {...props} />);


function FormVariantTabSelect({ classes, value, onChange }) {
  const { appConfig } = React.useContext(AppContext);
  const keys = React.useMemo(() => Object.keys(appConfig.formVariants), []);
  const values = React.useMemo(() => Object.values(appConfig.formVariants), []);
  const { t } = useTranslate();

  return (
    <AntTabs
      classes={{
        indicator : classes.indicator
      }}
      className={classes.container}
      value={keys.indexOf(value)}
      indicatorColor="primary"
      textColor="primary"
      onChange={(_, _value) => onChange(keys[_value])}
    >
      {
        values.map(_variant => (
          <AntTab label={t(_variant.title)} key={_variant.title} className={classes.tab} />
        ))
      }
    </AntTabs>
  );
}


export default wrapper({ styles }, FormVariantTabSelect);
