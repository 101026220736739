import { Fade, Grid, IconButton, Modal, Typography } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import FavoriteIcon from "@material-ui/icons/FavoriteBorder";
import React from "react";
import urlJoin from "url-join";
import AppContext from "../AppContext";
import useTranslate from "../lib/useTranslate";
import wrapper from "../utils/wrapper";
import Button from "./Button";


const styles = theme => ({
  modal : {
    display : "flex",
    alignItems : "center",
    justifyContent : "center"
  },

  paper : {
    position : "relative",
    backgroundColor : theme.palette.background.paper,
    padding : theme.spacing(5),
    width : "auto",

    "&:focus" : {
      outline : "none"
    }
  },

  closeIcon : {
    position : "absolute",
    top : 0,
    right : 0
  },

  favIconContainer : {
    display : "flex",
    alignItems : "center",
    justifyContent : "center",
    width : theme.spacing(9),
    height : theme.spacing(9),
    backgroundColor : "#fff",
    borderRadius : "100%",
    boxShadow : "0px 0px 4px 0px rgba(0,0,0,0.5)",
    marginBottom : theme.spacing(5)
  },

  title : {
    fontSize : "1.6875rem",
    textTransform : "initial",
    marginBottom : theme.spacing(2)
  },

  text : {
    padding : theme.spacing(1, 0, 4),
    maxWidth : 190,
    textAlign : "center",

    "& a" : {
      color : theme.palette.text.grey,
      ...theme.styles.hover.onLinkUnderline,
      textDecoration : "none"
    }
  },

  button : {
    width : "100%",
    marginBottom : theme.spacing()
  }
});


function SavingConfirmModal({ classes, isOpen, onClose }) {
  const { customizer, appConfig } = React.useContext(AppContext);
  const { t } = useTranslate();

  const courseURL = window.drupalSettings?.defil_core?.urls?.course || urlJoin("/",
    appConfig.api.pathPrefix,
    "/parcours");
  const myProjectsURL = window.drupalSettings?.defil_core?.urls?.projects_link || urlJoin("/",
    appConfig.api.pathPrefix,
    `/user/${customizer?.userId}/projects`);

  return React.useMemo(() => (
    <Modal
      open={isOpen}
      onClose={onClose}
      closeAfterTransition
      className={classes.modal}
      BackdropProps={{
          timeout : 500
        }}
    >
      <Fade in={isOpen}>
        <Grid container direction="column" alignItems="center" className={classes.paper}>
          <IconButton className={classes.closeIcon} onClick={onClose}>
            <CloseIcon />
          </IconButton>
          <div className={classes.favIconContainer}>
            <FavoriteIcon />

          </div>
          <Typography variant="h1" className={classes.title} paragraph>
            {t("COMPONENTS.SAVING_PROJECT_MODAL.PROJECT_SAVED")}
          </Typography>
          {!!customizer?.userId && (
            <Typography variant="body1" color="textPrimary" className={classes.text}>
              {t("COMPONENTS.SAVING_PROJECT_MODAL.FIND_IT_AT_ANY_TIME_IN")}{" "}
              <a href={myProjectsURL}>
                {t("COMPONENTS.SAVING_PROJECT_MODAL.MY_PROJECTS")}
              </a>
            </Typography>
          )}
          <Button className={classes.button} href={courseURL}>
            {t("ACTIONS.CREATE_NEW_PROJECT")}
          </Button>
          <Button className={classes.button} onClick={() => customizer.saveCustomization(true)}>
            {t("ACTIONS.CREATE_TEAM_LINEUP")}
          </Button>
        </Grid>
      </Fade>
    </Modal>
    ),
   [customizer, isOpen, classes, onClose]);
}


export default wrapper({ styles }, SavingConfirmModal);
