import { Button, TextField, Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import AddIcon from "@material-ui/icons/Add";
import clx from "classnames";
import React from "react";
import AppContext from "../../../AppContext";
import FormContext from "../../../FormContext";
import useTranslate from "../../../lib/useTranslate";
import wrapper from "../../../utils/wrapper";
import Select from "../../Select";
import ArrowRight from "../../svg/ArrowRight";
import InfoIcon from "../../svg/Info";


const styles = theme => ({
  container : {
    width : "100%",
    display : "flex",
    flexDirection : "column",
    marginTop : theme.spacing(2)
  },

  textField : {
    display : "flex",
    alignItems : "flex-start",
    justifyContent : "center",
    backgroundColor : theme.palette.grey[40],
    borderColor : "transparent",
    padding : theme.spacing(0, 2),
    borderRadius : 10,
    height : 40,
    width : "100%",

    "& > div, & input" : {
      width : "100%"
    },

    "& input" : {
      height : "100%"
    }
  },

  insertButton : {
    fontSize : "0.8rem",
    borderRadius : 10,
    marginLeft : theme.spacing(),
    height : 40
  },

  insertButton__small : {
    padding : theme.spacing(0, 4)
  },

  insertIcon : {
    width : 12,
    minWidth : 12
  },

  buttonLabel : {
    marginLeft : theme.spacing(),
    fontSize : "0.6rem"
  },

  infoIcon : {
    flexShrink : 0,
    marginRight : theme.spacing(2)
  },

  helpText : {
    marginTop : theme.spacing(4),
    marginBottom : theme.spacing(2)
  },

  locationSelect : {
    marginBottom : theme.spacing(),
    marginTop : theme.spacing()
  }
});


function TextsInputForm({ classes, onTextAdd, small, availableLocations }) {
  const { formValues } = React.useContext(FormContext);
  const { appConfig, customizer } = React.useContext(AppContext);
  const [value, setValue] = React.useState("");
  const [location, setLocation] = React.useState(availableLocations[0]?.value);
  const usagesOptions = (appConfig.formVariants.texts.settings.getUsageOptions(customizer.productType) || [])
    .filter(_option => !appConfig.formVariants.texts.settings.onePerUsage || !Object.values(formValues.custom.texts || {}).some(
      _text => _text.usage === _option.value
));
  const [usage, setUsage] = React.useState(usagesOptions?.[0]);
  const { t } = useTranslate();

  const handleChange = e => {
    // Force int only for the number usage
    if (usage?.value === "number" && Number.isNaN(Number(e.target.value))) { return; }

    if (e.target.value.length <= appConfig.formVariants.texts.settings.maxLength) {
      setValue(e.target.value);
    }
  };

  const handleTextAdd = () => {
    onTextAdd(value, location, usage?.value);
    setValue("");
  };

  React.useEffect(() => {
    if (!usagesOptions?.some?.(_option => _option?.value === usage?.value)) {
      setUsage(usagesOptions?.[0]);
    }

  }, [usagesOptions]);

  return (
    <div>
      <div className={classes.container}>
        <Grid container wrap="nowrap" spacing={1}>
          {
            !appConfig.formVariants.texts.settings.hiddenInputs?.includes?.("position")
            && (
            <Grid item container xs={12} direction="column">
              <Typography variant="caption">{t("WORDS.POSITION")} :</Typography>
              <Select
                value={location}
                onChange={e => setLocation(e.target.value)}
                options={availableLocations}
                className={classes.locationSelect}
              />
            </Grid>
)
          }
          <Grid item container xs={12} direction="column">
            <Typography variant="caption">{t("WORDS.USAGE")} :</Typography>
            <Select
              noEmptyItem
              value={usage?.value}
              onChange={e => setUsage(usagesOptions.find(_usage => _usage.value === e.target.value))}
              options={usagesOptions}
              className={classes.locationSelect}
              disabled={usagesOptions?.length <= 1}
            />
          </Grid>
        </Grid>
        <Grid container wrap="nowrap">
          <TextField
            value={value}
            onChange={handleChange}
            className={classes.textField}
            placeholder={t("COMPONENTS.TEXTS_FORM.ENTER_YOUR_TEXT")}
            maxLength={usage?.maxLength}
            inputProps={{
              maxLength : usage?.maxLength
            }}
          />
          <Button
            onClick={handleTextAdd}
            variant={small ? "text" : "contained"}
            color="primary"
            disableElevation
            size="large"
            className={clx(classes.insertButton, { [classes.insertButton__small]: small })}
          >
            {
              small
                ? <AddIcon className={classes.insertIcon} fontSize="large" />
                : <ArrowRight className={classes.insertIcon} />
            }
            <span className={classes.buttonLabel}>{small ? t("ACTIONS.ADD") : t("ACTIONS.INSERT")}</span>
          </Button>
        </Grid>
      </div>
      {
        !small
        && (
        <Grid container wrap="nowrap" className={classes.helpText}>
          <InfoIcon className={classes.infoIcon} />
          <Typography>
            {t(
              "COMPONENTS.TEXTS_FORM.BOTTOM_HELP_TEXT"
            )}
          </Typography>
        </Grid>
)
      }
    </div>
  );
}


export default wrapper({ styles }, TextsInputForm);
