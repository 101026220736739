import { Grid, Modal, Paper, Typography } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import React from "react";
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton } from "react-share";
import urlJoin from "url-join";
import AppContext from "../AppContext";
import useTranslate from "../lib/useTranslate";
import wrapper from "../utils/wrapper";
import EmailIcon from "./svg/EmailIcon";
import FacebookIIcon from "./svg/FacebookIIcon";
import LinkedInIcon from "./svg/LinkedInIcon";
import Share from "./svg/Share";
import TwitterIcon from "./svg/TwitterIcon";


const styles = theme => ({
  modal : {
    width : "100%",
    height : "100%",
    display : "flex",
    alignItems : "center",
    justifyContent : "center"
  },

  paper : {
    padding : theme.spacing(5),
    minWidth : 350,

    [theme.breakpoints.down("sm")] : {
      minWidth : 0,
      width : "100%",
      margin : theme.spacing()
    }
  },

  preview : {
    width : "100%",
    maxWidth : 350
  },

  buttonsContainer : {
    marginTop : theme.spacing(4)
  },

  button : {
    display : "flex",
    alignItems : "center",
    justifyContent : "center",
    width : 24,
    height : 24,
    minWidth : 12,
    minHeight : 12,
    marginRight : theme.spacing(2),
    borderRadius : 5,
    position : "relative",
    zIndex : 1,
    cursor : "pointer",

    "& svg" : {
      width : "100%"
    },

    "&::before" : {
      content : '""',
      boxSizing : "initial !important",
      backgroundColor : theme.palette.primary.main,
      width : "100%",
      height : "100%",
      position : "absolute",
      zIndex : -1,
      padding : 4,
      left : -4,
      top : -4,
      borderRadius : 5,
      opacity : 0,
      transition : `opacity 0.6s ${theme.transitions.easing.easeOut}`
    },

    "&:hover" : {
      color : "#fff !important",
      "& svg" : {
        fill : "#fff !important"
      },
      "&::before" : {
        transition : `opacity 0.6s ${theme.transitions.easing.easeOut}`,
        opacity : 1
      }
    },

    "&  > a" : {
      display : "flex",
      alignItems : "center",
      justifyContent : "center"
    }
  }
});

const ShareButton = function ControlPanel({ classes, className }) {
  const [save, setSave] = React.useState();
  const { customizer, appConfig } = React.useContext(AppContext);
  const { t } = useTranslate();


  /**
   * Save the project and store its url in the user clipboard
   */
  const handleShare = () => {
    if (!customizer.shouldProjectBeSaved()) {
      const customization = customizer.getCustomization();
      setSave({
        title : customizer.projectTitle,
        preview : customization?.captures?.front?.base64Image || customization?.captures?.front,
        url : document.location.href
      });
      return;
    }
    customizer.saveCustomization(false)
      .then(res => {
        if (!res || typeof res.configuration !== "string") {
          return;
        }

        const customization = JSON.parse(res.configuration);

        setSave({
          title : res.title,
          preview : customization?.captures?.front?.base64Image || customization?.captures?.front,
          url : document.location.href
        });
      })
      .catch(err => console.log("error", err));
  };

  React.useEffect(() => {
    if (!!save && !!window.Drupal) {
      setTimeout(() => {
        console.log("Calling window.Drupal.ajax.bindAjaxLinks(document.body)");
        // For the email sharing modal handled by Drupal
        window.Drupal.ajax.bindAjaxLinks(document.body);
      }, 500);
    }
  }, [save]);

  if (!customizer) { return null; }

  return (
    <>
      <IconButton onClick={handleShare} className={className}>
        <Share />
      </IconButton>
      <Modal
        open={!!save}
        onClose={() => setSave(undefined)}
        className={classes.modal}
      >
        <Paper className={classes.paper}>
          <Grid container>
            <Grid item xs={6}>
              <img src={save?.preview} className={classes.preview} alt={save?.title} />
            </Grid>
            <Grid item xs={1} />
            <Grid item container direction="column" xs={5}>
              {!!customizer.productInfos?.title && (
                <Typography variant="body1">
                  {customizer.productInfos.title}
                </Typography>
              )}
              <Typography variant="h2">
                {save?.title}
              </Typography>
              <Grid item container className={classes.buttonsContainer} direction="column">
                <Typography variant="caption" paragraph>
                  {t("COMPONENTS.SHARE_BUTTON.SHARE_ON")} :
                </Typography>
                <Grid container wrap="nowrap">
                  <FacebookShareButton url={save?.url} className={classes.button}>
                    <FacebookIIcon />
                  </FacebookShareButton>
                  <LinkedinShareButton url={save?.url} className={classes.button}>
                    <LinkedInIcon />
                  </LinkedinShareButton>
                  <TwitterShareButton url={save?.url} className={classes.button}>
                    <TwitterIcon />
                  </TwitterShareButton>
                  <div className={classes.button} role="button">
                    <a
                      href={urlJoin("/",
                        appConfig.api.pathPrefix,
                        "/project",
                        customizer.projectId || customizer.productId,
                        "/share")}
                      data-dialog-options="{&quot;width&quot;:400}"
                      data-dialog-type="modal"
                      className="use-ajax"
                    >
                      <EmailIcon />
                    </a>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Modal>
    </>
  );
};

export default wrapper({ styles }, ShareButton);
