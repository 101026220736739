import { Grid, Hidden, Modal, Paper, Typography } from "@material-ui/core";
import React from "react";
import useTranslate from "../lib/useTranslate";
import wrapper from "../utils/wrapper";
import Button from "./Button";


const styles = theme => ({
  modal : {
    width : "100%",
    height : "100%",
    display : "flex",
    alignItems : "center",
    justifyContent : "center"
  },

  paper : {
    padding : theme.spacing(3),
    maxWidth : "60vw"
  },

  textField : {
    width : "100%",
    marginBottom : theme.spacing(2),

    "& input" : {
      minHeight : 18,
      borderRadius : 0,
      padding : "18.5px 14px"
    }
  },

  buttonsContainer : {
    marginTop : theme.spacing(2)
  }
});


function NoPlayerTextWarningModal({ classes, onSubmit }) {
  const [isOpen, setIsOpen] = React.useState(true);
  const { t } = useTranslate();

  const handleSubmit = value => {
    setIsOpen(false);
    onSubmit(value);
  };

  const handleClose = () => {
    setIsOpen(false);
    onSubmit(undefined);
  };

  return (
    <Modal
      onClose={handleClose}
      open={isOpen}
      className={classes.modal}
    >
      <Paper className={classes.paper}>
        <Grid container alignItems="center" justifyContent="center" direction="column">
          <Typography variant="body2" paragraph>
            {t(
              "COMPONENTS.NO_PLAYER_TEXT_WARNING_MODAL.P1"
            )}
          </Typography>
          <Hidden smDown>
            <Typography variant="body1" paragraph>
              {t("COMPONENTS.NO_PLAYER_TEXT_WARNING_MODAL.P2")}
            </Typography>
          </Hidden>
          <Grid container spacing={2} className={classes.buttonsContainer}>
            <Grid item>
              <Button onClick={() => handleSubmit("cancel")}>
                {t("ACTIONS.CANCEL")}
              </Button>
            </Grid>
            <Grid item>
              <Button variant="contained" type="submit" onClick={() => handleSubmit("confirm")}>
                {t("COMPONENTS.NO_PLAYER_TEXT_WARNING_MODAL.CONFIRM_BUTTON_LABEL")}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Modal>
  );
}


export default wrapper({ styles }, NoPlayerTextWarningModal);
