import { Drawer, Grid, Typography } from "@material-ui/core";
import React from "react";
import Slider from "react-slick";
import useTranslate from "../lib/useTranslate";
import wrapper from "../utils/wrapper";


const styles = theme => ({
  container : {
    paddingTop : theme.spacing(4),
    paddingBottom : theme.spacing(5)
  },

  content : {
    overflow : "hidden",
    paddingBottom : theme.spacing(4)
  },

  title : {
    position : "relative",
    cursor : "pointer",

    "&:before" : {
      content : '"‹"',
      position : "absolute",
      right : -24,
      top : 0,
      fontFamily : "DIN",
      color : theme.palette.grey[50],
      lineHeight : "19px",
      fontSize : 30,
      transform : "rotate(-90deg)",
      transition : `transform 0.6s ${theme.transitions.easing.easeOut}`
    }
  },

  sliderContainer : {
    width : "100%",
    height : "100%",
    marginTop : theme.spacing(5),
    paddingLeft : theme.spacing(3),
    paddingRight : theme.spacing(3),

    "&.slick-slider" : {
      height : "100%",
      width : "100%"
    },

    "& .slick-slide" : {
      display : "flex",
      justifyContent : "center"
    },

    "& .slick-list" : {
      overflow : "visible"
    },

    "& .slick-arrow" : {
      width : theme.spacing(6),
      height : theme.spacing(6),
      display : "flex",
      alignItems : "center",
      justifyContent : "center",

      "&:before" : {
        color : theme.palette.text.grey,
        content : '"‹"',
        fontSize : 40,
        lineHeight : "40px"
      },

      "&.slick-next:before" : {
        content : '"›"'
      }
    }
  },

  itemImage : {
    width : 100,
    cursor : "pointer",
    transition : `transform 1.3s ${theme.transitions.easing.easeOut}`,

    "&:hover" : {
      transform : "scale(1.3)"
    }
  },

  itemLabel : {
    marginTop : theme.spacing(2)
  }
});

const sliderSettings = {
  // accessibility: false,
  draggable : false,
  infinite : true,
  slidesToShow : 6,
  slidesToScroll : 6,
  dots : true
};


function BottomSelectDrawer({ classes, isOpen, onClose, contentItem }) {
  const { t } = useTranslate();

  if (!contentItem || !contentItem.title || !contentItem.items) {
    return null;
  }

  function onDesignClick(item) {
    contentItem.onClick(item);
  }

  return (
    <Drawer variant="temporary" anchor="bottom" open={isOpen} onClose={onClose} transitionDuration={600}>
      <div className={classes.container}>
        <Grid container direction="column" alignItems="center" justifyContent="flex-start" className={classes.content}>
          <Typography variant="h2" className={classes.title} onClick={onClose}>
            {t(contentItem.title)}
          </Typography>
          <Grid container className={classes.sliderContainer}>
            <Grid item md={12} xs={12}>
              <Slider {...sliderSettings} infinite={contentItem.items.length > sliderSettings.slidesToShow}>
                {contentItem.items.map(_item => (
                  <button type="button" key={_item.code} onClick={() => onDesignClick(_item)}>
                    <Grid container className={classes.itemContainer} direction="column" alignItems="center" justifyContent="space-between">
                      <img
                        src={contentItem.getItemImage(_item)}
                        className={classes.itemImage}
                        alt={t(_item.label || _item.name || _item.title)}
                      />
                      {!!_item.label && (
                        <Typography align="center" className={classes.itemLabel}>
                          {_item.label}
                        </Typography>
                      )}
                    </Grid>
                  </button>
                ))}
              </Slider>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Drawer>
  );
}


export default wrapper({ styles }, BottomSelectDrawer);
