import { Tooltip } from "@material-ui/core";
import React from "react";
import wrapper from "../utils/wrapper";


const styles = theme => ({
  container : {
    width : "100%",
    display : "flex",
    alignItems : "center",
    justifyContent : "center"
  },

  color : {
    width : 10,
    height : 14,
    cursor : "pointer",
    border : "none",

    "&:last-child" : {
      borderTopRightRadius : 100,
      borderBottomRightRadius : 100
    },

    "&:first-child" : {
      borderTopLeftRadius : 100,
      borderBottomLeftRadius : 100
    },

    "&:hover" : {
      transform : "scale(2)",
      borderRadius : 10,
      transition : `transform 0.5s ${theme.transitions.easing.easeOut}`,
      zIndex : 200
    }

  }
});


function ColorsPicker({ classes, colors = [], onChange }) {

  return (
    <div className={classes.container}>
      {
        colors.map(({ code, label }) => (
          <Tooltip title={label} key={code}>
            <button
              type="button"
              aria-label={label}
              className={classes.color}
              style={{ backgroundColor: code }}
              onClick={() => onChange({ target: { value: code } })}
            />
          </Tooltip>
          ))
      }
    </div>
  );
}


export default wrapper({ styles }, ColorsPicker);
