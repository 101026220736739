/* eslint-disable complexity */
import { ThemeProvider } from "@material-ui/core/styles";
import axios from "axios";
import colorConvert from "color-convert";
import FontFaceObserver from "fontfaceobserver";
import paper from "paper";
import queryString from "query-string";
import React from "react";
import ReactDOM from "react-dom";
import urlJoin from "url-join";
import NoPlayerTextWarningModal from "../components/NoPlayerTextWarningModal";
import ProjectTitlePromptModal from "../components/ProjectTitlePromptModal";
import createObjectId from "../utils/createObjectId";
import deepDiff from "../utils/deepDiff";
import useTranslate from "./useTranslate";


/**
 * This class handle most of the customization logic, like
 * manipulating the canvas, etc.
 */
class Customizer {

  constructor({
    // The main config
    appConfig,
    // The used theme config
    theme,
    // Triggered when the viewer is updated
    onUpdate,
    // Triggered on fatal error
    onFatalError,
    // Triggered on non-fatal error
    onError,
    // Triggered to show a loading modal
    onLoading,
    // Triggered when the viewer is loading (inner loading)
    onProcessing,
    // Triggered when an item is selected on the viewer
    onViewerItemSelected,
    // Triggered when the app is initialized
    onLoadingProgress,
    // Triggered when the product info changes (mainly the price)
    onProductInfoChange
  }) {
    this.paper = paper;
    this.appConfig = appConfig;
    this.theme = theme;
    this.canvas = document.getElementById(Customizer.canvasID);
    this.textCanvas = document.getElementById(Customizer.textCanvasID);
    this.patternCanvas = document.getElementById(Customizer.patternCanvasID);
    this.mainScope = new paper.PaperScope();
    this.textScope = new paper.PaperScope();
    this.isProjectSaved = false;
    this.t = useTranslate().t; // This is not a real hook, so it can be used here
    this.callbacks = {
      onUpdate,
      onFatalError,
      onError,
      onLoading,
      onProcessing,
      onViewerItemSelected,
      onLoadingProgress,
      onProductInfoChange
    };

    this.mainScope.setup(this.canvas);
    this.textScope.setup(this.textCanvas);
    this.mainScope.activate();

    const parsedQuery = queryString.parse(window.location.search);

    this.design = parsedQuery.design || "Design01";
    this.productId = window.location.pathname.split("/").pop() || parsedQuery.productId;

    if (process.env.NODE_ENV === "development") {
      if (!this.productId) {
        this.productId = "3";
      }
      window.exportCustomizerSVG = this.exportToSVG.bind(this);
      window.saveCustomization = this.saveCustomization.bind(this);
    }
    window.customizer = this;
  }


  /**
   * Initialize the customizer
   * - Get the product initial customization
   * - Init the 3D Viewer
   * - Initialize a canvas for the SVG manipulations
   *
   * @returns {Promise<void>}
   */
  async init() {
    console.log("ⓘ Initializing the customizer");
    const onLoadingProgress = typeof this.callbacks.onLoadingProgress === "function"
      ? this.callbacks.onLoadingProgress
      : () => {
      };
    try {
      onLoadingProgress(0, "Récupération de la sauvegarde et des informations du produit");
      await this._initProductInfos();
      onLoadingProgress(10, "Initialisation du canvas");
      await this._initCanvas();
      onLoadingProgress(30, "Création de la configuration de l'utilisateur");
      await this._initCustomization();
      onLoadingProgress(40, "Initialisation du viewer 3D");
      await this._init3DViewer();
      onLoadingProgress(50, "Récupération de la sauvegarde temporaire (changement de design)");
      await this._initLocalStorageCustomization();
      onLoadingProgress(70, "Injection des couleurs et patterns par défaut (si définis)");
      await this._initCustomizationColors();
      await this._initCustomizationPatterns();
      onLoadingProgress(90, "Initialisation des événements du viewer");
      this._initListeners();
      onLoadingProgress(100);
      this.getUserProjectsList().catch(() => {}); // Fetch user projects a first time
      // this._initPageUnloadWarning(); This feature has been disabled

      // After having initialized the project we can consider that it is saved while no changes
      // have been made by the user at this time
      this.isProjectSaved = true;
    } catch (err) {
      if (!err.message.includes("Code:")) {
        this._handleFatalError("ERR_INIT", err);
      }
      throw err;
    }
  }


  /**
   * Gets a new customization object as parameter, updates the viewer and returns
   * the new customization object
   *
   * @param {object} customization
   * @param {Function} cb
   * @param {boolean} shouldThrow
   * @param {boolean} merge
   * @returns {?Promise<{}|*>}
   */
  async update(customization = this.getCustomization(), cb, shouldThrow = true, merge = true) {
    const mergedCustomization = merge
      ? deepDiff(customization, this.getCustomization())
      : customization;

    try {
      let errors;
      if (Object.keys(mergedCustomization)?.length && this.showcase) {
        let updateRes;

        // This is not good, but sometimes showcase.update won't resolve, so we need to add a timeout somewhere
        // TODO Remove this when the bug is fixed on the Hapticmedia side
        setTimeout(() => {
          if (updateRes || typeof cb !== "function") {
            return;
          }

          cb(false, errors);
        }, 5000);

        updateRes = await this.showcase.update(mergedCustomization);

        if (updateRes?.errors) { errors = updateRes.errors; }
      } else if (this.viewer) {
        this.viewer.emit("final-design-changed");
      }

      const nextCustomization = this.getCustomization();

      if (process.env.NODE_ENV === "development") {
        console.groupCollapsed("🦄 Viewer updated");
        console.log("diff", mergedCustomization);
        console.log("full", nextCustomization);
        console.log("errors", errors);
        console.groupEnd();
      }

      // Handle errors
      if (errors && errors[0]) {

        nextCustomization.error = errors[0].type;

        // Store errors
        errors.forEach(_error => {
          const relatedField = this._resolveDataFromMapPath(_error.field, nextCustomization);

          if (relatedField && typeof relatedField === "object") {
            relatedField.error = _error.type;
          }
        });

        if (process.env.NODE_ENV === "development") {
          console.error("Update errors", errors);
        }

      } else {
        nextCustomization.error = undefined;
      }

      if (shouldThrow && typeof this.callbacks.onUpdate === "function") {
        this.callbacks.onUpdate(nextCustomization);
      }

      if (typeof cb === "function") {
        cb(!errors || !errors.length, errors);
      }

      if (this.appConfig.dynamicPrice === true) {
        this.getCustomizationPrice()
          .catch(console.error);
      }

      this.isProjectSaved = false;

      return nextCustomization;
    } catch (err) {
      if (process.env.NODE_ENV === "development") {
        console.error("Apviz Error:", err);
      }
    }

    return null;
  }


  /**
   * Save the user customization on the server
   *
   * @param {boolean} composeTeam
   */
  async saveCustomization(composeTeam = false) {
    try {
      // Check if the project title is defined or ask the user to choose one
      const projectSavingData = await this._displayProjectTitlePromptModal();

      if (!projectSavingData) {
        return false;
      }

      this.projectTitle = projectSavingData.title;

      if (!this.projectTitle) {
        return false;
      }

      const customization = this.getCustomization();

      if (composeTeam) {
        // If no texts has been defined, ensure that it is not a mistake or an omission by showing a confirmation modal to the user
        if (!customization.custom || !customization.custom.texts || Object.keys(customization.custom.texts).length === 0) {
          const shouldContinue = await this._displayNoPlayerTextWarningModal();

          if (!shouldContinue) {
            return false;
          }
        } else { // Do the same thing if no player name/number has been defined
          const hasPlayerNameText = Object.values(customization.custom.texts).some(_text => _text.usage === "name");
          const hasPlayerNumberText = Object.values(customization.custom.texts).some(_text => _text.usage === "number");

          if (!hasPlayerNameText || !hasPlayerNumberText) {
            const shouldContinue = await this._displayNoPlayerTextWarningModal();

            if (!shouldContinue) {
              return false;
            }
          }
        }
      }

      if (typeof this.callbacks.onLoading === "function") {
        this.callbacks.onLoading(this.t("SENTENCES.SAVING_YOUR_PROJECT"));
      }

      // Take care of converting URLs images into base64 and vice versa
      await this._populateCustomizationLogosBase64andUrl(customization);

      const svgSource = this.exportToString();
      const svgURL = await this._uploadAPIFile("svg", svgSource);
      const captures = await this.captureViewer();

      // For retro-compatibility, remove the eventual svgSource of some
      // old user customizations that remains on the server
      if (customization.svgSource) {
        delete customization.svgSource;
      }

      const projectId = projectSavingData.id; // || (this.userIsOwner ? this.projectId : null);
      const design = this.appConfig.customizerPresets.designs.availableDesigns?.[this.productType]?.find(_design => _design.code === this.design);
      const designLabel = design?.label || design?.code || this.design;

      // Ensure that no base64 will be stored in the dump
      Object.values(customization.custom.logos || {})
        .forEach(_logo => {
          _logo.image = _logo.imageUrl;
        });
      Object.values(customization.custom.texts || {})
        .forEach(_text => {
          _text.image = _text.imageUrl;
        });

      // This is how the final project dump looks like
      const save = {
        product_source_id : this.productId,
        project_id : projectId,
        compose_team : composeTeam,
        title : this.projectTitle,
        configuration : JSON.stringify({
          ...customization,
          design : this.design,
          designLabel,
          custom : {
            ...customization.custom,
            design : {
              ...customization.custom.design,
              dynamicDrawing : undefined // We won't store this
            }
          },
          svgURL,
          captures
        })
      };

      // Save the project on the server
      const { data } = await axios.post(`${this.appConfig.api.baseURL}${this.appConfig.api.endpoints.saveProject}`, save);

      if (data?.response?.result) {
        const { result } = data.response;

        // Redirect the user to the "compose team" page if needed
        if (composeTeam && result.compose_team_url) {
          document.location.pathname = result.compose_team_url;

          // When the user creates a new project for the first time we should replace the URL product_id by the project_id
        } else if (!this.projectId && !document.location.pathname.includes(`/${this.projectId}`)) {
          window.history.pushState(save.title,
            save.title,
            document.location.pathname.replace(`/${this.productId}`,
              `/${result.project_id}`) + document.location.search);
          this.userIsOwner = true;

          // When the user have made a save from another user project, we should update the URL project_id with the new project_id
        } else if (!this.userIsOwner && document.location.pathname.includes(`/${this.projectId}`)) {
          window.history.pushState(save.title,
            save.title,
            document.location.pathname.replace(`/${this.projectId}`,
              `/${result.project_id}`) + document.location.search);
          this.userIsOwner = true;

          // When the user overwrite an existing project that belongs to him, we should update the current URL project_id
        } else if (this.projectId && document.location.pathname.includes(`/${this.projectId}`) && !!projectSavingData.id) {
          window.history.pushState(save.title,
            save.title,
            document.location.pathname.replace(`/${this.projectId}`,
              `/${projectSavingData.id}`) + document.location.search);

          // When the user creates a new project from one of his existing projects, we should also update the URL project_id
        } else if (document.location.pathname.includes(`/${this.projectId}`) && !projectSavingData.id) {
          window.history.pushState(save.title,
            save.title,
            document.location.pathname.replace(`/${this.projectId}`,
              `/${result.project_id}`) + document.location.search);
          this.userIsOwner = true;
        }

        this.projectId = result.project_id;

        if (process.env.NODE_ENV === "development") {
          console.groupCollapsed("ⓘ customization saved");
          console.log(save);
          console.groupEnd();
        }
        this.isProjectSaved = true;

        if (typeof this.callbacks.onLoading === "function") {
          this.callbacks.onLoading();
        }

        if (!this.userProjects) {
          this.userProjects = [];
        }

        // Push this project to the user projects list
        this.userProjects.push({ title: save.title, id: this.projectId, thumb: captures.front });
        return true;
      }
    } catch (err) {
      if (typeof this.callbacks.onLoading === "function") {
        this.callbacks.onLoading();
      }
      if (process.env.NODE_ENV !== "production") {
        console.log(err);
      }
      this._handleError(this.t("ERRORS.PROJECT_SAVING"));
    }
    return false;
  }


  /**
   * Get an object containing the customization colors configuration
   *
   * @returns {?object} colors
   */
  getCustomizationColors() {
    const colors = {};

    if (!this.availableColorZones) {
      return null;
    }

    this.availableColorZones.forEach(_colorZone => {
      const zone = this.appConfig.customizerPresets.colors.availableZones.find(_zone => _zone.id === _colorZone.name.split("_")[0]);

      if (zone) {
        const color = zone.availableColors?.find(_color => _color.code?.toLowerCase() === _colorZone.value?.toLowerCase());
        const zoneId = _colorZone.name.split("_")[0];
        const zoneName = this.t((this.appConfig.formVariants.colors.settings.colorZones.find(_zone => _zone.id === zoneId))?.title || zoneId);
        const colorName = color?.label ? color.label : _colorZone.value;
        const originalColor = this.originalColors?.[_colorZone.name];

        if (color) {
          colors[zoneId] = {
            zoneId,
            zoneName,
            colorName,
            colorCode : color?.code,
            colorId : color?.id,
            isCustomColor : !!color?.code && !!originalColor && originalColor.toLocaleString?.() !== color.code.toLowerCase?.()
          };
        }

        // Keep this for retro-compatibility (this should not be internally used)
        colors[zoneName] = color?.label ? color.label : _colorZone.value;
      }
    });

    return colors;
  }


  /**
   * Capture screens of the viewer, upload them on the server and return
   * the corresponding URLs
   * The snapshots dimension can be adjusted in the app config
   */
  async captureViewer() {
    return {
      front : await this._uploadAPIFile("preview", (await this.viewer.screenshot({
        viewpoint : "View_BAT_Front",
        imageType : "png",
        exportType : "base64",
        width : this.appConfig.viewer.captureSnapshotWidth
      })).base64Image),
      back : await this._uploadAPIFile("preview", (await this.viewer.screenshot({
        viewpoint : "View_BAT_Back",
        imageType : "png",
        exportType : "base64",
        width : this.appConfig.viewer.captureSnapshotWidth
      })).base64Image),
      left : await this._uploadAPIFile("preview", (await this.viewer.screenshot({
        viewpoint : "View_BAT_Left",
        imageType : "png",
        exportType : "base64",
        width : this.appConfig.viewer.captureSnapshotWidth
      })).base64Image),
      right : await this._uploadAPIFile("preview", (await this.viewer.screenshot({
        viewpoint : "View_BAT_Right",
        imageType : "png",
        exportType : "base64",
        width : this.appConfig.viewer.captureSnapshotWidth
      })).base64Image)
    };
  }


  /**
   * Get a list of all the SVG paths that are considered has a colorZone
   * These paths are identified by there name that should contain "color-zone"
   *
   * @param {boolean} onlyPatternCompatibleZones
   * @returns {{name: string, value: paper.Color}[]|Array}
   */
  getAvailableColorZones(onlyPatternCompatibleZones = false) {
    if (!this.designGroupSVGItem) { return []; }
    this.availableColorZones = this.designGroupSVGItem.getItems({
      recursive : true,
      match : e => (
        !!e.name && e.name.indexOf("color-zone") === 0
        && !!e.fillColor
        && ["path", "compoundpath"].includes(e.className.toLowerCase())
      )
    })
      .map(_item => ({
        name : _item.name,
        value : _item.fillColor.toCSS(true),
        item : _item
      }));

    if (onlyPatternCompatibleZones) {
      return this.availableColorZones // Filter excluded paths (an excluded path contains a custom metadata that we can retrieve in the original svg)
        .filter(_item => !this.parsedSVG.getElementById(_item.name)?.querySelector('odm[name="pattern"][value="false"]'))
        .filter(_item => !_item.name.includes("pattern-false"));
    }

    return this.availableColorZones;
  }


  /**
   * Get the hex value of a colorZone from its name (ex 'color-zone0')
   *
   * @param {string} zoneName
   * @returns {*}
   */
  getColorCodeFromZoneName(zoneName) {
    const matchingColor = this.getAvailableColorZones()
      .find(_zone => _zone.name === zoneName || _zone.name.split("_")[0] === zoneName);

    return matchingColor?.value;
  }


  /**
   * Apply a new color on all the SVG paths that match
   * a given zoneName and update the viewer
   *
   * @param {string} zoneName
   * @param {string} color
   * @param {boolean} shouldUpdate
   */
  updateColorZone(zoneName, color, shouldUpdate = true) {
    const modifiedItems = this.designGroupSVGItem.getItems({
      recursive : true,
      match : e => (
        !!e.name && e.name.split("_")[0] === zoneName
        && !!e.fillColor
        && ["group", "path", "compoundpath"].includes(e.className.toLowerCase())
      )
    })
      .map(_item => {
        const { alpha } = _item.fillColor; // Take care of keeping the fill alpha
        _item.fillColor = color;
        _item.fillColor.alpha = alpha;
        return _item;
      });

    const { colorZoneNameToBeUsedAsTextColor } = this.appConfig.formVariants.texts.settings;
    if (!!colorZoneNameToBeUsedAsTextColor && colorZoneNameToBeUsedAsTextColor === zoneName) {
      this._updateAllTextsColor(color);
    }
    if (shouldUpdate && modifiedItems && modifiedItems.length) {
      // Without this timeout, the colors may not be updated correctly
      setTimeout(() => this.update(), 50);
    }
  }


  /**
   * Apply a pattern to a colorZone
   *
   * @param {string} zoneName
   * @param {object} pattern
   * @returns {Promise<void>}
   */
  async setColorZonePattern(zoneName, pattern) {
    if (!pattern || !zoneName) { return; }

    let patternToApply = pattern;

    const patternConfig = this.appConfig.customizerPresets.patterns.availablePatterns
      .find(_pattern => _pattern.id === patternToApply.id);

    // Hydrate the pattern with the default patterns config to be sure that all required
    // fields are defined
    patternToApply = {
      ...this.appConfig.customizerPresets.patterns.defaultPatternConfig,
      ...patternToApply
    };

    patternToApply.colorName = (this.appConfig.customizerPresets.patterns.availableColors
      .find(_color => _color.code === patternToApply.fillColor))?.label;

    // If the given logo url isn't a string but a URL, we should first ensure to call this function
    // to convert the url into a valid svg string.
    const svgStringFile = await this._getSvgStringFileFromUrl(patternConfig.svgStringFile);

    // Create a parser and parse the svg
    const parser = new DOMParser();
    const parsedSVG = parser.parseFromString(svgStringFile, "image/svg+xml");

    // Update the svg xml to apply the fill color and opacity before converting it into base64
    Array.prototype.forEach.call(parsedSVG.querySelectorAll("path[fill],polygon[fill]"), svgPath => {
      svgPath.setAttribute("fill", patternToApply.fillColor);
    });

    // Convert the svg to base64
    const base64Svg = `data:image/svg+xml;base64,${btoa(new XMLSerializer().serializeToString(parsedSVG))}`;
    const canvas = this.patternCanvas;
    const ctx = canvas.getContext("2d");
    const img = new Image();

    // Load the pattern as an image
    img.src = base64Svg;
    img.addEventListener("load", async () => {
      // Create a temporary canvas in order to be able to resize the loaded image
      const tempCanvas = document.createElement("canvas");
      const tCtx = tempCanvas.getContext("2d");

      // Define the size of a pattern block inside the temporary canvas
      const patternWidth = this.appConfig.formVariants.patterns.settings.defaultPatternSizeInCanvas * patternToApply.scale;
      const patternHeight = (patternWidth * img.height) / img.width;
      tempCanvas.width = patternWidth;
      tempCanvas.height = patternHeight;

      // Draw the temporary canvas image with the required size
      tCtx.drawImage(img, 0, 0, img.width, img.height, 0, 0, patternWidth, patternHeight);

      // Fill the first canvas with a pattern created from the temporary canvas
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.fillStyle = ctx.createPattern(tempCanvas, "repeat");
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      // Remove the potential existing patterns for the color-zone
      this.designGroupSVGItem.getItems({
        recursive : true,
        match : e => (
          !!e.name && ((e.name.split("_")[0] === zoneName && e.name.indexOf("-clip") > 0) || e.name === `${zoneName}-clip`)
          && ["group"].includes(e.className.toLowerCase())
        )
      })
        .forEach(_item => {
          _item.remove();
        });

      // Get the related design items
      this.designGroupSVGItem.getItems({
        recursive : true,
        match : e => (
          !!e.name && e.name.split("_")[0] === zoneName
          && !!e.fillColor
          && ["group", "path", "compoundpath"].includes(e.className.toLowerCase())
        )
      })
        // Filter excluded paths (an excluded path contains a custom metadata that we can retrieve in the original svg)
        .filter(_item => {
          const itemId = _item.name.includes("_group")
            ? _item.name.replace("_group", "")
            : _item.name;

          return !itemId.includes("pattern-false")
            && !this.parsedSVG.getElementById(itemId)?.querySelector('odm[name="pattern"][value="false"]');
        })
        .forEach(_item => {
          // Create a raster from the pattern canvas
          const rasterImg = document.createElement("img");

          rasterImg.src = canvas.toDataURL("image/png");
          rasterImg.addEventListener("load", () => {
            const raster = new paper.Raster(rasterImg, new paper.Point(_item.bounds.x, _item.bounds.y));

            raster.fitBounds(_item.bounds, true);
            // Set pattern rotation
            raster.rotate(patternToApply.rotation || 0);

            // Create a clone of the color-zone that will be used to clip the pattern
            const itemCopy = _item.clone();

            itemCopy.clipMask = true;

            // Store the raster and the color-zone clone in a group
            const patternGroup = new paper.Group([itemCopy, raster]);

            // Give a recognizable name to the group in order to be able to update it if needed
            patternGroup.name = `${_item.name}-clip`;
            patternGroup.opacity = patternToApply.opacity;
            patternGroup.insertAbove(_item);
          });
        });

      // There is an embarrassing bug with PaperJs that updates the canvas in a differed manner
      // It means that we must run this whole process two times in order to see the changes.
      // Otherwise, each call of this method will update the canvas with the previous changes...
      // TODO Find a clean way to resolve this issue (or create an issue on Github, but this bug is hard to define)

      await new Promise(resolve => {
        // Update and store the pattern configuration
        this.update({
          custom : {
            patterns : {
              [zoneName] : patternToApply
            }
          }
        })
          .then(() => {
            setTimeout(() => {
              // Manually emit the final-design-changed to update the viewer
              this.viewer.emit("final-design-changed");

              resolve();
            }, 50);
          });
      });

    });
  }


  /**
   * Remove all the patterns of a given colorZone
   *
   * @param {string} zoneName
   */
  removeColorZonePattern(zoneName) {
    const items = this.designGroupSVGItem.getItems({
      recursive : true,
      match : e => (
        !!e.name && ((e.name.split("_")[0] === zoneName && e.name.indexOf("-clip") > 0) || e.name === `${zoneName}-clip`)
        && ["group"].includes(e.className.toLowerCase())
      )
    });

    items
      .forEach(_item => {
        _item.opacity = 0;
      });

    setTimeout(() => {
      items
        .forEach(_item => {
          _item.remove();
        });
      this.viewer.emit("final-design-changed");
    }, 200);
  }


  /**
   * Update a brand logo color and/or location
   *
   * @param {string} logoId
   * @param {string} color
   * @param {string} location
   */
  async updateBrandLogo(logoId, color, location) {
    if (!this.appConfig.customizerPresets.brandLogos?.availableLogos?.length) {
      return;
    }

    const customization = this.getCustomization();
    const logoPresets = this.appConfig.customizerPresets.brandLogos?.availableLogos?.find(_logo => _logo.id === logoId);

    // Do nothing if the logo is not defined
    if (!logoPresets || !customization.custom?.logos?.[logoId]) {
      return;
    }

    let { image } = customization.custom.logos[logoId];

    // Change the color if a new color is defined
    if (color) {
      const parser = new DOMParser();

      // If the given logo url isn't a string but a URL, we should first ensure to call this function
      // to convert the url into a valid svg string.
      const svgStringFile = await this._getSvgStringFileFromUrl(logoPresets.svgStringFile);

      const svgEl = parser.parseFromString(decodeURI(svgStringFile), "image/svg+xml");

      // Change the fill value of all the defined paths in the svg and generate a new base64 image from the result
      Array.prototype.forEach.call(svgEl.getElementsByTagName("path"), _path => _path.setAttribute("fill", color));
      image = await this._svgToBase64(svgEl);
    }

    const colorName = (logoPresets.availableColors.find(_color => _color.code === color))?.label;

    // Update the logo
    await this.update({
      custom : {
        logos : {
          [logoId] : {
            location : location || customization.custom.logos[logoId].location,
            image,
            // It is mandatory to clear `imageUrl` if color changes so the new logo version gets uploaded to server
            ...(color ? { imageUrl: null } : {}),
            color,
            colorName
          }
        }
      }
    });
  }


  /**
   * Generate a text in a canvas and return the result in a base64 format
   *
   * @param {string} text
   * @param {object} textConfig
   * @returns {?string} base64Image
   */
  async getBase64FromText(text = "", textConfig = {}) {
    try {
      // Load the font
      await new FontFaceObserver(textConfig.fontFamily).load();

      this.textScope.setup(this.textCanvas);
      this.textScope.activate();

      this.textScope.project.view.viewSize = new paper.Size(2048, 1024);

      const fontSize = (textConfig.fontSize || 45) * 8;

      // Create the paper pointText element
      const pointText = new paper.PointText({
        position : this.textScope.project.view.center,
        justification : "center",
        content : text,
        fillColor : textConfig.fillColor || "#111",
        strokeColor : textConfig.strokeColor || "transparent",
        strokeWidth : textConfig.strokeWidth || 0,
        fontFamily : textConfig.fontFamily,
        fontSize,
        // leading: fontSize,
        name : "text"
      });

      this.textScope.project.view.viewSize.width = pointText.bounds.width;
      pointText.position.x = pointText.bounds.width / 2;

      // Detect the first and last non-empty pixels of the canvas to crop it from its content size
      const canvas = document.getElementById(Customizer.textCanvasID);
      const borderPixels = await new Promise(res => {
        setTimeout(() => {
          res(this._getCanvasFirstAndLastPixelsCoordinates(canvas));
        }, 100);
      });
      const firstPixelRealY = (borderPixels.first.y * this.textScope.project.view.viewSize.height) / canvas.height;
      const lastPixelRealY = (borderPixels.last.y * this.textScope.project.view.viewSize.height) / canvas.height;

      pointText.position.y -= firstPixelRealY;
      this.textScope.project.view.viewSize.height = lastPixelRealY > firstPixelRealY
        ? lastPixelRealY - firstPixelRealY
        : firstPixelRealY - lastPixelRealY;

      /* USE THIS FOR DEBUG */
      // setTimeout(() => {
      // if (document.getElementById('canvas-text-debug')) {
      // document.getElementById('canvas-text-debug').remove();
      // }
      // const img = document.createElement('img');
      // img.id    = 'canvas-text-debug';
      // img.src   = this.textCanvas.toDataURL('image/png');
      // img.style = 'border:1px solid red;position:absolute;pointer-events:none';
      // document.body.appendChild(img);
      // }, 200);

      // Turn the image into a matrix
      const pngImage = await new Promise(res => setTimeout(() => res(this.textCanvas.toDataURL("image/xml+svg")),
        200));
      const svgString = new XMLSerializer().serializeToString(this.textScope.project.exportSVG());

      pointText.remove();
      this.mainScope.activate();

      return { pngImage, svgString };
    } catch (err) {
      console.error(err);
    }

    return null;
  }


  /**
   * Get the current customization object
   *
   * @returns {{}}
   */
  getCustomization() {
    this.customization = {
      ...this.baseCustomization,
      ...this.showcase.getCustomization()
    };

    this.customization.custom = this.customization.custom || {};

    // Only update this if the final customization has really changed
    this.customization.custom.design = {
      dynamicDrawing : this.canvas,
      svgFile : this.svgURL
    };

    // Inject the colors into the customization
    // (this is not required for the app to work but needed lately during the printing step)
    const colors = this.getCustomizationColors();
    this.customization.custom.colors = colors || this.customization.custom.colors;

    // Do not return errors here
    this.customization.error = undefined;

    return this.customization;
  }


  /**
   * Change the current viewer viewpoint
   *
   * @param {string} viewpoint
   * @returns {boolean}
   */
  changeViewpoint(viewpoint) {
    if (!this.appConfig.viewer.viewpoints[viewpoint]) {
      return false;
    }

    this.showcase.changeViewpoint(viewpoint);

    return true;
  }


  /**
   * Select an item in the viewer from its ID
   *
   * @param {string|number} itemId
   */
  selectViewerItem(itemId) {
    this.showcase.selectItem(itemId);
  }


  /**
   * Apply the user changes to the original SVG and return it
   * We cannot use paper.project.exportSVG() because the SVG returned by
   * this method do not have the same structure as the original one
   *
   * @param {boolean} exportFullSVG if set to false, only the "design" group will be exported
   * @returns {Document}
   */
  exportToSVG(exportFullSVG = true) {
    const colorZones = this.availableColorZones || this.getAvailableColorZones();
    const { parsedSVG } = this;
    const customization = this.getCustomization();

    /** 1) Update all the colors * */

    colorZones.forEach(_colorZone => {
      const relatedComponent = parsedSVG.getElementById(_colorZone.name);

      // Set the fill attribute on the related component
      if (relatedComponent) {
        relatedComponent.setAttribute("fill", _colorZone.value);

        // Replace the color inside the <style> tag
        // Get the inline style text that match the current colorZone name and replace its fill value
        const styleComponent = parsedSVG.getElementsByTagName("style")?.[0];

        if (styleComponent && relatedComponent.getAttribute("class")) {

          // Don't know if there is a cleaner way to parse/edit css in a svg, but this works just fine
          const regex = new RegExp(`.${relatedComponent.getAttribute("class")}.*{.*}`,
            "gm");
          const match = styleComponent.innerHTML.match(regex);

          if (match && match[0]) {

            // Get all the defined styles rules contained in this style segment
            const newCSSLine = match[0]
              .replace("{", "")
              .replace("}", "")
              .replace(/ /, "")
              .replace(`.${relatedComponent.getAttribute("class")}`, "")
              .trim()
              .split(";")
              .map(_rule => { // For each style rule, check if fill is defined and replace its value
                if (_rule && _rule.includes("fill:")) {
                  return undefined;
                }

                return _rule;
              })
              .filter(Boolean)
              .join(";");

            // Replace the old style line with the new one
            styleComponent.innerHTML = styleComponent.innerHTML.replace(regex,
              `.${relatedComponent.getAttribute("class")} {${newCSSLine}}`);
          }
        }
      }
    });


    /** 2) Remove all the eventual images and texts from the svg * */

    Array.prototype.forEach.call(parsedSVG.querySelectorAll("#design > image"), el => {
      el.remove();
    });

    Array.prototype.forEach.call(parsedSVG.querySelectorAll("#design > text"), el => {
      el.remove();
    });

    /** 3) Insert texts and logos to the exported svg * */

    if (exportFullSVG) {
      const images = [...Object.values(customization.custom.texts || {}), ...Object.values(customization.custom.logos || {})];

      // Sort the images by index (zIndex) and add them to the parsed svg
      if (images.length) {
        images
          .sort((_imageA, _imageB) => {
            if (_imageA?.index < _imageB?.index) { return -1; }
            if (_imageA?.index > _imageB?.index) { return 1; }
            return 0;
          })
          // For texts, it is a better choice to insert a vector element than a matrix image
          .forEach(_image => (_image.svgString
            ? this._insertCustomizationSvgStringInSvgFile(parsedSVG, _image)
            : this._insertCustomizationImageInSvgFile(parsedSVG, _image)));
      }
    } else {
      // Remove everything that do not belong to a design group
      Array.prototype.forEach.call(parsedSVG.querySelectorAll("svg > g"),
        _element => {
          if (_element.id !== "design") {
            _element.remove();
          }
        });
    }

    /** 4) Add the eventual patterns * */

    const paperSvg = this.mainScope.project.exportSVG();
    const paperSvgDesignGroup = paperSvg.querySelector("#design");
    const parsedSvgDesignGroup = parsedSVG.querySelector("#design");
    const paperSvgDefsEl = paperSvg.querySelector("defs");
    const parsedSvgDefsEl = parsedSVG.querySelector("defs");
    const clipGroups = paperSvgDesignGroup.querySelectorAll("[id$=-clip]");

    // Loop over the founded groups with clips
    Array.prototype.forEach.call(clipGroups, _group => {
      const targetedPathId = _group.id.replace("-clip", "");
      const targetedSvgPath = parsedSvgDesignGroup.querySelector(`#${targetedPathId}`);

      // Replace the original svg path with the clip group
      if (targetedSvgPath) {
        targetedSvgPath.parentNode.insertBefore(_group, targetedSvgPath.nextSibling);
      }

      const clipId = _group.getAttribute("clip-path")
        .replace("url(#", "")
        .replace(")", "");
      const clipEl = paperSvgDefsEl.querySelector(`#${clipId}`);

      // Insert the clipMask in the exported svg
      parsedSvgDefsEl.append(clipEl);
    });


    return parsedSVG;
  }


  /**
   * Export the svg as a string
   *
   * @returns {string}
   */
  exportToString() {
    const svgEl = this.exportToSVG(true);
    const serializedSvg = new XMLSerializer().serializeToString(svgEl);

    return decodeURI(serializedSvg)
      .replace(/↵/g, "")
      .replace(/&quot;/g, '"')
      .replace(/&apos;/g, "'")
      .replace(/&amp;/g, "")
      .replace(/amp;/g, "")
      .replace(/gt;/g, ">")
      .replace(/lt;/g, "<");
  }


  /**
   * Return the final Image to base64
   *
   * @param {boolean} exportFullSVG
   * @returns {string}
   */
  exportToBase64(exportFullSVG = true) {
    return `data:image/svg+xml;base64,${ window.btoa(new XMLSerializer().serializeToString(this.exportToSVG(exportFullSVG)))}`;
  }


  /**
   * Store the customization to the local storage
   * This is used when a user changes the design/variant while we need to refresh
   * the whole page without losing his current customization
   *
   * @returns {Promise<void>}
   */
  async addCustomizationToLocalStorage() {
    const customization = this.getCustomization();
    const colorZones = this.getAvailableColorZones();

    await this._populateCustomizationLogosBase64andUrl(customization, true);


    localStorage.setItem("tmp_conf", JSON.stringify({
      custom : Object.assign(customization.custom, { design: undefined }),
      colors : colorZones
        .map(({ name, value }) => ({ name: name.split("_")[0], value }))
        .filter((_zone, index) => colorZones.findIndex(_z => _z.name.split("_")[0] === _zone.name) >= index)
    }));
  }


  /**
   * Fetch the user projects list
   */
  async getUserProjectsList() {
    if (this.userProjects) { return this.userProjects; }

    try {
      const { data } = await axios.post(`${this.appConfig.api.baseURL}${this.appConfig.api.endpoints.getUserProjects}`);

      if (data?.response?.result) {
        this.userProjects = data.response.result;
        return data.response.result;
      }
    } catch (err) {
      console.error(err);
    }

    return [];
  }


  /**
   * While the colors stored in the customization is not really usable as is,
   * this method can be used to convert them into a more readable object, using the color zones
   * ids and the colors hex code
   * Note: this function is still used for retro compatibility. New projects don't use the same colors structure anymore
   *
   * @param {{}} customColors
   * @returns {{}}
   */
  getColorsMapFromCustomizationColors(customColors = {}) {
    const colors = {};

    Object.entries(customColors)
      .forEach(([_colorZoneTitle, _colorName]) => {

        // First get the zone form config...
        const zoneConfig = this.appConfig.formVariants.colors.settings.colorZones
          .find(_colorZone => this.t(_colorZone.title) === this.t(_colorZoneTitle) || _colorZone.id === _colorZoneTitle
            .split("_")[0]);

        if (!!zoneConfig && !!zoneConfig.id) {

          // ... then the color zone config...
          const colorZoneConfig = this.appConfig.customizerPresets.colors.availableZones
            .find(_colorZone => _colorZone.id === zoneConfig.id);

          if (!!colorZoneConfig && !!colorZoneConfig.availableColors) {

            // ... and finally the color config
            const colorConfig = colorZoneConfig.availableColors
              .find(_color => _color.label === _colorName || _color.code.toLowerCase() === _colorName.toLowerCase());

            if (!!colorConfig && !!colorConfig.code) {
              colors[zoneConfig.id] = colorConfig.code;
            }
          }
        }
      });

    return colors;
  }


  /**
   * Get the price of the current customization from the API (not necessary used on every project)
   *
   * @returns {Promise<void>}
   */
  async getCustomizationPrice() {
    const customization = { ...this.getCustomization() };

    customization.custom = { ...customization.custom };
    customization.custom.logos = { ...(customization.custom.logos || {}) };
    customization.custom.texts = { ...(customization.custom.texts || {}) };

    Object.keys(customization.custom?.logos || {})
      .forEach(logoId => {
        customization.custom.logos[logoId] = { ...customization.custom.logos[logoId] };
        customization.custom.logos[logoId].image = null;
      });
    Object.keys(customization.custom?.texts || {})
      .forEach(textId => {
        customization.custom.texts[textId] = { ...customization.custom.texts[textId] };
        customization.custom.texts[textId].image = null;
        customization.custom.texts[textId].svgString = null;
      });

    delete customization.custom.design;

    try {
      const { data } = await axios.post(
        `${this.appConfig.api.baseURL}${this.appConfig.api.endpoints.getCustomizationPrice}`,
        {
          product_id : this.productId,
          configuration : customization
        }
      );

      if (!data?.response?.result?.price) { return; }

      // This should probably have been done on the server side...
      this.productInfos = { ...this.productInfos };
      this.productInfos.price = parseFloat(String(data.response.result.min_price || "")
        .replace("€", ""));
      this.productInfos.formattedPrice = data.response.result.formatted_price;
      this.productInfos.minPrice = parseFloat(String(data.response.result.min_price || "")
        .replace("€", ""));
      this.productInfos.maxPrice = parseFloat(String(data.response.result.max_price || "")
        .replace("€", ""));

      if (typeof this.callbacks.onProductInfoChange === "function") {
        this.callbacks.onProductInfoChange(this.productInfos);
      }
    } catch (err) {
      console.error(err);
    }
  }


  /**
   * Allow knowing if we should make a save of the project (if changes has been made after the last save)
   *
   * @returns {boolean}
   */
  shouldProjectBeSaved() {
    return !this.isProjectSaved;
  }


  /**
   * This method is used each time a new logo or text is added or when their position changes
   * It will first check if the overlaying of two images is allowed from the config file. If not, and the
   * new image is superimposed on another image, the user will be asked to either keep the old image and
   * replace it with the new one, or to cancel the action
   *
   * @param {string} newImageLocation
   */
  async imageCanBeOverlaid(newImageLocation) {
    // 1. Check if the feature is enabled
    if (!this.appConfig.replaceOverlapping) { return true; }

    // 2. Get potential existing logo or text on the requested location
    const customization = this.getCustomization();
    const currentLogoOnThisLocation = Object.entries(customization.custom.logos || {})
      .find(_image => _image[1].location === newImageLocation);
    const currentTextOnThisLocation = Object.entries(customization.custom.texts || {})
      .find(_image => _image[1].location === newImageLocation);

    // 3. If no logo/text exists, we are good
    if (!currentLogoOnThisLocation && !currentTextOnThisLocation) { return true; }

    // 4. Ask the user if he really want to replace the current logo/text on this location
    // eslint-disable-next-line no-alert
    const userAnswer = window.confirm(
      this.t("SENTENCES.LOGO_REPLACEMENT_WARNING")
);

    if (!userAnswer) { return false; }

    // 5. Remove the current logo/text
    const itemToRemoveId = (currentLogoOnThisLocation || currentTextOnThisLocation)?.[0];
    const itemToRemoveType = currentLogoOnThisLocation ? "logos" : "texts";

    if (!itemToRemoveType || !itemToRemoveId) { return true; }

    await this.update({
      custom : {
        [itemToRemoveType] : {
          [itemToRemoveId] : null
        }
      }
    });

    return true;
  }


  /**
   * Upload an image to the server
   *
   * @param {string} type (logo|preview|svg)
   * @param {{}|*} source
   * @returns {Promise<*>}
   * @private
   */
  async _uploadAPIFile(type = "logo", source) {
    const { data } = await axios.post(`${this.appConfig.api.baseURL}${this.appConfig.api.endpoints.uploadFile}`,
      {
        type,
        data : source
      });

    return data?.response?.result?.path;
  }


  /**
   * Open a modal with a prompt that allows the user to define a
   * custom name for its project
   *
   * @returns {Promise<unknown>}
   * @private
   */
  _displayProjectTitlePromptModal() {
    return new Promise(resolve => {
      const body = document.getElementsByTagName("body")[0];
      const div = document.createElement("div");

      div.setAttribute("id", "title-prompt-container");
      body.append(div);


      const onSubmit = res => {
        div.remove();
        resolve(res);
      };

      ReactDOM.render(
        <ThemeProvider theme={this.theme}>
          <ProjectTitlePromptModal onSubmit={onSubmit} customizer={this} currentProjectId={this.projectId} />
        </ThemeProvider>,
        div
      );
    });
  }


  /**
   * Open a modal to inform the user that no player name/number has been defined
   * custom name for its project
   *
   * @returns {Promise<unknown>}
   * @private
   */
  _displayNoPlayerTextWarningModal() {
    return new Promise(resolve => {
      const body = document.getElementsByTagName("body")[0];
      const div = document.createElement("div");

      div.setAttribute("id", "player-text-warning-container");
      body.append(div);


      const onSubmit = res => {
        div.remove();
        resolve(res === "confirm");
      };

      ReactDOM.render(
        <ThemeProvider theme={this.theme}>
          <NoPlayerTextWarningModal onSubmit={onSubmit} />
        </ThemeProvider>,
        div
      );
    });
  }


  /**
   * Hide the customizer and show an error message
   *
   * @param {string} code
   * @param {Error} error
   * @param {string} message
   * @private
   */
  _handleFatalError(code = "0", error, message = "An error has occurred. Please try again later.") {
    const formattedMessage = `${message} Code: ${code}`;
    // if (!!error) {
    //   console.error(error);
    // }
    if (typeof this.callbacks.onFatalError === "function") {
      this.callbacks.onFatalError(formattedMessage);
    }
    throw error || new Error(formattedMessage);
  }


  /**
   * Display a snackbar with an error
   *
   * @param {string} message
   * @private
   */
  _handleError(message = "An error has occurred. Please try again later.") {
    if (typeof this.callbacks.onError === "function") {
      this.callbacks.onError(message);
    }
  }


  /**
   * Fetch the product information
   *
   * @returns {Promise<void>}
   * @private
   */
  async _initProductInfos() {
    let res = {};

    // Fetch product infos
    try {
      const response = await axios.post(`${this.appConfig.api.baseURL}${this.appConfig.api.endpoints.getProductInfos}`,
        {
          product_id : this.productId
        });

      res = response.data;
    } catch (err) {
      this._handleFatalError("ERR_INIT_PROD_F", err);
    }

    if (res?.response?.result) {
      const result = res?.response?.result;

      // Parse the customization if needed
      if (typeof result.configuration === "string") {
        result.configuration = JSON.parse(result.configuration);
        this.isCustomProject = true;
      }

      // Convert all images URLs with base64 files
      if (result.configuration) {
        await this._populateCustomizationLogosBase64andUrl(result.configuration);
      }

      const userCustomization = result.configuration;
      const parsedQuery = queryString.parse(window.location.search);

      // Store main product infos for the customizer
      this.projectId = result.project_id;
      this.projectTitle = result.project_title;
      this.userId = result.uid;
      this.userIsOwner = !!result.is_owner;
      this.userIsLogged = !result.is_anonymous;
      this.productId = result.product_source_id;
      this.productType = result.product_type;
      this.design = result.design
        || result.configuration?.design
        || parsedQuery.design
        || this.appConfig.customizerPresets?.designs?.availableDesigns[this.productType || "default"]?.[0]?.code
        || this.design;

      this.svgURL = result.configuration?.custom?.design?.svgFile;

      // Just in case the URL is wrong, it is preferable to remove it
      // The svg can still be loaded by re-generating the svg URL during the canvas initialization
      if (!!this.svgURL && !this.svgURL.includes("http")) {
        this.svgURL = undefined;
      }

      // This needs to be done for retro-compatibility. When making a save, it is sometimes possible that the stored
      // design value is not the real design code but its label. If the current design matches a design label,
      // it means that it is probably wrong and we should handle this
      const potentialWrongDesignConfig = this.appConfig.customizerPresets.designs.availableDesigns[this.productType]
        ?.find(_design => _design.label === this.design);

      if (potentialWrongDesignConfig) {
        this.design = potentialWrongDesignConfig.code;
      }

      this.staticBaseConfig = {
        size : result.size,
        sleeve : result.sleeve,
        model : result.model || "MyLigaShirt-2-18",
        collar : result.colar, // Typo on the API
        design : this.design,
        userCustomization
      };

      // Store product related data like title and price
      this.productInfos = {
        title : result.title,
        name : this.staticBaseConfig.model,
        description : result.description,

        // Special thanks to the dude that sends price in a random format...
        price : parseFloat((result.price || "").replace("€", "")),
        formattedPrice : result.formatted_price,
        minPrice : parseFloat(String(result.min_price || "").replace("€", "")),
        maxPrice : parseFloat(String(result.max_price || "").replace("€", "")),
        defaultMinPrice : parseFloat(String(result.min_price || "").replace("€", "")),
        defaultMaxPrice : parseFloat(String(result.max_price || "").replace("€", "")),
        defaultPrice : parseFloat(String(result.price || result.min_price || "")
          .replace?.("€", ""))
      };

      // Fetch product variants if available
      if (result.variant_ids?.length) {
        this.productVariants = [];
        await Promise.all(result.variant_ids.map(_variantId => (async () => {
            try {
              const { data } = await axios.post(
                `${this.appConfig.api.baseURL}${this.appConfig.api.endpoints.getVariantInfos}`,
                {
                  product_id : _variantId
                }
);

              if (data?.response?.result) {
                this.productVariants.push({
                  code : data.response.result.product_id,
                  preview : data.response.result.image_url
                });
              }
            } catch {
              // Fail silently
            }
          })()));
      }

      if (typeof this.callbacks.onProductInfoChange === "function") {
        this.callbacks.onProductInfoChange(this.productInfos);
      }

      // Check that all the required attributes are defined for the default products
      const hasMissingData = (!this.staticBaseConfig.sleeve || !this.staticBaseConfig.model || !this.staticBaseConfig.collar);
      if (this.productType === "default" && hasMissingData) {
        this._handleFatalError("ERR_INIT_PROD_M",
          new Error("Some base config parameters are missing (sleeve, model or collar)"));
      }
    } else {
      this._handleFatalError("ERR_INIT_PROD_R", new Error("No product info returned by the API"));
    }
  }


  /**
   * Initialize the 3D Viewer
   *
   * @returns {Promise<void>}
   * @private
   */
  _init3DViewer() {
    return new Promise((resolve, reject) => {
      // This will be triggered after the apviz scripts has loaded
      window.initApviz = () => {
        // 1. Create the showcase
        if (!window.apviz) {
          console.error("window.apviz is not defined");
          return;
        }

        window.apviz.createShowcase({
          url : this.appConfig.viewer.integrationLink
        })
          .then(showcase => {
            this.showcase = showcase;
            const customization = this.getCustomization();

            return showcase.update(customization);
          })
          .then(() => this.showcase.display({ divId: Customizer.viewerID }))
          .then(viewer => {
            this.viewer = viewer;

            if (process.env.NODE_ENV === "development") {
              console.log("ⓘ viewer initialized");
            }

            resolve();
          })
          .catch(err => {
            this._handleFatalError("ERR_INIT_VIEWER", err);
            reject(err);
          });
      };

      // Then add the necessary scripts to the DOM
      if (!window.apviz) {
        const script = document.createElement("script");

        script.src = this.appConfig.viewer.apvizScriptLink;
        script.async = true;
        document.body.append(script);
      }
    });
  }


  /**
   * Get the initial product customization
   *
   * @private
   */
  async _initCustomization() {
    const fabric1SVGItem = this._getSVGItemByName("fabric1");
    const fabric2SVGItem = this._getSVGItemByName("fabric2", "pointtext");

    this.baseCustomization = {
      size : this.staticBaseConfig.size,
      sleeve : this.staticBaseConfig.sleeve,
      model : this.staticBaseConfig.model,
      collar : this.staticBaseConfig.collar,
      fabric1 : fabric1SVGItem?.content?.toLowerCase(),
      fabric2 : fabric2SVGItem?.content?.toLowerCase(),
      viewpoint : "front",
      ...(this.staticBaseConfig.userCustomization || {})
    };

    // Handle the case where there is more than one possible fabric and the
    // user should select one
    if (this.baseCustomization.fabric1.indexOf("|") > 0) {
      this.availableFabrics = this.baseCustomization.fabric1.split("|");
      this.baseCustomization.fabric1 = this.availableFabrics[0];
    }

    // Hide the brand collar label if needed
    if (this.appConfig.customizerPresets.options?.showBrandCollarLabel === false) {
      this.baseCustomization.pumaLabel = "off";
    }

    this.baseCustomization.custom = this.baseCustomization.custom || {};

    // Add potential custom stitches colors
    if (this.appConfig.customizerPresets.options?.stitchesColor) {
      this.baseCustomization.stitchesColor = this.appConfig.customizerPresets.options.stitchesColor;
    }

    // Add brand logos to the customization
    this.baseCustomization.custom.logos = {
      ...(await this._getDefaultBrandLogos(this.baseCustomization.custom.logos)),
      ...(this.baseCustomization.custom.logos || {})
    };

    if (process.env.NODE_ENV === "development") {
      console.groupCollapsed("ⓘ customization initialized");
      console.log(this.baseCustomization);
      console.groupEnd();
    }
  }


  /**
   * Check if a config is defined in the local storage
   * This is mainly used when changing the current product design or variant
   * The config is partially saved in the localstorage to be reloaded after the
   * design/variant change
   *
   * @private
   */
  async _initLocalStorageCustomization() {
    const localStorageCustomizationString = localStorage.getItem("tmp_conf");

    if (typeof localStorageCustomizationString !== "string") {
      return;
    }

    try {
      let localStorageCustomization = JSON.parse(localStorageCustomizationString);

      // Get images base64 from URLs
      await this._populateCustomizationLogosBase64andUrl(localStorageCustomization);

      if (Array.isArray(localStorageCustomization.colors)) {

        // Set colors
        localStorageCustomization.colors.forEach(_color => {
          if (_color && typeof _color === "object") {

            this.updateColorZone(_color.name, _color.value, false);
          }
        });
      }

      // For the brand logos we should take care of manually overwrite the default customization brand logos
      // If we call this.update() just like that, the local stored brand logos are going to be compared with the default
      // customization brand logos and only the differences will be sent to the viewer. The problem is that this may
      // remove some useful data, like for example the logos location. If the same location is defined on both local stored and
      // default brand logos, this location will not appear in the diff and won't be sent to the viewer
      if (localStorageCustomization.custom?.logos) {
        const mergedConfig = deepDiff(localStorageCustomization, this.getCustomization());
        const brandLogosIds = this.appConfig.customizerPresets?.brandLogos?.availableLogos?.map(_logo => _logo.id);

        Object.entries(localStorageCustomization.custom.logos)
          .forEach(([_logoId, _logoData]) => {
            if (brandLogosIds.includes(_logoId)) {
              mergedConfig.custom.logos[_logoId] = _logoData;
            }
          });

        localStorageCustomization = mergedConfig;
      } else {
        localStorageCustomization = deepDiff(localStorageCustomization, this.getCustomization());
      }

      // Set images
      await new Promise(resolve => {
        setTimeout(() => {
          this.update({ custom: localStorageCustomization.custom }, async (_, errors) => {

            await new Promise(resolve2 => {
              // Init patterns
              const patternsArray = Object.entries(localStorageCustomization.custom?.patterns || {});
              const interval = setInterval(async () => {
                if (patternsArray.length === 0) {
                  clearInterval(interval);
                  resolve2();
                  return;
                }

                const [patternId, pattern] = patternsArray.pop();
                await this.setColorZonePattern(patternId, pattern);
              }, 500);
            });

            // some logos/texts may throw errors while they are out of the viewer model. We should remove them
            if (errors && errors.length) {
              const removeCustom = {};
              const brandLogosIds = this.appConfig.customizerPresets.brandLogos.availableLogos.map(_logo => _logo.id);

              errors.forEach(_error => {
                const splitField = _error.field.split(".");

                if (splitField[0] === "custom" && !brandLogosIds.includes(splitField[2])) {
                  if (!removeCustom[splitField[1]]) {
                    removeCustom[splitField[1]] = {};
                  }

                  removeCustom[splitField[1]][splitField[2]] = null;
                }
              });

              this.update({ custom: removeCustom })
                .then(() => resolve(true));
            } else {
              resolve(true);
            }
          }, false, false);
        }, 100);
      });

      // Clean local storage
      localStorage.removeItem("tmp_conf");

    } catch (err) {
      console.error(err);
    }
  }


  /**
   * Fetch the initial Viewer SVG from the baseCustomization URL
   * and parse it with paperJs
   * (A lot of work has been done here, with tears and blood)
   *
   * @private
   */
  async _initCanvas() {
    this.svgURL = this.svgURL || this._getSVGURLFromConfig(this.baseCustomization);

    if (this.svgURL.indexOf("/") === 0) {
      this.svgURL = urlJoin(this.appConfig.siteBaseURL, this.svgURL);
    }

    try {
      // Fetch the svg file or use a file from a user save
      const { data } = await axios.get(this.svgURL);

      const parser = new DOMParser();
      const pathsWithMask = [];
      const masks = {};

      this.parsedSVG = parser.parseFromString(data, "image/svg+xml");

      // Get all predefined positions coordinates
      this.predefinedPositions = {};
      Array.prototype.forEach.call(this.parsedSVG.querySelectorAll("#predefined-positions > circle"),
        el => {
          this.predefinedPositions[el.id] = {
            x : parseFloat(el.getAttribute("cx")),
            y : parseFloat(el.getAttribute("cy"))
          };
        });

      // Remove all the eventual images from the svg (those images may come from a saved user customization)
      Array.prototype.forEach.call(this.parsedSVG.querySelectorAll("#design > image"), el => {
        el.remove();
      });

      // Check that there is no path with an id that only consists of numbers (not supported by paperjs)
      Array.prototype.forEach.call(this.parsedSVG.querySelectorAll("path,polygon"), el => {
        if (!el.id?.length) {
          el.id = createObjectId();
        } else if (!Number.isNaN(Number(el.id))) {
          el.id = `i${el.id}`;
        }
      });

      // Get all the defined mask in the SVG file
      Array.prototype.forEach.call(this.parsedSVG.querySelectorAll("mask"), el => {
        masks[el.getAttribute("id")] = {
          linearGradient : el.querySelector("linearGradient"),
          rect : el.querySelector("rect")
        };
      });

      // Get all the paths in the SVG that are using a mask
      Array.prototype.forEach.call(this.parsedSVG.querySelectorAll("path[style*='mask:'], polygon[style*='mask:']"),
        el => {
          const elStyleMask = el.getAttribute("style").match(/mask:url\(#id\d+\)/g);

          if (elStyleMask?.[0]) {
            const maskId = elStyleMask[0]
              .replace("mask:url(#", "")
              .replace(")", "");

            pathsWithMask.push({
              pathId : el.getAttribute("id"),
              gradient : masks[maskId]
            });
          }
        });

      // Load the SVG in paperJs
      this.mainScope.activate();
      this.mainScope.project.importSVG(this.parsedSVG, {
        expandShapes : true,
        applyMatrix : false,
        onLoad : item => {
          item.bounds = this.mainScope.project.view.viewSize;
          this.canvasSVG = item; // Contains the paper Item of the SVG
          this.designGroupSVGItem = this._getSVGItemByName("design", "group"); // Contains paths with customizable fill colors

          // Store the original product colors
          this.originalColors = {};
          this.designGroupSVGItem.getItems({
            recursive : true,
            match : _item => (
              !!_item.name && _item.name.indexOf("color-zone") === 0
              && !!_item.fillColor
              && ["path", "compoundpath"].includes(_item.className.toLowerCase())
            )
          })
            .forEach(_item => {
              this.originalColors[_item.name] = _item.fillColor?.toCSS?.(true);
            });

          // We should follow some extra steps in order to make masks work with paper
          pathsWithMask.forEach(({ pathId, gradient }) => {
            const pathItem = this._getSVGItemByName(pathId);

            // This is the mask. The result will use the mask's transparency
            const mask = pathItem.clone({ insert: false });
            mask.set({
              name : `mask-${pathItem.name}`,
              fillColor : {
                gradient : {
                  stops : Array.prototype.map.call(gradient.linearGradient.children, _stop => {
                    const stopStyles = this._inlineStyleToObject(_stop.getAttribute("style"));
                    const rgbColors = colorConvert.keyword.rgb(stopStyles["stop-color"]);

                    return ([new paper.Color(rgbColors[0],
                      rgbColors[1],
                      rgbColors[2],
                      parseFloat(stopStyles["stop-opacity"])), parseFloat(_stop.getAttribute("offset"))]);
                  })
                },
                origin : {
                  x : parseFloat(gradient.linearGradient.getAttribute("x1")),
                  y : parseFloat(gradient.linearGradient.getAttribute("y1"))
                },
                destination : {
                  x : parseFloat(gradient.linearGradient.getAttribute("x2")),
                  y : parseFloat(gradient.linearGradient.getAttribute("y2"))
                }
              }
            });

            pathItem.hasMask = true;

            // Bundle everything is a group so the mask can be applied to the content
            const { parent } = pathItem;
            const content = new paper.Group({ children: [pathItem], blendMode: "source-in" });
            const group = new paper.Group({ children: [mask, content], blendMode: "source-over" });

            content.set({
              name : `${pathItem.name }_group`
            });
            parent.addChild(group);
          });

          if (process.env.NODE_ENV === "development") {
            console.log("ⓘ canvas initialized");
          }
        },
        onError : err => {
          this._handleFatalError("ERR_INIT_CANVAS", err);
        }
      });
    } catch (err) {
      this._handleFatalError("ERR_INIT_CANVAS", err);
    }
  }


  /**
   * Init some listeners
   *
   * @private
   */
  _initListeners() {
    this.viewer.on("item-duplicated", this._handleItemDuplication.bind(this));
    //  this.viewer.on('item-transforming', this._handleImagesResizeMinSizes.bind(this));
    this.viewer.on("item-selected", this._handleViewerItemSelected.bind(this));
    this.viewer.on("enter-cursor-area", this._handleCursorAreaChanges.bind(this));
  }


  /**
   * Initialize the customization colors (if some are defined)
   * This will only be useful when loading an existing project
   *
   * @returns {Promise<void>}
   * @private
   */
  async _initCustomizationColors() {
    const customization = this.baseCustomization;

    if (customization?.custom?.colors) {

      let colors = Object.values(customization?.custom?.colors)
        .filter(_color => typeof _color === "object" && !!_color.zoneId && !!_color.colorCode);

      // Keep this to handle the previous way of saving colors that may still be used for some old projects
      if (colors.length === 0) {
        colors = Object.entries(this.getColorsMapFromCustomizationColors(customization.custom.colors))
          .map(([zoneId, colorCode]) => ({ zoneId, colorCode }));
      }

      colors
        .forEach(_color => this.updateColorZone(_color.zoneId, _color.colorCode, false));

      await new Promise(resolve => setTimeout(() => {
        this.update.bind(this);
        resolve();
      }, 100));
    }
  }


  /**
   * Initialize the customization patterns (if some are defined)
   * This will be only useful when loading an existing project
   *
   * @returns {Promise<void>}
   * @private
   */
  async _initCustomizationPatterns() {
    const customization = this.getCustomization();
    const patternsArray = Object.entries(customization?.custom?.patterns || {});
    await new Promise(resolve => {
      const interval = setInterval(async () => {
        if (patternsArray.length === 0) {
          clearInterval(interval);
          resolve();
          return;
        }

        const [patternId, pattern] = patternsArray.pop();

        await this.setColorZonePattern(patternId, pattern);

      }, 500);
    });
  }


  /**
   * Add a listener to prevent the page from being reloaded when the user
   * do not have saved his customization
   * (This feature as been disabled)
   *
   * @private
   */
  _initPageUnloadWarning() {
    window.addEventListener("beforeunload", e => {
      if (!this.isProjectSaved && process.env.NODE_ENV === "production") {
        if (e) {
          e.returnValue = "Vous n'avez pas sauvegardé votre projet. Êtes-vous certain de vouloir fermer la page ?";
          return e.returnValue;
        }

        return "Vous n'avez pas sauvegardé votre projet. Êtes-vous certain de vouloir fermer la page ?";
      }

      return null;
    });
  }


  /**
   * This function can be used to ensure that all the logos and texts will have both an 'image' (base64) and 'imageURL'
   *
   * @param {{}} customization
   * @param {boolean} replaceBase64WithURLs
   * @returns {Promise<*>}
   * @private
   */
  async _populateCustomizationLogosBase64andUrl(customization, replaceBase64WithURLs = false) {
    const imagesList = [
      ...Object.values(customization?.custom?.logos || {}),
      ...Object.values(customization?.custom?.texts || {})
    ];
    await Promise.all(imagesList.map(_image => (async () => {
        if ((typeof _image?.imageUrl !== "string" || !_image.imageUrl.startsWith("/")) && typeof _image.image === "string" && _image.image.startsWith("data:image")) {
          _image.imageUrl = await this._uploadAPIFile("logo", _image.image);
        } else if (typeof _image?.imageUrl === "string" && _image.imageUrl.startsWith("/") && (typeof _image.image !== "string" || !_image.image.startsWith("data:image"))) {
          _image.image = await this._imageURLToBase64(urlJoin(this.appConfig.siteBaseURL, _image.imageUrl));
          _image.image = _image.image.replace("text/xml", "image/svg+xml");
        }
        if (replaceBase64WithURLs && !!_image.imageUrl) {
          _image.image = _image.imageUrl;
        }
      })())
      .filter(Boolean));

    return customization;
  }


  // /**
  //  * Get a customization object as parameter and will look
  //  * for all the base64 images it contains and upload them
  //  * on the server before replacing them in the customization
  //  * (DEPRECATED)
  //  *
  //  * @param {{}} customization
  //  * @returns {Promise<void>}
  //  * @private
  //  */
  // async _convertCustomizationBase64ToURLs(customization = {}) {
  //   if (typeof customization.custom?.logos === "object") {
  //     await Promise.all(Object.values(customization.custom.logos)
  //       // eslint-disable-next-line array-callback-return,consistent-return
  //       .map(_logo => {
  //         if (typeof _logo?.image === "string" && _logo.image.indexOf("data:image") === 0) {
  //           // eslint-disable-next-line consistent-return
  //           return (async () => {
  //             // A logo never changes, so we do not need to upload a new version of it if it has already
  //             // been uploaded
  //             if (_logo.imageUrl) {
  //               return _logo.imageUrl;
  //             }
  //             _logo.image = await this._uploadAPIFile("logo", _logo.image);
  //             _logo.imageUrl = _logo.image;
  //           })();
  //         }
  //       })
  //       .filter(Boolean));
  //   }
  //
  //   if (typeof customization.custom?.texts === "object") {
  //     await Promise.all(Object.values(customization.custom.texts)
  //       // eslint-disable-next-line array-callback-return,consistent-return
  //       .map(_text => {
  //         if (typeof _text?.image === "string" && _text.image.indexOf("data:image") === 0) {
  //           return (async () => {
  //             _text.image = await this._uploadAPIFile("logo", _text.image);
  //             _text.imageUrl = _text.image;
  //           })();
  //         }
  //       })
  //       .filter(Boolean));
  //   }
  // }


  // /**
  //  * Get a customization object has parameter and will look
  //  * for all the images URLs it contains and convert them to base64
  //  * before replacing them in the customization
  //  * (DEPRECATED)
  //  *
  //  * @param {{}} customization
  //  * @returns {Promise<void>}
  //  * @private
  //  */
  // async _convertCustomizationURLsToBase64(customization = {}) {
  //   if (typeof customization?.custom?.logos === "object") {
  //     await Promise.all(Object.values(customization.custom.logos)
  //       // eslint-disable-next-line consistent-return,array-callback-return
  //       .map(_logo => {
  //         if (typeof _logo?.image === "string" && _logo.image.indexOf("/") === 0) {
  //           return (async () => {
  //             _logo.image = await this._imageURLToBase64(urlJoin(this.appConfig.siteBaseURL, _logo.image));
  //             _logo.image = _logo.image.replace("text/xml", "image/svg+xml");
  //           })();
  //         }
  //       })
  //       .filter(Boolean));
  //   }
  //
  //   if (typeof customization?.custom?.texts === "object") {
  //     await Promise.all(Object.values(customization.custom.texts)
  //       // eslint-disable-next-line consistent-return,array-callback-return
  //       .map(_text => {
  //         if (typeof _text?.image === "string" && _text.image.indexOf("/") === 0) {
  //           return (async () => {
  //             _text.image = await this._imageURLToBase64(urlJoin(this.appConfig.siteBaseURL, _text.image));
  //           })();
  //         }
  //       })
  //       .filter(Boolean));
  //   }
  // }


  /**
   * Get the first and last non-empty pixels coordinates of a canvas
   *
   * @param {object} canvas
   * @returns {{last: {x: number, y: number}, first: {x: number, y: number}}}
   * @private
   */
  _getCanvasFirstAndLastPixelsCoordinates(canvas) {
    const { width, height } = canvas;
    const ctx = canvas.getContext("2d");
    const imgData = ctx.getImageData(0, 0, width, height);


    function getFirstPixel() {
      for (let y = 0; y < height; y++) {
        for (let x = 0; x < width; x++) {
          const t = 4 * (y * width + x);
          if ((imgData.data[t] > 0 && imgData.data[t + 1] > 0)
            || (imgData.data[t + 1] > 0 && imgData.data[t + 2] > 0)
            || (imgData.data[t] > 0 && imgData.data[t + 2] > 0)) {
            return { x, y };
          }
        }
      }

      return null;
    }


    function getLastPixel() {
      for (let y = height; y > 0; y--) {
        for (let x = width; x > 0; x--) {
          const t = 4 * (y * width + x);
          if ((imgData.data[t] > 0 && imgData.data[t + 1] > 0)
            || (imgData.data[t + 1] > 0 && imgData.data[t + 2] > 0)
            || (imgData.data[t] > 0 && imgData.data[t + 2] > 0)) {
            return { x, y };
          }
        }
      }

      return null;
    }


    return { first: getFirstPixel(), last: getLastPixel() };
  }


  /**
   * Triggered each time a viewer item is selected
   *
   * @param {string|number} itemId
   * @returns {{variant: *, id: *}}
   * @private
   */
  _handleViewerItemSelected(itemId) {
    let variant;
    Object.keys(this.appConfig.formVariants).forEach(_variantName => {
      if (this.customization?.custom?.[_variantName]?.[itemId]) {
        variant = _variantName;
      }
    });

    const res = {
      variant,
      id : itemId
    };

    if (typeof this.callbacks.onViewerItemSelected === "function") {
      setTimeout(() => this.callbacks.onViewerItemSelected(res), 200);
    }

    return res;
  }


  /**
   * This callback is called when an image is moved from a cursorZone to another
   * (eq from Front to Arms) and is used to update the current viewpoint dependently
   *
   * @param {object} cursor
   * @param {string} cursor.to
   * @private
   */
  _handleCursorAreaChanges({ to }) {
    const cursorArea = this.appConfig.viewer.cursorAreas[to] || this.appConfig.viewer.safeZones[to];

    if (cursorArea && cursorArea.viewpoint) {
      this.changeViewpoint(cursorArea.viewpoint);
    }
  }


  // /**
  //  * Triggered each time the user changes an image on the viewer
  //  * Display an error message if the image is too small
  //  * DEPRECATED : This feature is no more necessary, a user cannot reduce an image size under a defined limit
  //  *
  //  * @param {string} id
  //  * @param {number} scaling
  //  * @private
  //  */
  // _handleImagesResizeMinSizes({ id, scaling }) {
  //   let { customization } = this;
  //   let imageConfig = customization.custom?.logos?.[id] || customization.custom?.texts?.[id];
  //
  //   // Only do something if the image scaling has changed
  //   if (!imageConfig || imageConfig.scale?.toFixed(4) === scaling?.toFixed(4)) {
  //     return;
  //   }
  //
  //   const type = customization.custom?.logos?.[id] ? "logo" : "texte";
  //
  //   // Get the updated customization
  //   customization = this.getCustomization();
  //   imageConfig = customization.custom?.logos?.[id] || customization.custom?.texts?.[id];
  //
  //   // Get the svg sizes
  //   const [svgWidth, svgHeight] = this.parsedSVG.getElementsByTagName("svg")[0]
  //     .getAttribute("viewBox")
  //     .split(" ")
  //     .slice(2, 4)
  //     .map(string => parseInt(string, 10));
  //
  //   // Get the image height and width in centimeters
  //   const height = (imageConfig.height * svgHeight) / 1000;
  //   const width = (imageConfig.width * svgWidth) / 1000;
  //
  //   // Define the min size depending on the image type
  //   const minSize = type === "logo" ? 4 : 5;
  //
  //   if (Math.min(height, width) < minSize) {
  //     this.callbacks.onError(`Attention votre ${type} risque de ne pas être suffisamment lisible`);
  //   }
  // }


  /**
   * Get the default brand logos customization
   * Check if each logo is already defined in the customization (this may append when restoring a saved
   * customization) and add them as normal logos
   *
   * @param {object} currentLogos
   * @returns {Promise<void>}
   * @private
   */
  async _getDefaultBrandLogos(currentLogos = {}) {
    if (!this.appConfig.customizerPresets.brandLogos?.availableLogos?.length) {
      return null;
    }
    const parser = new DOMParser();

    const logos = {};
    await Promise.all(
      this.appConfig.customizerPresets.brandLogos.availableLogos.map(_logo => (async () => {
        // Do nothing if the logo is already defined
        if (!!currentLogos[_logo.id] && typeof currentLogos[_logo.id] === "object") {
          return;
        }

        // If the given logo url isn't a string but an URL, we should first ensure to call this function
        // to convert the url into a valid svg string.
        const svgStringFile = await this._getSvgStringFileFromUrl(_logo.svgStringFile);

        // Generate a base64 for the logo
        const svgEl = parser.parseFromString(decodeURI(svgStringFile), "image/svg+xml");

        const image = await this._svgToBase64(svgEl);
        const color = svgEl.getElementsByTagName("path")[0]?.getAttribute("fill");
        const colorName = (_logo.availableColors?.find?.(_color => _color.code === color))?.label;
        // eslint-disable-next-line no-nested-ternary
        const positions = Array.isArray(_logo.availablePositions)
          ? _logo.availablePositions
          : Array.isArray(_logo.availablePositions?.[this.productType])
            ? _logo.availablePositions[this.productType]
            : [];

        logos[_logo.id] = {
          image,
          location : positions[0]?.id,
          pinned : true,
          menuDisabled : true,
          sizeInCm : {
            width : _logo.widthInCm
          },
          color,
          colorName
        };
      })())
    );

    return logos;
  }


  /**
   * Triggered from a viewer event when an item has been duplicated
   * perform a full duplication of the item
   *
   * @param {string} duplicatedId
   * @param {string} parentId
   * @private
   */
  async _handleItemDuplication({ duplicatedId, parentId }) {
    await new Promise(resolve => setTimeout(resolve, 100));
    const customization = this.getCustomization();
    const itemType = customization.custom?.texts?.[parentId]
      ? "texts"
      : "logos";
    const originalItem = customization.custom?.[itemType]?.[parentId];
    const duplicatedItem = customization.custom?.[itemType]?.[duplicatedId];
    const item = { ...originalItem, ...duplicatedItem, location: null };

    await this.update({
      custom : {
        [itemType] : {
          [duplicatedId] : item
        }
      }
    });

    setTimeout(() => {
      this.selectViewerItem(duplicatedId);
      if (typeof this.callbacks.onViewerItemSelected === "function") {
        this.callbacks.onViewerItemSelected({ variant: itemType, id: duplicatedId });
      }
    }, 10);
  }


  /**
   * Get the svg file has a string from a given svg URL
   *
   * @param {string} url
   * @returns {string|Promise<string>}
   * @private
   */
  _getSvgStringFileFromUrl(url = "") {
    if (!url?.startsWith("http") && !url.startsWith("/")) { return url; }
    const svgUrl = url.startsWith("http")
      ? url
      : urlJoin(this.appConfig.siteBaseURL, url);
    return fetch(svgUrl)
      .then(r => r.text())
      .catch(console.error.bind(console));
  }


  /**
   *
   * @param {{}} productConfig
   * @returns {string} url
   * @private
   */
  _getSVGURLFromConfig(productConfig = this.staticBaseConfig) {

    const URLs = {
      default : `${this.appConfig.api.svgBaseURL}/${productConfig.model}/${productConfig.design}/${productConfig.model}_${productConfig.sleeve}_${productConfig.collar}_${productConfig.design}_${productConfig.size}.svg`,
      short : `${this.appConfig.api.svgBaseURL}/${productConfig.model}/${productConfig.design}/${productConfig.model}_${productConfig.design}_${productConfig.size}.svg`
    };

    if (process.env.NODE_ENV === "development") {
      console.log("ⓘ SVG URL generated", URLs[this.productType]);
    }

    return URLs[this.productType];
  }


  /**
   * Get an Item node that match
   * a given name and type can have one of
   * the following values : [path|pointtext|compoundpath|group|shape]
   *
   * @param {string} name
   * @param {string} type
   * @returns {null|paper.Item[]}
   * @private
   */
  _getSVGItemByName(name, type) {
    if (!this.canvasSVG) { return null; }

    return this.canvasSVG.getItem({
      recursive : true,
      match : e => e.name === name && (!type || e.className.toLowerCase() === type)
    });
  }


  /**
   * Convert an inline style string into an iterable object
   *
   * @param {string} style
   * @returns {object} stylesObject
   * @private
   */
  _inlineStyleToObject(style = "") {
    const res = {};
    style.split(";")
      .forEach(_style => {
        res[_style.split(":")[0].trim()] = _style.split(":")[1].trim();
      });

    return res;
  }


  /**
   * Insert a customization image into a svgEl
   *
   * @param {Document} svgEl
   * @param {{}} imageConfig
   * @private
   */
  _insertCustomizationImageInSvgFile(svgEl, imageConfig = {}) {
    const svgImageElement = document.createElementNS("http://www.w3.org/2000/svg", "image");
    const [svgWidth, svgHeight] = svgEl.getElementsByTagName("svg")[0]
      .getAttribute("viewBox")
      .split(" ")
      .slice(2, 4)
      .map(string => parseInt(string, 10));
    const height = imageConfig.height * svgHeight;
    const width = imageConfig.width * svgWidth;
    const x = (this.predefinedPositions[imageConfig.location]?.x || imageConfig.position?.x * svgWidth) - (width / 2);
    const y = (this.predefinedPositions[imageConfig.location]?.y || imageConfig.position?.y * svgHeight) - (height / 2);

    const imageSrc = imageConfig.image;

    svgImageElement.setAttributeNS(null, "height", String(height));
    svgImageElement.setAttributeNS(null, "width", String(width));
    svgImageElement.setAttributeNS("http://www.w3.org/1999/xlink", "href", imageSrc);
    svgImageElement.setAttributeNS(null, "x", String(x));
    svgImageElement.setAttributeNS(null, "y", String(y));
    svgImageElement.setAttributeNS(null, "visibility", "visible");
    svgImageElement.setAttributeNS(null, "opacity", imageConfig.opacity);

    const rotation = (imageConfig.technicalRotation || 0) + (imageConfig.userRotation || 0);
    svgImageElement.setAttributeNS(null,
      "transform",
      `rotate(${rotation} ${(this.predefinedPositions[imageConfig.location]?.x || imageConfig.position?.x * svgWidth)} ${(this.predefinedPositions[imageConfig.location]?.y || imageConfig.position?.y * svgHeight)})`);

    svgEl.getElementById("design").append(svgImageElement);
  }


  /**
   * Do the same thing as _insertCustomizationImageInSvgFile but with a svg string
   * The imageConfig object should contain a stringified svg
   * This svg should have a group child with a text tag in it
   *
   * @param {Document} svgEl
   * @param {{}} imageConfig
   * @private
   */
  _insertCustomizationSvgStringInSvgFile(svgEl, imageConfig = {}) {
    if (!imageConfig.svgString) {
      this._insertCustomizationImageInSvgFile(svgEl, imageConfig);
      return;
    }

    try {
      // Convert the string to an svg element
      let imageSvgEl = new DOMParser().parseFromString(imageConfig.svgString, "image/svg+xml");
      imageSvgEl = imageSvgEl.firstChild;

      const groupEl = imageSvgEl.firstChild;
      const textEl = groupEl.firstChild;

      // Insert the text in the final svg
      svgEl.getElementById("design").append(textEl);

      // Get the final svg bounding box
      const [svgWidth, svgHeight] = svgEl.getElementsByTagName("svg")[0]
        .getAttribute("viewBox")
        .split(" ")
        .slice(2, 4)
        .map(string => parseInt(string, 10));
      const height = imageConfig.height * svgHeight;
      const width = imageConfig.width * svgWidth;
      const x = (this.predefinedPositions[imageConfig.location]?.x || imageConfig.position?.x * svgWidth) - (width / 2);
      const y = (this.predefinedPositions[imageConfig.location]?.y || imageConfig.position?.y * svgHeight) - (height / 2);

      // Define the font-size that the text should have to be scaled at the good height
      const scaledFontSize = (parseInt(groupEl.getAttribute("font-size"), 10) / parseInt(imageSvgEl.getAttribute("height"), 10)) * height;
      const scaledStrokeWidth = (parseInt(groupEl.getAttribute("stroke-width"), 10) / parseInt(imageSvgEl.getAttribute("height"), 10)) * height;

      Array.prototype.forEach.call(groupEl.attributes,
        _attribute => textEl.setAttributeNS(null, _attribute.name, _attribute.value));

      // Add some extra attributes
      textEl.setAttributeNS(null, "visibility", "visible");
      textEl.setAttributeNS(null, "opacity", imageConfig.opacity);
      textEl.setAttributeNS(null, "text-anchor", "start");
      textEl.setAttributeNS(null, "font-size", String(scaledFontSize));
      textEl.setAttributeNS(null, "stroke-width", String(scaledStrokeWidth));

      // The text position should not be defined on the group but on the text tag directly
      textEl.setAttributeNS(null, "x", String(x));
      textEl.setAttributeNS(null, "y", String(y));

      textEl.id = null;

      // Set the rotation
      const rotation = (imageConfig.technicalRotation || 0) + (imageConfig.userRotation || 0);
      textEl.setAttributeNS(null,
        "transform",
        `rotate(${rotation} ${(this.predefinedPositions[imageConfig.location]?.x || imageConfig.position?.x * svgWidth)} ${(this.predefinedPositions[imageConfig.location]?.y || imageConfig.position?.y * svgHeight)})translate(0, ${height})`);

    } catch (err) {
      console.error(err);
      this._insertCustomizationImageInSvgFile(svgEl, imageConfig);
    }

  }


  _imageURLToBase64(url) {
    return new Promise(resolve => {
      const xhr = new XMLHttpRequest();

      xhr.addEventListener("load", () => {
        const reader = new FileReader();

        reader.onloadend = () => {
          resolve(reader.result);
        };
        reader.readAsDataURL(xhr.response);
      });

      xhr.open("GET", url);
      xhr.responseType = "blob";
      xhr.send();
    });
  }

  /**
   * Convert a svg element to a base64 string
   *
   * @param {Document} svgEl
   * @returns {Promise<any>}
   * @private
   */
  _svgToBase64(svgEl) {
    return new Promise((resolve, reject) => {
      if (!svgEl) {
        reject();
        return;
      }

      const svgElement = svgEl.getElementsByTagName("svg")[0] || svgEl;
      const svgData = unescape(encodeURIComponent(new XMLSerializer().serializeToString(svgElement)));
      const canvas = document.createElement("canvas");
      const viewBox = svgElement.getAttribute("viewBox");
      const [svgWidth, svgHeight] = viewBox
        .split(!viewBox.includes(",") ? " " : ",")
        .slice(2, 4)
        .map(string => parseInt(string, 10));

      canvas.width = svgWidth;
      canvas.height = svgHeight;

      const ctx = canvas.getContext("2d");
      const img = document.createElement("img");

      // Create a base64 image src from the svg
      img.setAttribute("src", `data:image/svg+xml;base64,${ btoa(svgData)}`);

      // Once the image is loaded, add it to the canvas and generate a png base64 of the canvas
      img.addEventListener("load", () => {
        ctx.drawImage(img, 0, 0);
        resolve(canvas.toDataURL("image/png"));
      });
    });
  }


  /**
   * Convert a js File object to a base64 string
   *
   * @param {File} file
   * @returns {Promise<any>}
   */
  _fileToBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.addEventListener("load", () => resolve(reader.result));
      reader.addEventListener("error", error => reject(error));
    });
  }


  _resolveDataFromMapPath(path, data) {
    if (typeof data !== "object" || !path) { return null; }

    const segments = path.split(".");

    if (!segments.length) { return null; }

    if (data[segments[0]]) {
      if (typeof data[segments[0]] === "object" && !!segments[1]) {
        return this._resolveDataFromMapPath(segments.slice(1).join("."), data[segments[0]]);
      } if (!segments[1]) {
        return data[segments[0]];
      }
    }
    return null;
  }


  /**
   * This method can be used to update all the customization texts sequentially
   * by replacing their color by a given color
   *
   * @param {string} color
   * @returns {Promise<void>}
   * @private
   */
  async _updateAllTextsColor(color) {
    const customization = this.getCustomization();
    const updatedTexts = {};

    // This method is used to run the promises one after the other
    // eslint-disable-next-line arrow-body-style
    const updateTextsSequentially = arr => {
      // eslint-disable-next-line arrow-body-style
      return arr.reduce((promise, [_textId, _textConfig]) => {
        return promise.then(() => {
          if (_textConfig.fillColor === color) {
            return;
          }

          const nextConfig = { ..._textConfig, fillColor: color };
          const fillColorName = (this.appConfig.customizerPresets.texts.availableColors.find(_color => _color.code === nextConfig.fillColor))?.label;

          this.getBase64FromText(nextConfig.text, nextConfig)
            .then(({ pngImage, svgString }) => {
              updatedTexts[_textId] = {
                image : pngImage,
                // It is mandatory to clear `imageUrl` so the new text image gets uploaded to server
                imageUrl : null,
                svgString,
                fillColorName,
                fillColor : color
              };
            });
        })
          .catch(console.error);
      }, Promise.resolve());
    };

    const textsArray = Object.entries(customization?.custom?.texts || {});

    // Do nothing if all the texts are already using the right color
    if (textsArray.every(([, _textConfig]) => _textConfig.fillColor === color)) {
      return;
    }

    // While updating many texts sequentially may take some time, it is not a bad
    // practice to display the loading process on the screen to prevent the user
    // from manipulating the customizer during the process
    this.callbacks.onProcessing(true);

    await updateTextsSequentially(textsArray);

    await this.update({
      custom : {
        texts : updatedTexts
      }
    });

    this.callbacks.onProcessing(false);
  }

}


Customizer.canvasID = "customizer-canvas";
Customizer.textCanvasID = "customizer-canvas-text";
Customizer.patternCanvasID = "customizer-pattern-text";
Customizer.viewerID = "customizer-3D-viewer";
Customizer.Canvases = [
  props => <canvas id={Customizer.canvasID} {...props} style={{ width: 2048 }} />,
  props => <canvas id={Customizer.textCanvasID} {...props} style={{ width: 1024 }} />,
  props => <canvas id={Customizer.patternCanvasID} {...props} width={2400} height={2400} />
];

export default Customizer;
