import React from "react";


export default props => (
  <svg width="29" height="29" viewBox="0 0 23 29" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g transform="translate(1 1)" stroke="#181818" strokeWidth="1.452" fill="none" fillRule="evenodd">
      <circle cx="16.959" cy="4.226" r="3.636" />
      <circle cx="4.095" cy="13.847" r="3.636" />
      <circle cx="16.337" cy="23.401" r="3.636" />
      <path d="M13.156 21.074l-6.18-4.596M7.258 11.569l6.65-5.033" />
    </g>
  </svg>
);
