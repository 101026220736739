import { Button, Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import clx from "classnames";
import React from "react";
import { useDropzone } from "react-dropzone";
import AppContext from "../../../AppContext";
import useTranslate from "../../../lib/useTranslate";
import toBase64 from "../../../utils/toBase64";
import wrapper from "../../../utils/wrapper";
import Select from "../../Select";
import InfoIcon from "../../svg/Info";
import UploadIcon from "../../svg/Upload";


const styles = theme => ({
  container : {
    width : "100%",
    display : "flex",
    flexDirection : "column"
  },

  buttonsContainer : {
    width : "100%"
  },

  buttonsContainer__small : {
    width : "auto",
    marginLeft : theme.spacing(2)
  },

  uploadButton : {
    fontSize : "0.8rem",
    borderRadius : 10,
    marginRight : theme.spacing(),
    marginTop : theme.spacing()
  },

  uploadButton__small : {
    width : 39,
    minWidth : 39
  },

  uploadIcon : {
    width : 16,
    minWidth : 16
  },

  errorMessage : {
    marginTop : theme.spacing()
  },

  infoIcon : {
    flexShrink : 0,
    marginRight : theme.spacing(2)
  },

  helpText : {
    marginTop : theme.spacing(2),
    marginBottom : theme.spacing(2)
  },

  buttonLabel : {
    marginLeft : theme.spacing()
  },

  dividerTop : {
    marginBottom : theme.spacing(2),
    marginTop : theme.spacing()
  },

  locationSelect : {
    marginTop : theme.spacing(),
    maxWidth : 177
  }
});


function LogosUploadForm({ classes, onFileUpload, small, availableLocations = [] }) {
  const [error, setError] = React.useState();
  const [location, setLocation] = React.useState(availableLocations[0]?.value);
  const { appConfig } = React.useContext(AppContext);
  const { t } = useTranslate();

  /**
   * Handle drop success
   *
   * @type {Function}
   */
  const onDrop = acceptedFiles => {
    if (acceptedFiles && acceptedFiles.length) {
      toBase64(acceptedFiles[0])
        .then(file => onFileUpload(file, location))
        .catch(err => {
          setError(t("COMPONENTS.LOGOS_FORM.ERROR_WHILE_ADDING_FILE"));
          console.log(err);
        });
    }
    setError(null);
  };

  /**
   * Handle Drop errors
   *
   * @type {Function}
   */
  const onDropRejected = rejectedFiles => {
    if (!rejectedFiles || !rejectedFiles.length) { return; }
    if (rejectedFiles[0]?.errors[0]?.code === "file-too-large") {
      setError(t("COMPONENTS.LOGOS_FORM.FILE_SHOULD_NOT_EXCEED_X_MO",
        { size: appConfig.formVariants.logos.settings.maxFileSize / 1000000 }));
    } else if (rejectedFiles[0]?.errors[0]?.code === "file-invalid-type") {
      setError(t("COMPONENTS.LOGOS_FORM.FILE_TYPE_NOT_SUPPORTED"));
    } else {
      setError(t("COMPONENTS.LOGOS_FORM.ERROR_OCCURRED_WHILE_ADDING_FILE"));
    }
  };

  // Initialize Dropzone
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept : appConfig.formVariants.logos.settings.allowedFileTypes.join(", "),
    maxSize : appConfig.formVariants.logos.settings.maxFileSize,
    multiple : false,
    onDropRejected
  });


  return (
    <div className={classes.container}>
      <Grid
        container
        wrap="nowrap"
        alignItems={small ? "flex-start" : "center"}
        justifyContent="space-between"
        direction={small ? "column" : "row"}
      >
        {
          small
          && (
          <Typography style={{ flexShrink: 0 }}>
            {t("COMPONENTS.LOGOS_FORM.ADD_ANOTHER_LOGO")}
          </Typography>
)
        }
        <Grid
          container
          direction={small ? "row" : "column"}
          wrap="nowrap"
          justifyContent={small ? "flex-end" : "flex-start"}
          alignItems={small ? "flex-end" : "flex-start"}
        >
          <Grid container direction="column">
            <Typography variant="caption">{t("WORDS.POSITION")} :</Typography>
            <Select
              value={location}
              onChange={e => setLocation(e.target.value)}
              options={availableLocations}
              className={classes.locationSelect}
              disabled={availableLocations?.length <= 1}
            />
          </Grid>
          <Grid
            container
            className={clx(classes.buttonsContainer, { [classes.buttonsContainer__small]: small })}
            alignItems="center"
            justifyContent={small ? "flex-end" : "space-between"}
            wrap="nowrap"
          >
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <Button
                disableElevation
                size="large"
                variant="contained"
                color="primary"
                className={clx(classes.uploadButton, { [classes.uploadButton__small]: small })}
              >
                <UploadIcon className={classes.uploadIcon} />
                {
                  !small
                  && <span className={classes.buttonLabel}>{t("ACTIONS.UPLOAD")}</span>
                }
              </Button>
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Typography className={classes.errorMessage} color="error">
        {error}
      </Typography>
      {
        !small
        && (
        <Grid container wrap="nowrap" className={classes.helpText}>
          <InfoIcon className={classes.infoIcon} />
          <Typography variant="caption">
            <span style={{ textTransform: "uppercase" }}>
              {t("COMPONENTS.LOGOS_FORM.HELP_CALLOUT.TITLE")}
            </span>
            <br />
            {t("COMPONENTS.LOGOS_FORM.HELP_CALLOUT.TEXT")}
          </Typography>
        </Grid>
)
      }
    </div>
  );
}


export default wrapper({ styles }, LogosUploadForm);
