/* eslint-disable react/no-array-index-key */
import { Button } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import withWidth, { isWidthUp } from "@material-ui/core/withWidth";
import clx from "classnames";
import React from "react";
import SlickSlider from "react-slick";
import AppContext from "../../../AppContext";
import FormContext from "../../../FormContext";
import useTranslate from "../../../lib/useTranslate";
import wrapper from "../../../utils/wrapper";
import LessMoreButton from "../../LessMoreButton";
import Select from "../../Select";
import Slider from "../../Slider";


const styles = theme => ({
  container : {
    width : "100%",
    display : "flex",
    flexDirection : "column",

    "& .slick-slider" : {
      maxWidth : "100%"
    },

    "& .slick-arrow" : {
      display : "flex",
      alignItems : "center",
      justifyContent : "center",
      transform : "scale(2)",

      "&:before" : {
        color : theme.palette.text.grey,
        content : '"‹"'
      },

      "&.slick-next:before" : {
        content : '"›"'
      }
    }
  },

  previewContainer : {
    position : "relative",
    height : theme.spacing(8),
    minHeight : theme.spacing(8)
  },

  preview : {
    position : "relative",
    height : theme.spacing(8),
    minHeight : theme.spacing(8),
    width : theme.spacing(8),
    minWidth : theme.spacing(8),
    backgroundSize : "contain",
    backgroundPosition : "center",
    backgroundRepeat : "no-repeat",
    borderRadius : 10,
    backgroundColor : "#B6B6B6",
    cursor : "pointer",
    margin : "auto",
    left : 0,
    right : 0
  },

  preview__selected : {
    "&:before" : {
      width : "calc(100% - 6px)",
      height : "calc(100% - 6px)",
      border : "3px solid black",
      content : '""',
      position : "absolute",
      left : 0,
      top : 0,
      borderRadius : 13,
      boxSizing : "initial"
    }
  },

  slidersContainer : {
    marginTop : theme.spacing(4)
  },

  slider : {
    width : 210,
    marginLeft : theme.spacing(2)
  },

  deleteButton : {
    fontSize : "0.7rem",
    alignSelf : "flex-start",
    marginBottom : theme.spacing(2),
    color : theme.palette.error.main
  },

  locationSelect : {
    marginLeft : theme.spacing(2)
  }

});

const sliderSettings = {
  // accessibility: false,
  draggable : false,
  infinite : false,
  slidesToShow : 3,
  rows : 2,
  dots : true
};


function LogosResizeForm({ classes, logos = {}, onLogoDelete, selectedViewerItemId, availableLocations, width }) {
  const { formValues, setFormValues } = React.useContext(FormContext);
  const [logosList, setLogosList] = React.useState([]);
  const [selectedLogo, setSelectedLogo] = React.useState();
  const [slidersValues, setSlidersValue] = React.useState({});
  const { customizer, appConfig } = React.useContext(AppContext);
  const sliderRef = React.useRef();
  const { t } = useTranslate();
  const isShortButtocksPosition = slidersValues?.custom?.logos?.[selectedLogo]?.location?.indexOf?.("buttock") !== -1;

  const initialSlide = logosList?.findIndex(_logo => _logo.id === selectedLogo);

  if (isWidthUp("md", width)) {
    sliderSettings.rows = 2;
  } else {
    sliderSettings.rows = 1;
  }

  // triggered when a logo is selected from somewhere else (the viewer)
  React.useEffect(() => {
    const relatedLogo = logosList?.find(_logo => _logo.id === selectedViewerItemId);

    if (selectedViewerItemId !== selectedLogo && !!relatedLogo) {

      setSelectedLogo(selectedViewerItemId);

      // Change the logos' slider current slide to show the selected logo up
      if (typeof sliderRef?.current?.slickGoTo === "function") {
        const logoIndex = logosList.findIndex(_logo => _logo.id === selectedViewerItemId);
        let nbPages = Math.ceil(((logosList.length / (sliderSettings.slidesToShow * sliderSettings.rows)) >= 1 ? 1 : 0) + ((logosList.length - (sliderSettings.slidesToShow * sliderSettings.rows))) / sliderSettings.rows);

        nbPages = nbPages <= 0 ? 0 : nbPages;

        const logoPage = Math.min(nbPages, Math.ceil((logoIndex + 1) / sliderSettings.rows) - 1);

        if (logoIndex >= 0) {
          sliderRef.current.slickGoTo(logoPage, false);
        }
      }
    }

  }, [selectedViewerItemId, sliderRef, logosList]);


  React.useEffect(() => {
    const list = Object.entries(logos)
      .filter(([, image]) => !!image)
      .map(([id, image]) => ({ ...image, id }));

    setLogosList(list);
    setSlidersValue(formValues);

    if (list.length && list.length !== logosList.length) { // when a logo has been removed, unselect it
      setSelectedLogo(undefined);
    }
  }, [logos, formValues, logosList.length]);

  const handleChange = (field, cb) => async value => {

    // Check if images overlaid is allowed
    if (field === "location") {
      const imageCanBeOverlaid = await customizer.imageCanBeOverlaid(value);

      if (!imageCanBeOverlaid) { return; }
    }

    const nextFormValues = {
      custom : {
        logos : {
          [selectedLogo] : {
            [field] : value
          }
        }
      }
    };

    // If we are changing a property of a logo placed on the buttock position of a short
    // we should also update the symmetric logo. Note that the scale is already applied to the
    // symmetric logo by the viewer, so we should not update it
    if (field !== "sizeInCm" && field !== "location") {
      if (formValues.custom.logos?.[`${selectedLogo}_symetric`]) {
        nextFormValues.custom.logos[`${selectedLogo}_symetric`] = {
          [field] : value
        };
      }
    }

    setFormValues(nextFormValues, res => {

      // In case of a location update, we must also change the current viewer viewpoint
      if (!!res && field === "location") {
        const location = availableLocations.find(_location => _location.value === value);

        if (location && location.viewpoint) {
          customizer.changeViewpoint(location.viewpoint);
        }

        // When we are changing the position of a logo that was located on the buttocks position of a short
        // we should take care to remove its symmetric version, as we do not need it anymore
        if (formValues.custom.logos?.[`${selectedLogo}_symetric`]) {
          setFormValues({
            custom : {
              logos : {
                [`${selectedLogo}_symetric`] : null
              }
            }
          });
        }
      }

      if (typeof cb === "function") {
        cb(res);
      }
    });
  };


  function handleLogoClick(logoId) {
    setSelectedLogo(logoId);
    customizer.selectViewerItem(logoId);
  }


  function handleSliderChange(sliderId, value) {

    setSlidersValue(prevState => {
      const updatedLogos = {
        ...(prevState.custom?.logos || {}),
        [selectedLogo] : {
          ...(prevState.custom?.logos?.[selectedLogo] || {}),
          [sliderId] : value,
          error : null
        }
      };

      // This is for shorts buttock position only
      if (prevState.custom?.logos?.[`${selectedLogo}_symetric`]) {
        updatedLogos[`${selectedLogo}_symetric`] = {
          ...(prevState.custom?.logos?.[`${selectedLogo}_symetric`] || {}),
          [sliderId] : value,
          error : null
        };
      }

      return ({
        custom : {
          logos : updatedLogos
        }
      });
    });
  }


  if (logosList.length === 0) { return null; }

  return (
    <Grid container className={classes.container}>
      <SlickSlider {...sliderSettings} ref={sliderRef} initialSlide={initialSlide > -1 ? initialSlide : 0}>
        {
          logosList.map((_logo, index) => !!_logo.image && (
            <button
              type="button"
              key={index}
              className={classes.previewContainer}
              style={{
                backgroundImage : `url(${_logo.image})`
              }}
              onClick={() => handleLogoClick(_logo.id)}
            >
              <div
                className={clx(classes.preview,
                  { [classes.preview__selected]: selectedLogo === _logo.id })}
                style={{
                  backgroundImage : `url(${_logo.image})`
                }}
              />
            </button>
          ))
        }
      </SlickSlider>
      <Grid container direction="column" className={classes.slidersContainer}>
        {
          !!selectedLogo && !isShortButtocksPosition
          && (
          <Grid item container wrap="nowrap" justifyContent="space-between">
            <Typography variant="body2">{t("WORDS.POSITION")}</Typography>
            <Select
              value={formValues?.custom?.logos?.[selectedLogo]?.location || ""}
              onChange={e => handleChange("location")(e.target.value)}
              options={availableLocations}
              className={classes.locationSelect}
            />
          </Grid>
)
        }
        {
          !!selectedLogo && appConfig.formVariants.logos.settings.transformationInputs.map(_input => {
            const _value = slidersValues.custom.logos?.[selectedLogo]?.[_input.id] || _input.defaultValue;

            // Do not show the rotation slider for shorts buttock position
            if (_input.id === "userRotation" && isShortButtocksPosition) {
              return null;
            }

            return (
              <Grid item container key={_input.id} wrap="nowrap" justifyContent="space-between">
                <Typography variant="body2">{t(_input.title)}</Typography>
                {
                  _input.useButtons
                    ? (
                      <LessMoreButton
                        className={classes.slider}
                        value={_value}
                        min={_input.min}
                        max={_input.max}
                        step={_input.step}
                        onChange={(value, cb) => handleChange(_input.id, cb)(value)}
                      />
                    ) : (
                      <Slider
                        value={_value}
                        className={classes.slider}
                        defaultValue={_input.defaultValue}
                        min={_input.min}
                        max={_input.max}
                        step={_input.step}
                        marks={_input.marks}
                        onChange={(evt, value) => handleSliderChange(_input.id, value)}
                        onChangeCommitted={(e, value) => handleChange(_input.id)(value)}
                      />
                    )
                }
              </Grid>
            );
          })
        }
      </Grid>
      {
        !!selectedLogo
        && (
        <Button size="small" className={classes.deleteButton} onClick={() => onLogoDelete(selectedLogo)}>
          {t("ACTIONS.REMOVE")}
        </Button>
)
      }
    </Grid>
  );
}


export default withWidth()(wrapper({ styles }, LogosResizeForm));
